import { bemCN } from '../../configs/bem-classname';
import './tooltip.scss';
const tooltipCn = bemCN('tooltip');

export const Tooltip = ({ active, payload, label, unit }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className={tooltipCn()}>
        <div className={tooltipCn('label')}>{payload[0].payload.label}:</div>
        <div className={tooltipCn('value')}>
          {payload[0].value.toLocaleString('ru-RU')} {unit ? unit : 'руб.'}
        </div>
      </div>
    );
  }
  return null;
};
