export class ReviewItem {
  id: number;
  rating: number;
  text: string;
  author: string;

  constructor(id: number, rating: number, text: string, author: string) {
    this.id = id;
    this.rating = rating;
    this.text = text;
    this.author = author;
  }
}
