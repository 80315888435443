import { FC, useState } from 'react';
import { bemCN } from '../../../configs/bem-classname';
import './favorite-table-item.scss';
import { Button, ColorsButton, SizesButton } from '../../button/button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LabelDto, LabelProductContentDto } from '../../../api/Api';
import { RootState, typedDispatch } from '../../../store';
import Popup from '../../popup/popup';
import { FavoriteRadioGroup } from '../../../components/favorite-radio-group/favorite-radio-group';
import { favoriteService } from '../../../services/favorite-service';
import { FavoriteColors } from '../../../components/favorite-check-group/favorite-check-group';
import {
  setAllFavoriteProducts,
  setFavoriteProducts,
} from '../../../store/favorite-products/action';
import { allFavoriteProductssReducer } from '../../../store/favorite-products/reducer';

interface FavoriteTableItemProps {
  idProduct: number;
  name: string;
  link: string;
  labelId: number;
}

const favoriteTableItemCn = bemCN('favorite-table-item');
export const FavoriteTableItem: FC<FavoriteTableItemProps> = ({
  idProduct,
  name,
  link,
  labelId,
}) => {
  const products: LabelProductContentDto[] = useSelector(
    (state: RootState) => state.filterFavoriteProductssReducer,
  )?.products;
  const allProducs: LabelProductContentDto[] = useSelector(
    (state: RootState) => state.allFavoriteProductssReducer,
  )?.products;
  const labels: LabelDto[] = useSelector((state: RootState) => state.favoriteLabelsReducer)?.labels;
  let label = labels.find((l: LabelDto) => l.id == labelId);
  let className;
  if (label) {
    className = FavoriteColors[label.colorId || -1];
  }

  const [isShowPopup, setIsShowPopup] = useState(false);

  const changeProductLabel = (newLabelId: number) => {
    favoriteService.changeProductLabel(idProduct, newLabelId).then(() => {
      // let newProducts = [...products];
      let newProducts = [...products].map((p: LabelProductContentDto) => {
        if (p.id === idProduct) {
          return { ...p, labelId: newLabelId } as LabelProductContentDto;
        } else return p;
      });

      let newAllProducts = [...allProducs].map((p: LabelProductContentDto) => {
        if (p.id === idProduct) {
          return { ...p, labelId: newLabelId } as LabelProductContentDto;
        } else return p;
      });
      typedDispatch(setFavoriteProducts(newProducts));
      typedDispatch(setAllFavoriteProducts(newAllProducts));
    });
  };

  return (
    <div className={favoriteTableItemCn()}>
      <div
        className={
          className
            ? favoriteTableItemCn(FavoriteColors[(label?.colorId || 1) as FavoriteColors])
            : ''
        }
      >
        <Button
          onClick={() => setIsShowPopup(!isShowPopup)}
          size={SizesButton.small}
          color={ColorsButton.white}
        />
        <Link to={link}>{name}</Link>
        <Popup isShow={isShowPopup} setIsShow={setIsShowPopup}>
          <div>
            <FavoriteRadioGroup activeId={labelId} items={labels} onChange={changeProductLabel} />
          </div>
        </Popup>
      </div>
    </div>
  );
};
