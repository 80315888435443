import { CurrentSubscriptionDto, OrderHistoryDto, SellableItem } from '../api/Api';
import { API } from '../configs/api';
import { PaymentItem } from '../view-models/payment-item';

class PaymentService {
  async getTariffs() {
    try {
      let res = API.payment.sellableItemsList();
      let result = (await res).data;
      return result as SellableItem[];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getHistory() {
    try {
      let res = API.payment.ordersList();
      let result = (await res).data as OrderHistoryDto[];
      return result
        .sort(
          (a: OrderHistoryDto, b: OrderHistoryDto) =>
            new Date(b.orderDate || '').getDate() - new Date(a.orderDate || '').getDate(),
        )
        .map(
          (item: OrderHistoryDto, index: number) =>
            new PaymentItem(
              index + 1,
              item.orderDate || '',
              item.subscriptionName || '',
              item.status || '',
              item.paymentMethod || '',
              item.amount || 0,
            ),
        );
    } catch (err: any) {
      console.log(err);
      return [] as PaymentItem[];
    }
  }

  async getCurrentTariff() {
    try {
      let res = API.profile.getCurrentSubscriptionList();
      let result = (await res).data;
      return result as CurrentSubscriptionDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async initPayment(id: number) {
    try {
      let res = API.payment.initCreate({ itemId: id, recurrent: true });
      let result = (await res).data;
      return result.url || "";
    } catch (err: any) {
      console.log(err);
      return "";
    }
  }

  getIsActiveTariff() {
    let cookie = document.cookie;
    if (cookie.includes('IsPaidUser=true')) return true;
    else return false;
  }
}

export const paymentService: PaymentService = new PaymentService();
