import { bemCN } from '../../configs/bem-classname';
import './small-loader.scss';

const smallLoaderCn = bemCN('small-loader');

export const SmallLoader = () => {
  return (
    <div className={smallLoaderCn()}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
