import { FC, ReactNode, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './review-slide.scss';
import { ReactComponent as StarIcon } from '../../shared/image/svg/star.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReviewItem } from '../../view-models/review-item';
import { ColorsModal, Modal } from '../../ui/modal/modal';

export interface ReviewSlideProps {
  review: ReviewItem;
}
const reviewSlideCn = bemCN('review-slide');

const stars = [<StarIcon />, <StarIcon />, <StarIcon />, <StarIcon />, <StarIcon />];

export const ReviewSlide: FC<ReviewSlideProps> = ({ review }) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const onClickShowMore = () => {
    setIsShowMore(true);
    // console.log('Показать');
  };
  return (
    <div className={reviewSlideCn()}>
      <div className={reviewSlideCn('stars')}>
        {stars.map((s: ReactNode, index: number) => (
          <div key={index} className={reviewSlideCn('star', { isFill: index < review.rating })}>
            {s}
          </div>
        ))}
      </div>
      <p className={reviewSlideCn('text')}>{review.text}</p>
      <a onClick={() => onClickShowMore()}>Читать далее</a>
      <p className={reviewSlideCn('author')}>{review.author}</p>
      <Modal
        title=""
        color={ColorsModal.dark}
        isShowDialog={isShowMore}
        setIsShowDialog={setIsShowMore}
      >
        <div className={reviewSlideCn('stars')}>
          {stars.map((s: ReactNode, index: number) => (
            <div key={index} className={reviewSlideCn('star', { isFill: index < review.rating })}>
              {s}
            </div>
          ))}
        </div>
        <p className={reviewSlideCn('all-text')}>{review.text}</p>
        <p className={reviewSlideCn('author')}>{review.author}</p>
      </Modal>
    </div>
  );
};
