import { TableColumn, TypeColumn } from '../view-models/table-column';

export const campaignsColumns = [
  new TableColumn(6, 'id', 'ID', TypeColumn.string, true, undefined, true, false, 0),

  new TableColumn(1, 'name', 'Название', TypeColumn.string, true, undefined, true, false, 1),
  new TableColumn(2, 'typeName', 'Тип', TypeColumn.string, true, undefined, false, false, 2),
  new TableColumn(
    3,
    'createTime',
    'Дата создания',
    TypeColumn.string,
    true,
    undefined,
    true,
    false,
    3,
  ),
  new TableColumn(
    4,
    'changeTime',
    'Дата изменения',
    TypeColumn.string,
    true,
    undefined,
    true,
    false,
    4,
  ),
  new TableColumn(
    7,
    'startTime',
    'Дата старта',
    TypeColumn.string,
    true,
    undefined,
    true,
    false,
    5,
  ),
  new TableColumn(5, 'statusName', 'Статус', TypeColumn.string, true, undefined, false, false, 6),
];
