import { TableColumn, TypeColumn } from '../view-models/table-column';

export const paymentHistoryColumns = [
  new TableColumn(1, 'id', 'Номер', TypeColumn.string, true, 100, false, false, 1),
  new TableColumn(2, 'date', 'Дата', TypeColumn.string, true, 145, false, false, 2),
  new TableColumn(3, 'tariffName', 'Тариф', TypeColumn.string, true, 250, false, false, 3),
  new TableColumn(4, 'status', 'Статус', TypeColumn.string, true, 200, false, false, 4),
  new TableColumn(5, 'method', 'Метод оплаты', TypeColumn.string, true, 200, false, false, 5),
  new TableColumn(6, 'sum', 'Сумма, ₽', TypeColumn.string, true, 210, false, false, 6),
];
