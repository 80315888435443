export class User {
  name: string;
  phone: string;
  email: string;
  imageBase64: string;

  constructor(name: string, phone: string, email: string, imageBase64: string) {
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.imageBase64 = imageBase64;
  }
}
