import { HistoryDto, HistoryOffsetDto } from '../api/Api';
import { API } from '../configs/api';
import { Country } from '../view-models/country';
import { HistoryItem } from '../view-models/history-item';
import { ProfileHistoryFilter } from '../view-models/profile-history-filter';

class HistoryService {
  async getHistory(filter: ProfileHistoryFilter) {
    try {
      let res = API.history.getHistoryCreate({ offset: filter.offset, count: filter.limit });
      let result = (await res).data as HistoryDto[];
      return (
        result.map(
          (h: HistoryDto) => new HistoryItem(h.dateTime || '', h.type || '', h.url || ''),
        ) || []
      );
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getItemsCount() {
    try {
      let res = API.history.getCountCreate();
      let result = (await res).data;
      return (result as number) || -1;
    } catch (err: any) {
      console.log(err);
      return -1;
    }
  }
}

export const historyService: HistoryService = new HistoryService();
