import { bemCN } from '../../configs/bem-classname';
import './help-page.scss';
import { ReactComponent as TelegremIcon } from '../../shared/image/svg/telegram-colorfull.svg';
import { ReactComponent as VKIcon } from '../../shared/image/svg/vk-colorfull.svg';
import { ReactComponent as WhatsAppIcon } from '../../shared/image/svg/whatsapp-colorfull.svg';
import { ReactComponent as CrossIcon } from '../../shared/image/svg/cross.svg';
import { phoneWhatsApp } from '../../consts/phones';
import { helpItems } from '../../consts/help-items';
import { HelpItem } from '../../view-models/help-item';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { Link } from 'react-router-dom';
import { useState } from 'react';
const helpPageCn = bemCN('help-page');
export const HelpPage = () => {
  const [activeId, setActiveId] = useState(-1);

  const onClickItem = (id: number) => {
    if (activeId === id) setActiveId(-1);
    else setActiveId(id);
  };
  return (
    <div className={helpPageCn()}>
      <div className={helpPageCn('header')}>
        <p className="h1">Вопрос (FAQ)</p>
        <div className={helpPageCn('contacts')}>
          <p>Техническая поддержка</p>
          <a target="_blank" href="https://t.me/xstatru">
            <TelegremIcon />
          </a>
          <a target="_blank" href={'https://api.whatsapp.com/send?phone=' + [phoneWhatsApp]}>
            <WhatsAppIcon />
          </a>
          <a target="_blank" href="https://vk.com/xstatru">
            <VKIcon />
          </a>
        </div>
      </div>
      <div className={helpPageCn('help-items')}>
        {helpItems.map((item: HelpItem) => (
          <div
            className={helpPageCn('help-item', { isActive: activeId === item.id })}
            key={item.id}
          >
            <div>
              <p>{item.question}</p>
              <p>
                {item.answer} {item.link && <Link to={item.link.link}>{item.link.text}</Link>}
              </p>
            </div>
            <Button
              onClick={() => onClickItem(item.id)}
              size={SizesButton.small}
              color={ColorsButton.violet}
              icon={<CrossIcon />}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
