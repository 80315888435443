export const typesCampanies = [
  {
    id: 8,
    name: 'Автоматическая кампания',
  },
  {
    id: 9,
    name: 'Аукцион',
  },
  {
    id: 4,
    name: 'Кампания в каталоге',
  },
  {
    id: 5,
    name: 'Кампания в карточке товара',
  },
  {
    id: 6,
    name: 'Кампания в поиске',
  },
  {
    id: 7,
    name: 'Кампания  в рекомендациях на главной странице',
  },
];

export const statusesCampanies = [
  {
    id: 9,
    name: 'Идут показы',
  },
  {
    id: 11,
    name: 'На паузе',
  },
  {
    id: 4,
    name: 'Готова к запуску',
  },
  {
    id: 7,
    name: 'Завершена',
  },
  {
    id: 8,
    name: 'Отказался',
  },
  {
    id: -1,
    name: 'В процессе удаления',
  },
];
