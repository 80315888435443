import { RadioGroup } from '../../components/radio-group/radio-group';
import { Select, TypeSelectChild } from '../../components/select/select';
import { bemCN } from '../../configs/bem-classname';
import { countries } from '../../consts/county';
import { Input, TypeDataInput, TypeViewInput } from '../../ui/input/input';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { SelectItem } from '../../view-models/select-item';
import './registation-page.scss';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { SearchInput } from '../../components/search-input/search-input';
import { cities } from '../../consts/cities';
import { MouseEventHandler, useEffect, useState } from 'react';
import { SiteJobTitle, UserRegistrationDto } from '../../api/Api';
import {
  dateHasError,
  emailHasError,
  nameHasError,
  passwordHasError,
} from '../../utils/validation-input';
import { authService } from '../../services/auth-service';
import { ColorsModal, Modal } from '../../ui/modal/modal';

const registrationPageCn = bemCN('registation-page');

export const RegistationPage = () => {
  const [filterCities, setFilterCities] = useState(cities);
  const [filterCities2, setFilterCities2] = useState(cities);
  const [filterCountry, setFilterCountry] = useState(countries);

  const [searchCityValue, setSearchCityValue] = useState('');
  const [searchCountryValue, setSearchCountryValue] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  const [checkCountryId, setCheckCountryId] = useState(-1);
  const [checkCityId, setCheckCityId] = useState(-1);
  const [roles, setRoles] = useState<SiteJobTitle[]>([]);

  const [isShowModal, setIsShowModal] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    authService.getRoles().then((res: SiteJobTitle[]) => setRoles(res));
  }, [1]);

  const [isActiveSendButton, setIsActiveSendButton] = useState(false);

  const onChangeSearchCiteValue = (v: string) => {
    setSearchCityValue(v);
    if (newUser.country) {
      setFilterCities2([
        ...filterCities.filter((c: any) =>
          (c.name as string).toLowerCase().includes(v.toLowerCase()),
        ),
      ]);
    } else {
      setFilterCities2([
        ...cities.filter((c: any) => (c.name as string).toLowerCase().includes(v.toLowerCase())),
      ]);
    }
  };

  const onChangeSearchCountryValue = (v: string) => {
    setSearchCountryValue(v);
    setFilterCountry([
      ...countries.filter((c: any) => (c.name as string).toLowerCase().includes(v.toLowerCase())),
    ]);
  };

  const location = useLocation();
  const [newUser, setNewUser] = useState<UserRegistrationDto>({
    email: location.state && location.state.user ? location.state.user?.email : '',
    passwordHash: '',
    phoneNumber: location.state && location.state.user ? location.state.user?.phone : '',
    name: '',
    gender: 0,
    birthday: '',
    city: '',
    country: '',
    jobTitle: 5,
  });

  const onCheckCountry = (id: number) => {
    setNewUser({ ...newUser, country: countries.find((c: any) => c.id === id)?.name });
    setCheckCountryId(id);

    setFilterCities([...cities.filter((c: any) => c.countryId === id)]);
    setFilterCities2([...cities.filter((c: any) => c.countryId === id)]);
  };

  const onCheckCity = (id: number) => {
    setNewUser({ ...newUser, city: cities.find((c: any) => c.id === id)?.name });
    setCheckCityId(id);
  };

  const onChangeGender = (v: any) => {
    setNewUser({ ...newUser, gender: Number(v.target.defaultValue) });
  };

  useEffect(() => {
    if (
      !nameHasError(newUser.name || '') &&
      !emailHasError(newUser.email || '') &&
      !passwordHasError(newUser.passwordHash || '') &&
      !dateHasError(newUser.birthday || '') &&
      isAgree
    ) {
      setIsActiveSendButton(true);
    } else {
      setIsActiveSendButton(false);
    }
  }, [newUser, isAgree]);

  const onRegistrationClick = () => {
    setErrorText('');
    authService.registration(newUser).then((res: any) => {
      if (res) {
        setErrorText(res);
      } else {
        setNewUser({
          email: '',
          passwordHash: '',
          phoneNumber: '',
          name: '',
          gender: 0,
          birthday: '',
          city: '',
          country: '',
          jobTitle: 5,
        });
        setIsShowModal(true);
      }
    });
  };
  return (
    <div className={registrationPageCn()}>
      <div>
        <h1>Регистрация</h1>
        <div className={registrationPageCn('form')}>
          <div className={registrationPageCn('input')}>
            <p>Имя</p>
            <Input
              value={newUser.name || ''}
              placeholder="Имя"
              typeView={TypeViewInput.dark}
              typeData={TypeDataInput.text}
              onChange={(v: string) => {
                setNewUser({ ...newUser, name: v });
              }}
              isValidate
            />
          </div>
          <div className={registrationPageCn('input')}>
            <p>Адрес электронной почты</p>
            <Input
              value={newUser.email}
              placeholder="Email"
              typeView={TypeViewInput.dark}
              typeData={TypeDataInput.email}
              onChange={(v: string) => {
                setNewUser({ ...newUser, email: v });
              }}
              isValidate
            />
          </div>
          <div className={registrationPageCn('input')}>
            <p>Страна</p>
            <Select
              activeNames={newUser.country == '' ? [] : [newUser.country || '']}
              typeSelectChild={TypeSelectChild.radio}
            >
              <SearchInput
                value={searchCountryValue}
                onChange={onChangeSearchCountryValue}
                onEnterClick={() => {}}
              />
              <RadioGroup
                items={filterCountry.map((c: any) => new SelectItem(c.id || -1, c.name || ''))}
                activeId={checkCountryId}
                onChange={onCheckCountry}
              />
            </Select>
          </div>
          <div className={registrationPageCn('input')}>
            <p>Пароль</p>
            <Input
              value={newUser.passwordHash || ''}
              placeholder="Пароль"
              typeView={TypeViewInput.dark}
              typeData={TypeDataInput.password}
              onChange={(v: string) => {
                setNewUser({ ...newUser, passwordHash: v });
              }}
              isValidate
            />
          </div>
          <div className={registrationPageCn('input')}>
            <p>Город</p>
            <Select
              activeNames={newUser.city == '' ? [] : [newUser.city || '']}
              typeSelectChild={TypeSelectChild.radio}
            >
              <SearchInput
                value={searchCityValue}
                onChange={onChangeSearchCiteValue}
                onEnterClick={() => {}}
              />
              <RadioGroup
                items={filterCities2.map((c: any) => new SelectItem(c.id || -1, c.name || ''))}
                activeId={checkCityId}
                onChange={onCheckCity}
              />
            </Select>
          </div>
          <div className={registrationPageCn('input')}>
            <p>Номер телефона</p>
            <Input
              value={newUser.phoneNumber || ''}
              placeholder="Номер телефона"
              typeView={TypeViewInput.dark}
              typeData={TypeDataInput.phone}
              onChange={(v: string) => {
                setNewUser({ ...newUser, phoneNumber: v });
              }}
              isValidate
            />
          </div>
          <div className={registrationPageCn('input')}>
            <p>Дата рождения</p>
            <Input
              value={newUser.birthday || ''}
              placeholder="Дата рождения"
              typeView={TypeViewInput.dark}
              typeData={TypeDataInput.date}
              onChange={(v: string) => {
                setNewUser({ ...newUser, birthday: v });
              }}
              isValidate
            />
          </div>
          <div className={registrationPageCn('input')}>
            <p>Специальнсоть</p>
            <Select
              activeNames={
                newUser.jobTitle && roles.length > 0
                  ? [roles.find((r: SiteJobTitle) => r.id === newUser.jobTitle)?.title || '']
                  : []
              }
              typeSelectChild={TypeSelectChild.radio}
            >
              <RadioGroup
                items={roles.map((c: SiteJobTitle) => new SelectItem(c.id || -1, c.title || ''))}
                activeId={newUser.jobTitle}
                onChange={(id: number) => {
                  setNewUser({ ...newUser, jobTitle: id });
                }}
              />
            </Select>
          </div>
          <div className={registrationPageCn('gender')}>
            <div>
              <input onClick={onChangeGender} type="radio" id="man" name="gender" value="0" /> 
              <label htmlFor="man">Мужской</label>
            </div>
            <div>
              <input onClick={onChangeGender} type="radio" id="woman" name="gender" value="1" /> 
              <label htmlFor="woman">Женский</label>
            </div>
          </div>
          <div className={registrationPageCn('agreement')}>
            <div onClick={() => setIsAgree(!isAgree)}>{isAgree && <CheckMarkIcon />}</div>
            <p>
              Я согласен с правилами{' '}
              <Link target="_blank" to={linksUrl.politics}>
                обработки персональных данных
              </Link>
            </p>
          </div>
        </div>
        <Button
          onClick={onRegistrationClick}
          noDisabled={!isActiveSendButton}
          text="Далее"
          color={ColorsButton.green}
          size={SizesButton.big}
        />
        {errorText !== '' && <p className={registrationPageCn('error')}>{errorText}</p>}
        <p>
          Уже есть аккаунт? <Link to={linksUrl.login}>Войти.</Link>
        </p>
      </div>
      <Modal
        setIsShowDialog={setIsShowModal}
        isShowDialog={isShowModal}
        title={''}
        color={ColorsModal.dark}
      >
        <div className={registrationPageCn('modal')}>
          <p>
            На указанный Вами адрес электронной почты отправлено письмо. Пожалуйста, проверьте
            почтовый ящик. Если вы не получили письмо с подтверждением на свою почту, пожалуйста,
            проверьте папку «Спам» или «Нежелательная почта».
          </p>
          <p></p>
        </div>
      </Modal>
    </div>
  );
};
