import { FC, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './export.scss';
import { ReactComponent as ExportIcon } from '../../shared/image/svg/export.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import Popup from '../../ui/popup/popup';
import { RadioGroup } from '../radio-group/radio-group';
import { SelectItem } from '../../view-models/select-item';

export interface ExportProps {
  onClickExport(typeFile: number): void;
}
const exportCn = bemCN('export');

export const Export: FC<ExportProps> = ({ onClickExport }) => {
  const [isShow, setIsShow] = useState(false);
  const [selectType, setSelectType] = useState(0);

  const onClick = () => {
    onClickExport(selectType);
    setIsShow(false);
  };

  return (
    <div className={exportCn()}>
      <Button
        color={ColorsButton.white}
        size={SizesButton.big}
        text="Экспорт"
        icon={<ExportIcon />}
        onClick={() => setIsShow(!isShow)}
      />
      <Popup isShow={isShow} setIsShow={setIsShow}>
        <RadioGroup
          items={[new SelectItem(0, 'xlsx'), new SelectItem(1, 'xml')]}
          activeId={selectType}
          onChange={setSelectType}
        />
        <Button
          onClick={onClick}
          size={SizesButton.big}
          color={ColorsButton.violet}
          text="Сохранить"
        />
      </Popup>
    </div>
  );
};
