import { FC, useEffect, useState } from 'react';
import './category-selected-item.scss';
import { bemCN } from '../../configs/bem-classname';
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { CategoryTree } from '../../view-models/category';

interface FilterCategorySelect {
  items: CategoryTree[];
  setCheckedItems?: (ids: number[]) => void;
  checkedItems?: number[];
}

const filterCategorySelect = bemCN('filter-category-selected');

export const FilterCategorySelect: FC<FilterCategorySelect> = ({
  items,
  setCheckedItems,
  checkedItems,
}) => {
  const [checked, setChecked] = useState<string[]>(
    checkedItems ? checkedItems.map((id: number) => id.toString()) : [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  // const [isCheckAll, setIsCheckAll] = useState(false);

  // const onClickCheckAll = () => {
  //   if (isCheckAll) {
  //     let emtyIds: string[] = [];
  //     setChecked([...emtyIds]);
  //   } else {
  //     let allIds: string[] = [];
  //     const extractVal = (items: CategoryTree[]) => {
  //       items.forEach((e) => {
  //         allIds.push(e.value);
  //         if (e.children) {
  //           extractVal(e.children);
  //         }
  //       });
  //     };
  //     extractVal(items);
  //     setChecked(allIds);
  //   }
  //   setIsCheckAll(!isCheckAll);
  // };

  useEffect(() => {
    setCheckedItems && setCheckedItems(checked.map((id: string) => Number(id)));
  }, [checked]);

  return (
    <div className={filterCategorySelect()}>
      {/* <div className={filterCategorySelect('check-all')}>
        <p>Выбрать все</p>
        <div
          onClick={onClickCheckAll}
          className={isCheckAll ? filterCategorySelect('active') : ''}
        ></div>
      </div> */}
      <CheckboxTree
        nodes={items}
        checked={checked}
        expanded={expanded}
        onCheck={(ids) => setChecked(ids)}
        onExpand={(expanded) => setExpanded(expanded)}
      />
    </div>
  );
};
