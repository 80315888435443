export class LineChartPropName {
  id: number;
  label: string;
  propName: string;
  unit?: string;
  constructor(id: number, label: string, propName: string, unit?: string) {
    this.id = id;
    this.label = label;
    this.propName = propName;
    this.unit = unit;
  }
}
 
