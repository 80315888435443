import { FC, ReactNode, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './calendar.scss';
import { ReactComponent as CrossIcon } from '../../shared/image/svg/cross.svg';
import { ReactComponent as ArrowLeftIcon } from '../../shared/image/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../shared/image/svg/arrow-right.svg';
import { ReactComponent as CalendarIcon } from '../../shared/image/svg/calender.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import Popup from '../../ui/popup/popup';
import ReactCalendar from 'react-calendar';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { Value } from 'react-calendar/src/shared/types';
import { getLastDays, lastMonthRange, yeasteday } from '../../consts/calendar';
import { getLast, getNext } from '../../utils/date';

export enum PeriodType {
  week = 1,
  month,
  year,
}
export interface CalendarProps {
  value: CalendarPeriod;
  changeValue: (v: CalendarPeriod) => void;
  maxDate?: Date;
  minDate?: Date;
  defaultDate?: CalendarPeriod;
  isDisable?: boolean;
  maxPeriod?: PeriodType;
}
const calendarCn = bemCN('calendar');

const options: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};
const opts: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' };

const getPeriodForTitle = (period: CalendarPeriod) => {
  let dateTo = new Intl.DateTimeFormat('ru-RU', opts).format(period.dateTo);
  let dateFrom = new Intl.DateTimeFormat('ru-RU', opts).format(period.dateFrom);
  return dateFrom + ' — ' + dateTo;
};

const getPeriodForButton = (period: CalendarPeriod) => {
  let dateTo = new Intl.DateTimeFormat('ru-RU', options).format(period.dateTo);
  let dateFrom = new Intl.DateTimeFormat('ru-RU', options).format(period.dateFrom);
  return dateFrom + ' — ' + dateTo;
};

export const Calendar: FC<CalendarProps> = ({
  value,
  changeValue,
  maxDate,
  minDate,
  defaultDate,
  isDisable,
  maxPeriod,
}) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [period, setPeriod] = useState<CalendarPeriod>(value);
  const [min, setMin] = useState<Date>();
  const [max, setMax] = useState<Date>();

  const resetCalendar = () => {
    if (defaultDate) {
      setPeriod(defaultDate);
      changeValue(defaultDate);
    } else {
      let defValue = new CalendarPeriod(lastMonthRange, yeasteday);
      setPeriod(defValue);
      changeValue(defValue);
    }
    setIsShowCalendar(false);
  };

  const onChangeDate = (date: Value) => {
    if (Array.isArray(date)) {
      let newValue: CalendarPeriod = new CalendarPeriod(date[0] as Date, date[1] as Date);
      setPeriod(newValue);
      changeValue(newValue);
      setIsShowCalendar(false);

      setMin(undefined);
      setMax(undefined);
    }
  };

  const onCheckDay = (day: any) => {
    if (maxPeriod) {
      let min = getLast(day, maxPeriod);
      let max = getNext(day, maxPeriod);
      if(maxDate && max>maxDate){
        max = maxDate;
      }
      if(minDate && min<minDate){
        min = minDate;
      }
      setMin(min);
      setMax(max);
    }
  };

  return (
    <div className={calendarCn()}>
      <Button
        onClick={() => setIsShowCalendar(!isShowCalendar)}
        icon={<CalendarIcon />}
        size={SizesButton.big}
        color={ColorsButton.white}
        text={getPeriodForButton(period)}
        noDisabled={isDisable !== undefined ? isDisable : undefined}
      />
      <Popup isShow={isShowCalendar} setIsShow={setIsShowCalendar}>
        <div className={calendarCn('container')}>
          <div className={calendarCn('header')}>
            <p>Календарь</p>
            <span>{getPeriodForTitle(period)}</span>
            <Button
              onClick={() => setIsShowCalendar(false)}
              color={ColorsButton.transition}
              size={SizesButton.small}
              icon={<CrossIcon />}
            />
          </div>
          <ReactCalendar
            value={[period.dateFrom, period.dateTo]}
            selectRange={true}
            prev2Label={null}
            prevLabel={<ArrowLeftIcon />}
            nextLabel={<ArrowRightIcon />}
            next2Label={null}
            formatMonth={(local: string | undefined, date: Date) => {
              return date.toLocaleTimeString('ru-RU', { month: 'short' }).slice(0, -10);
            }}
            maxDate={max || maxDate}
            minDate={min || minDate}
            onChange={onChangeDate}
            onClickDay={onCheckDay}
          />
          <div className={calendarCn('footer')}>
            <Button
              onClick={resetCalendar}
              color={ColorsButton.transition}
              size={SizesButton.big}
              text="Сбросить"
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};
