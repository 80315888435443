import { FC } from 'react';
import './pagination.scss';
import { bemCN } from '../../configs/bem-classname';
import { ReactComponent as ArrowLeftIcon } from '../../shared/image/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../shared/image/svg/arrow-right.svg';

interface PaginationProps {
  id?: string;
  currentPageNumber: number;
  totalPagesCount: number;
  onClick: (directionPaginationClick: DirectionPaginationClick) => void;
}

export enum DirectionPaginationClick {
  next,
  back,
}

const paginationCn = bemCN('pagination');

export const Pagination: FC<PaginationProps> = ({
  id,
  currentPageNumber,
  totalPagesCount,
  onClick,
}) => {
  return (
    <div id={id} className={paginationCn()}>
      <p>
        {currentPageNumber} из {totalPagesCount.toLocaleString("ru-Ru")}
      </p>
      <div>
        <div onClick={() => onClick && onClick(DirectionPaginationClick.back)}>
          <ArrowLeftIcon />
        </div>
        <div onClick={() => onClick && onClick(DirectionPaginationClick.next)}>
          <ArrowRightIcon />
        </div>
      </div>
    </div>
  );
};
