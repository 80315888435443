import { FC, useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './search-input.scss';
import { ReactComponent as SearchIcon } from '../../shared/image/svg/search.svg';

export interface SearchInputProps {
  id?: string;
  value: string | number;
  onChange: (v: string) => void;
  onEnterClick: () => void;
  colorSearchInput?: ColorSearchInput;
  placeholder?: string;
}
const searchInputCn = bemCN('search-input');

export enum ColorSearchInput {
  light = 1,
  dark,
}

export const SearchInput: FC<SearchInputProps> = ({
  id,
  value,
  onChange,
  onEnterClick,
  placeholder,
}) => {
  const [valueState, setValueState] = useState<string | number>(value ? value : '');

  const enterClick = (e: any) => {
    if (e.key === 'Enter') {
      onEnterClick && onEnterClick();
    }
  };

  const onChangeValue = (v: React.FormEvent<HTMLInputElement>) => {
    setValueState(v.currentTarget.value);
    onChange && onChange(v.currentTarget.value);
  };

  return (
    <div className={searchInputCn()}>
      <SearchIcon />
      <input
        onKeyDown={enterClick}
        type="text"
        value={valueState}
        onChange={onChangeValue}
        placeholder={placeholder && placeholder}
      ></input>
    </div>
  );
};
