import { bemCN } from '../../configs/bem-classname';
import './line-loading.scss';

const lineLoadingCn = bemCN('line-loading');
export const LineLoading = () => {
  return (
    <div className={lineLoadingCn()}>
      <p>Загрузка файла...</p>
      <div className={lineLoadingCn('line')}></div>
    </div>
  );
};
