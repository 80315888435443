import { LabelProductContentDto } from '../../api/Api';

const defaultState = {
  products: [] as LabelProductContentDto[],
};

export const filterFavoriteProductssReducer = (_state = defaultState, _action: any) => {
  switch (_action.type) {
    case 'SET_PRODUCTS': {
      if (_action.payload === 'null' || _action.payload === '')
        return { ..._state, products: defaultState.products };
      else return { ..._state, products: _action.payload };
    }
    default: {
      return _state;
    }
  }
};

export const allFavoriteProductssReducer = (_state = defaultState, _action: any) => {
  switch (_action.type) {
    case 'SET_ALL_PRODUCTS': {
      if (_action.payload === 'null' || _action.payload === '')
        return { ..._state, products: defaultState.products };
      else return { ..._state, products: _action.payload };
    }
    default: {
      return _state;
    }
  }
};
