import './check-group.scss';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { bemCN } from '../../configs/bem-classname';
import { SelectItem } from '../../view-models/select-item';

type CheckGroupProps = {
  items: SelectItem[];
  activeIds: number[];
  checkMarkPosition: CheckMarkPosition;
  onChange(ids: number[]): void;
};

export enum CheckMarkPosition {
  left = 1,
  right,
}
export const CheckGroup: FC<CheckGroupProps> = ({
  activeIds,
  items,
  checkMarkPosition,
  onChange,
}) => {
  const checkGroupCN = bemCN('check-group');
  const [checkedIds, setChekedIds] = useState(activeIds);

  const onClickItem = (id: number) => {
    checkedIds.includes(id)
      ? setChekedIds([...checkedIds.filter((i) => i !== id)])
      : setChekedIds([...checkedIds.concat(id)]);
  };

  useEffect(() => {
    onChange(checkedIds);
  }, [checkedIds]);


  return (
    <div className={checkGroupCN({markPosition:CheckMarkPosition[checkMarkPosition]})}>
      {items &&
        items.map((item: SelectItem) => (
          <div
            onClick={() => onClickItem(item.id)}
            key={item.id}
            className={checkGroupCN('check-item')}
          >
            <span>{item.name}</span>
            <div className={checkedIds.includes(item.id) ? checkGroupCN('active') : ''}>
              {checkedIds.includes(item.id) ? <CheckMarkIcon /> : null}
            </div>
          </div>
        ))}
    </div>
  );
};
