import { FC, ReactNode, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './bar-panel.scss';
import { ReactComponent as CrossIcon } from '../../shared/image/svg/cross.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';

export interface BarPanelProps {
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
  children: ReactNode;
  title?: string;
}
const barPanelCn = bemCN('bar-panel');

export const BarPanel: FC<BarPanelProps> = ({ isShow, setIsShow, children, title }) => {
  return (
    <div className={barPanelCn({ isShow: isShow })}>
      <div className={barPanelCn('header')}>
        <p>{title}</p>
        <Button onClick={() => setIsShow(false)} icon={<CrossIcon/>} size={SizesButton.small} color={ColorsButton.transition}/>
      </div>
      <div>{children}</div>
    </div>
  );
};
