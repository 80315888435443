import './favorite-check-group.scss';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { bemCN } from '../../configs/bem-classname';
import { LabelDto } from '../../api/Api';

type FavoriteCheckGroupProps = {
  items: LabelDto[];
  activeIds: number[];
  onChange(ids: number[]): void;
  onChangeNameLabel: (newName: string, idLabel: number) => void;
  saveChangeName: () => void;
};

export enum FavoriteColors {
  green = 1,
  red,
  blue,
}

export const FavoriteCheckGroup: FC<FavoriteCheckGroupProps> = ({
  activeIds,
  items,
  onChange,
  onChangeNameLabel,
  saveChangeName,
}) => {
  const favoriteCheckGroupCN = bemCN('favorite-check-group');
  const [checkedIds, setChekedIds] = useState([...activeIds]);
  const [isAll, setIsAll] = useState(true);

  const onClickItem = (id: number) => {
    checkedIds.includes(id)
      ? setChekedIds([...checkedIds.filter((i) => i !== id)])
      : setChekedIds([...checkedIds.concat(id)]);
  };

  const onClickAll = () => {
    let newIsAll = !isAll;
    if (newIsAll) {
      setChekedIds([...checkedIds.concat(-1)]);
    } else {
      setChekedIds([...checkedIds.filter((it: number) => it !== -1)]);
    }
    setIsAll(newIsAll);
  };

  useEffect(() => {
    onChange(checkedIds);
  }, [checkedIds]);

  return (
    <div className={favoriteCheckGroupCN()}>
      <div className={favoriteCheckGroupCN('check-item')} onClick={onClickAll}>
        <div className={isAll ? favoriteCheckGroupCN('active') : ''}>
          {isAll ? <CheckMarkIcon /> : null}
        </div>
        <p>Без группы</p>
      </div>
      {items &&
        items.map((item: LabelDto) => (
          <div key={item.id} className={favoriteCheckGroupCN('check-item')}>
            <div
              onClick={() => onClickItem(item.id || -1)}
              className={favoriteCheckGroupCN(
                FavoriteColors[(item.colorId || 1) as FavoriteColors],
              )}
            >
              {checkedIds.includes(item.id || -1) ? <CheckMarkIcon /> : null}
            </div>
            <input
              onChange={(v: any) => onChangeNameLabel(v.target.value, item.id || -1)}
              value={item.name || ''}
              onBlur={saveChangeName}
            />
          </div>
        ))}
    </div>
  );
};
