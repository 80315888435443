export class BrandsStats {
  revenue?: number;
  sale?: number;
  lostRevenue?: number;
  productsCount?: number;
  sellerCount?: number;

  constructor(
    revenue?: number,
    sale?: number,
    lostRevenue?: number,
    productsCount?: number,
    sellerCount?: number,
  ) {
    this.revenue = revenue;
    this.sale = sale;
    this.lostRevenue = lostRevenue;
    this.productsCount = productsCount;
    this.sellerCount = sellerCount;
  }
}
