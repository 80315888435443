import { getDateTime } from '../utils/date';

export class HistoryItem {
  time: string;
  type: string;
  url: string;

  constructor(time: string, type: string, url: string) {
    this.time = getDateTime(time);
    this.type = type;
    this.url = url;
  }
}
