export class ProductStats {
  productCount?: number;
  brandCount?: number;
  saleCount?: number;
  sellerCount?: number;

  constructor(productCount?: number, brandCount?: number, saleCount?: number, sellerCount?: number) {
    this.productCount = productCount;
    this.brandCount = brandCount;
    this.saleCount = saleCount;
    this.sellerCount = sellerCount;
  }
}
