import { useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './profile-keys-page.scss';
import BigLoader from '../../components/big-loader/big-loader';
import { TableColumn } from '../../view-models/table-column';
import { ReactComponent as QuestionIcon } from '../../shared/image/svg/question.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { linksUrl } from '../../consts/linksUrl';
import { ApiKeyItem } from '../../view-models/api-key-item';
import { apiKeysService } from '../../services/api-keys-service';
import { apiKeysColumns } from '../../consts/api-key-columns';
import { ColorsModal, Modal } from '../../ui/modal/modal';
import { SimpleTable } from '../../ui/simple-table/simple-table';
import { AddApiKeyRequestDto, AddApiKeyResponseDto } from '../../api/Api';

const profileKeysCN = bemCN('profile-keys-page');
export const ProfileKeysPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(apiKeysColumns);
  const [keys, setKeys] = useState<ApiKeyItem[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [deleteIdKey, setDeleteIdKey] = useState(-1);
  const [newKey, setNewKey] = useState<AddApiKeyRequestDto>({ name: '', apiKey: '' });
  const [errorText, setErrorText] = useState('');

  const onClickDeleteRow = (idItem: number) => {
    setDeleteIdKey(idItem);
    setIsShowModal(true);
  };
  apiKeysColumns[apiKeysColumns.length - 1].buttonOnClick = onClickDeleteRow;

  useEffect(() => {
    getKeys();
  }, []);

  const getKeys = () => {
    setIsLoading(true);
    apiKeysService.getKeys().then((res: ApiKeyItem[]) => {
      setKeys(res);
      setIsLoading(false);
    });
  };

  const deleteKeyFromApi = () => {
    setIsShowModal(false);
    apiKeysService.deleteKey(deleteIdKey).then(() => {
      getKeys();
    });
  };

  const addKey = () => {
    setErrorText('');
    apiKeysService.addKey(newKey).then((res: AddApiKeyResponseDto | string) => {
      if (typeof res === 'string') {
        setErrorText(res);
      } else {
        getKeys();
      }
    });
  };

  const onClickQuestionButton = () => {
    window.open(linksUrl.root + linksUrl.instructions, '_blank');
  };

  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={profileKeysCN()}>
      <p className="h1">Подключение ключа</p>
      <div className={profileKeysCN('combo-input')}>
        <input
          onChange={(v: any) => setNewKey({ ...newKey, name: v.target.value })}
          type="text"
          placeholder="Название ключа"
        />
        <input
          onChange={(v: any) => setNewKey({ ...newKey, apiKey: v.target.value })}
          type="text"
          placeholder="Ключ"
        />
        <Button
          onClick={addKey}
          size={SizesButton.big}
          color={ColorsButton.violet}
          text="Подключить"
        />
        <div data-title="Скопированный на предыдущем шаге. (Нажмите, чтобы открыть инструкцию для предыдущего шага)">
          <Button
            onClick={onClickQuestionButton}
            icon={<QuestionIcon />}
            size={SizesButton.small}
            color={ColorsButton.transition}
          />
        </div>
      </div>
      <p className={profileKeysCN('error')}>{errorText}</p>
      <p className="h1">Мои ключи</p>
      <SimpleTable columns={tableColumns} data={keys} />

      <Modal
        title="Вы уверены, что хотите удалить ключ?"
        color={ColorsModal.light}
        isShowDialog={isShowModal}
        setIsShowDialog={setIsShowModal}
      >
        <div className={profileKeysCN('modal-buttons')}>
          <Button
            onClick={() => setIsShowModal(false)}
            size={SizesButton.big}
            color={ColorsButton.white}
            text="Нет"
          />
          <Button
            onClick={deleteKeyFromApi}
            size={SizesButton.big}
            color={ColorsButton.violet}
            text="Да"
          />
        </div>
      </Modal>
    </div>
  );
};
