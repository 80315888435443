import { ColorsBarChart } from "../ui/bar-chart/bar-chart";

export class ChartDataItem {
  id: number;
  label: string;
  value: string | number;
  color?: string|ColorsBarChart;
  shortLabel?: string;

  constructor(id: number, label: string, value: string | number, color?: string|ColorsBarChart) {
    this.id = id;
    this.label = label;
    this.value = value;
    this.color = color;
    this.shortLabel = label.slice(0, 3) + '.';
  }
}
