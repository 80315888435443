import { FC, useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './range-inputs.scss';

export enum TypeRangeValue {
  from = 1,
  to,
}

export interface RangeInputsProps {
  id?: string;
  title?: string;
  min?: number;
  max?: number;
  fromValue: number | undefined;
  toValue: number | undefined;
  onChangeValue: (typeRangeValue: TypeRangeValue, value: number) => void;
}

const rangeInputsCn = bemCN('range-inputs');

export const RangeInputs: FC<RangeInputsProps> = ({
  id,
  title,
  max,
  min,
  fromValue,
  toValue,
  onChangeValue,
}) => {
  const validateValue = (e: any, typeRangeValue: TypeRangeValue) => {
    let value = e.target.value;

    if (min && value < min) {
      e.target.value = undefined;
    }
    if (min == 0 && value < min) {
      e.target.value = undefined;
    }
    if (max && value > max) {
      e.target.value = undefined;
    }

    let newValue = e.target.value;
    onChangeValue(typeRangeValue, newValue);
  };


  return (
    <div className={rangeInputsCn()}>
      {title && <span>{title}</span>}
      <div>
        <span>От</span>
        <input
          value={fromValue ? fromValue : ''}
          onChange={(e: any) => validateValue(e, TypeRangeValue.from)}
          min={min}
          type="number"
        ></input>
        <span>До</span>
        <input
          value={toValue ? toValue : ''}
          onChange={(e: any) => validateValue(e, TypeRangeValue.to)}
          max={max}
          type="number"
        ></input>
      </div>
    </div>
  );
};
