import './radio-group.scss';
import { FC, useState } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { bemCN } from '../../configs/bem-classname';

type RadioGroupProps = {
  items: any[];
  activeId: number;
  onChange(id: number): void;
  // setIsShow(isShow: boolean): void;
};

export const RadioGroup: FC<any> = ({ activeId, items, onChange, setIsShow}) => {
  const radioGroupCN = bemCN('radio-group');
  const [checkedId, setChekedId] = useState(activeId);

  const onClickItem = (id: number) => {
    setChekedId(id);
    onChange(id);
    setIsShow && setIsShow(false);
  };

  return (
    <div className={radioGroupCN()}>
      {items &&
        items.map((item:any) => (
          <div
            onClick={() => onClickItem(item.id)}
            key={item.id}
            // className={radioGroupCN('radioItem')}
          >
            <div>{item.name}</div>
            {checkedId == item.id ? <CheckMarkIcon /> : null}
          </div>
        ))}
    </div>
  );
};
