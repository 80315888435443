export class CategoryTree {
  value: string;
  label: string;
  children?: CategoryTree[];
  countProducts?: number;

  constructor(value: number, label: string, countProducts?: number, children?: CategoryTree[]) {
    this.value = value.toString();
    this.label = label;
    this.countProducts = countProducts;
    this.children = children;
  }
}
