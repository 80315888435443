import { bemCN } from '../../configs/bem-classname';
import './checkbox.scss';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';

import { FC, useState } from 'react';

type CheckboxProps = {
  id?: string;
  isCheck: boolean;
  onClick?: () => void;
};
const checkboxCN = bemCN('checkbox');

export const Checkbox: FC<CheckboxProps> = ({ id, isCheck, onClick }) => {
  const [isCheckble, setIsCheckble] = useState(isCheck);

  const clickCheckbox = () => {
    setIsCheckble(!isCheckble);
    onClick && onClick();
  };
  return (
    <div
      id={id}
      onClick={() => clickCheckbox()}
      className={checkboxCN({ isActive: isCheckble })}
    >
      {isCheckble && <CheckMarkIcon />}
    </div>
  );
};
