import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import './favorites-page.scss';
import { ReactComponent as ExportIcon } from '../../shared/image/svg/export.svg';
import { FavoriteCheckGroup } from '../../components/favorite-check-group/favorite-check-group';
import { useEffect, useState } from 'react';
import {
  FilterNavigationDto,
  LabelColorDto,
  LabelDto,
  LabelProductContentDto,
} from '../../api/Api';
import { favoriteService } from '../../services/favorite-service';
import BigLoader from '../../components/big-loader/big-loader';
import { SearchInput } from '../../components/search-input/search-input';
import { defaultFavoriteFilterDto } from '../../consts/default-filter-values';
import { Table } from '../../ui/table/table';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { useTable } from '../../hooks/useTable';
import { TableColumn } from '../../view-models/table-column';
import { productsService } from '../../services/products-service';
import { Pagination } from '../../components/pagination/pagination';
import { RootState, typedDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { setLabels } from '../../store/favorite-labels/action';
import { setAllFavoriteProducts, setFavoriteProducts } from '../../store/favorite-products/action';
import { Export } from '../../components/export/export';
import { LineLoading } from '../../components/line-loading/line-loading';
import { TypeFile } from '../../view-models/type-files';
import { TableLoad } from '../../components/table-load/table-load';

const favoritesPageCn = bemCN('favorites-page');
export const FavoritesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const labels: LabelDto[] = useSelector((state: RootState) => state.favoriteLabelsReducer)?.labels;
  const filterProducts: LabelProductContentDto[] = useSelector(
    (state: RootState) => state.filterFavoriteProductssReducer,
  )?.products;
  const [activeIds, setActiveIds] = useState<number[]>([]);
  const [filter, setFilter] = useState<FilterNavigationDto>({ ...defaultFavoriteFilterDto });
  const [isProductLoading, setIsProductLoading] = useState(false);

  const allProducts: LabelProductContentDto[] = useSelector(
    (state: RootState) => state.allFavoriteProductssReducer,
  )?.products;
  const [searchValue, setSearchValue] = useState('');
  const [productCount, setProductCount] = useState(0);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.favoriteColumn) || [],
  );
  const [updateLabel, setUpdateLabel] = useState<LabelDto>();
  const [isLoadingFileExport, setIsLoadingFileExport] = useState(false);

  useEffect(() => {
    tableColumns.length == 0 &&
      productsService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
  }, []);

  useEffect(() => {
    let startFilter = getParams();
    tableColumns.length == 0 &&
      productsService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
    getProducts(startFilter);
  }, [1]);

  useEffect(() => {
    favoriteService.getCountProducts().then((res: number) => setProductCount(res));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getLabels();
    getProducts(filter);
  }, []);

  const getLabels = () => {
    favoriteService.getLabels().then((res: LabelDto[]) => {
      typedDispatch(setLabels(res));
      setActiveIds(res.map((l: LabelDto) => l.id || -1).concat(-1));
      setIsLoading(false);
    });
  };

  const getProducts = (filter: FilterNavigationDto) => {
    setIsProductLoading(true);

    favoriteService.getProducts(filter).then((res: LabelProductContentDto[]) => {
      typedDispatch(setFavoriteProducts(res));
      setIsProductLoading(false);
      typedDispatch(setAllFavoriteProducts(res));
    });
  };

  const onChangeLabelName = (newName: string, labelId: number) => {
    let oldLabels = [...labels];
    let newLabel = { ...labels.find((l: LabelDto) => l.id === labelId), name: newName };
    setUpdateLabel(newLabel);
    oldLabels.forEach((l: LabelDto) => {
      if (l.id == labelId) {
        l.name = newName;
      }
    });
  };

  const saveChangeName = () => {
    favoriteService.changeLabelName(updateLabel || {}).then((res: any) => {});
  };

  useEffect(() => {
    if (allProducts.length > 0) {
      let newProducts: LabelProductContentDto[] = allProducts.filter((p: LabelProductContentDto) =>
        activeIds.includes(p.labelId || -1),
      );
      typedDispatch(setFavoriteProducts(newProducts));
    }
  }, [activeIds]);

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, pageNumber, setPageNumber } = useTable(
    productCount || 0,
    filter,
    defaultFavoriteFilterDto,
    setFilter,
    getProducts,
    10,
  );

  const onChangeSearchValue = (v: string) => {
    setSearchValue(v);
    typedDispatch(
      setFavoriteProducts(
        allProducts.filter(
          (p: LabelProductContentDto) => p.name?.toLowerCase().includes(v.toLowerCase()),
        ),
      ),
    );
  };

  //Клик на экспорт
  const onClickExport = (typeFile: TypeFile) => {
    setIsLoadingFileExport(true);
    favoriteService
      .startExportFile(
        typeFile,
        tableColumns.map((col: TableColumn) => col.id),
      )
      .then((res: string) => {
        setIsLoadingFileExport(false);
        productsService.getFile(res, typeFile);
      });
  };

  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={favoritesPageCn()}>
      <p className="h1">Избранное</p>
      <div className={favoritesPageCn('buttons')}>
        <Export onClickExport={onClickExport} />

        <FavoriteCheckGroup
          onChangeNameLabel={onChangeLabelName}
          items={labels}
          activeIds={activeIds}
          onChange={(ids: number[]) => {
            setActiveIds(ids);
          }}
          saveChangeName={saveChangeName}
        />
        <SearchInput value={searchValue} onChange={onChangeSearchValue} onEnterClick={() => {}} />
      </div>
      {isProductLoading ? (
        <TableLoad />
      ) : (
        <>
          <Pagination
            currentPageNumber={pageNumber}
            totalPagesCount={productCount ? Math.ceil(productCount / 10) : 1}
            onClick={onPagination}
          />
          <Table
            localStorageColumns={LocalStorageVariableName.favoriteColumn}
            columns={tableColumns}
            data={filterProducts}
            onSort={onSort}
            idActiveSort={filter.sortFieldId || 666}
            decs={filter.desc || false}
            // countRows={10}
          />
          <Pagination
            currentPageNumber={pageNumber}
            totalPagesCount={productCount ? Math.ceil(productCount / 10) : 1}
            onClick={onPagination}
          />
        </>
      )}

      {isLoadingFileExport && <LineLoading />}
    </div>
  );
};
