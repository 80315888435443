import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import { Input, TypeDataInput, TypeViewInput } from '../../ui/input/input';
import './login-page.scss';
import { linksUrl } from '../../consts/linksUrl';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { useEffect, useState } from 'react';
import { UserLoginDto } from '../../api/Api';
import { authService } from '../../services/auth-service';
import { ColorsModal, Modal } from '../../ui/modal/modal';

const loginPageCn = bemCN('login-page');

export const LoginPage = () => {
  const [authData, setAuthData] = useState<UserLoginDto>({
    email: '',
    passwordHash: '',
  });
  const [errorText, setErrorText] = useState('');
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const [isShowDoubleAuth, setIsShowDoubleAuth] = useState(searchParams.get('ds') ? true : false);
  const isReg = searchParams.get('ConfirmToken');

  const [isShowSuccessRegistation, setIsShowSuccessRegistation] = useState(false);

  useEffect(() => {
    if (isReg) {
      authService.confirmationEmail(isReg).then(() => {
        setIsShowSuccessRegistation(true);
      });
    }
  }, [1]);

  const onClickAuth = () => {
    setErrorText('');
    authService.auth(authData).then((res: any) => {
      if (res.ok) {
        navigate(linksUrl.analyitcs + linksUrl.analyticsProduct);
      } else {
        setErrorText(res);
      }
    });
  };

  return (
    <div className={loginPageCn()}>
      <div>
        <h1>Вход</h1>
        <Input
          id="emailInput"
          value={authData.email ? authData.email : ''}
          onChange={(v: string) => setAuthData({ ...authData, email: v })}
          placeholder="Почта"
          typeData={TypeDataInput.email}
          typeView={TypeViewInput.dark}
        />
        <Input
          id="passwordInput"
          value={authData.passwordHash ? authData.passwordHash : ''}
          onChange={(v: string) => setAuthData({ ...authData, passwordHash: v })}
          placeholder="Пароль"
          typeData={TypeDataInput.password}
          typeView={TypeViewInput.dark}
        />
        <span className={loginPageCn('error')}>{errorText !== '' && errorText}</span>
        <Link to={linksUrl.passwordRecovery}>Забыли пароль?</Link>
        <Button
          onClick={onClickAuth}
          text="Войти"
          color={ColorsButton.green}
          size={SizesButton.big}
        />
        <span>
          Еще не с нами? <Link to={linksUrl.register}>Зарегистрироваться</Link>
        </span>
      </div>

      <Modal
        isShowDialog={isShowDoubleAuth}
        setIsShowDialog={setIsShowDoubleAuth}
        color={ColorsModal.dark}
        title=""
      >
        <div className={loginPageCn('double-modal')}>
          <p>Осуществлен вход с другого устройства.</p>
          <p>Текущая сессия завершена.</p>
        </div>
      </Modal>
      <Modal
        setIsShowDialog={setIsShowSuccessRegistation}
        isShowDialog={isShowSuccessRegistation}
        title={''}
        color={ColorsModal.dark}
      >
        <div className={loginPageCn('success-reg')}>
          <p>Вы успешно зарегистрированы в сервисе.</p>
          <Button
            onClick={() => setIsShowSuccessRegistation(false)}
            size={SizesButton.big}
            color={ColorsButton.green}
            text="Начать пользоваться"
          />
        </div>
      </Modal>
    </div>
  );
};
