import { useNavigate, useParams } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import './promotion-card-page.scss';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { ReactComponent as ErrorIcon } from '../../shared/image/svg/error.svg';
import { ReactComponent as ArrowBackIcon } from '../../shared/image/svg/arrow-back.svg';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as QuestionIcon } from '../../shared/image/svg/question.svg';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { defaultPromotionCardFilter } from '../../consts/default-filter-values';
import { PromotionCardFilter } from '../../view-models/promotion-filter';
import { useEffect, useState } from 'react';
import { toCalendarDate } from '../../utils/date';
import { Calendar, PeriodType } from '../../components/calendar/calendar';
import BigLoader from '../../components/big-loader/big-loader';
import { CampaignCardDto } from '../../api/Api';
import { promotionService } from '../../services/promotion-service';
import { SmallLoader } from '../../components/small-loader/small-loader';
import DinamicLineChart from '../../ui/dinamic-line-chart/dinamic-line-chart';
import { LineChartPropName } from '../../view-models/product-analitics-line-chart-item';
import { useTimer } from 'react-timer-hook';
import { useTable } from '../../hooks/useTable';

const promotionCardPageCN = bemCN('promotion-card-page');

export const PromotionCardPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState<CampaignCardDto>();
  const cabinetId =
    localStorageService.getValue<number>(LocalStorageVariableName.promotionCabinetId) || -1;
  const navigate = useNavigate();
  const [filter, setFilter] = useState<PromotionCardFilter>({
    ...defaultPromotionCardFilter,
    cabinetId: cabinetId,
    campaignId: Number(id),
  });
  const [backCount, setBackCount] = useState(-1);

  const [isAccessRequest, setIsAccessRequest] = useState(true);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60);
  const { seconds, minutes, start, pause, restart } = useTimer({
    expiryTimestamp: expiryTimestamp,
    autoStart: false,
    onExpire: () => setIsAccessRequest(true),
  });

  //Получение данных
  useEffect(() => {
    getParams();
  }, []);

  const getCard = (filter: PromotionCardFilter) => {
    if (cabinetId) {
      setIsLoading(true);
      promotionService
        .getnCard({ ...filter, cabinetId: cabinetId, campaignId: Number(id) })
        .then((res: CampaignCardDto) => {
          setCampaign(res);
          setIsAccessRequest(false);
          restart(expiryTimestamp);
          setIsLoading(false);
        });
    }
    setBackCount(backCount - 1);
  };

  //Подключение инструментов для работы с таблицей
  const { getParams } = useTable(0, filter, defaultPromotionCardFilter, setFilter, getCard);

  if (isLoading) return <BigLoader color="dark" />;
  if (cabinetId == -1)
    return (
      <div className={promotionCardPageCN('error')}>
        <div>
          <ErrorIcon />
          <p>Что-то пошло не так...</p>
        </div>
        <p>Пожалуйста, вернитесь на страницу всех кампаний и повторите попытку.</p>
      </div>
    );
  return (
    <div className={promotionCardPageCN()}>
      <div className={promotionCardPageCN('header')}>
        <Button
          onClick={() => navigate(backCount)}
          color={ColorsButton.transition}
          size={SizesButton.small}
          icon={<ArrowBackIcon />}
        />
        <p className="h1">
          {campaign?.name + ' (' + campaign?.type + ') ' || 'Не удалось получить название кампании'}
        </p>

        <div
          data-title={
            'Получить данные можно 1 раз в минуту. Повторите попытку через ' +
            minutes +
            ':' +
            (seconds < 10 ? '0' + seconds.toString() : seconds.toString())
          }
          className={promotionCardPageCN('calendar-container', { isShow: !isAccessRequest })}
        >
          <Calendar
            isDisable={!isAccessRequest}
            changeValue={(v: CalendarPeriod) => {
              setFilter({
                ...filter,
                dateFrom: toCalendarDate(v.dateFrom),
                dateTo: toCalendarDate(v.dateTo),
              });
            }}
            value={
              new CalendarPeriod(
                new Date(filter.dateFrom || lastMonthRange),
                new Date(filter.dateTo || yeasteday),
              )
            }
            maxDate={yeasteday}
            minDate={campaign?.createdDate ? new Date(campaign?.createdDate) : undefined}
            maxPeriod={PeriodType.month}
          />
        </div>
        <div
          className={promotionCardPageCN('help', { isRed: !isAccessRequest })}
          data-title={
            'Получить данные для дашборда можно 1 раз в минуту. Максимальный период между датами в календаре 30 дней'
          }
        >
          <Button
            icon={<QuestionIcon />}
            size={SizesButton.small}
            color={ColorsButton.transition}
          />
        </div>
      </div>
      <div className={promotionCardPageCN('content')}>
        <div className={promotionCardPageCN('chart-stat-row')}>
          <div>
            <p>Дата старта</p>
            <p>{campaign?.createdDate !== null ? campaign?.createdDate : '-'}</p>
          </div>
          <div>
            <p>Дата окончания</p>
            <p>{campaign?.endTime !== null ? campaign?.endTime : '-'}</p>
          </div>
          <div>
            <p>Выручка, ₽</p>
            <p>{campaign?.revenue !== null ? campaign?.revenue?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Затраты на кампанию, ₽</p>
            <p>{campaign?.cost !== null ? campaign?.cost?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Чистая прибыль, ₽</p>
            <p>{campaign?.profit !== null ? campaign?.profit?.toLocaleString() : '-'}</p>
          </div>
        </div>
        <div className={promotionCardPageCN('chart-stat-row')}>
          <div>
            <p>CR</p>
            <p>{campaign?.cr !== null ? campaign?.cr + ' %' : '-'}</p>
          </div>
          <div>
            <p>CTR</p>
            <p>{campaign?.ctr !== null ? campaign?.ctr + ' %' : '-'}</p>
          </div>
          <div>
            <p>ROMI</p>
            <p>{campaign?.romi !== null ? campaign?.romi + ' %' : '-'}</p>
          </div>
          <div>
            <p>ROAS</p>
            <p>{campaign?.roas !== null ? campaign?.roas + ' %' : '-'}</p>
          </div>
          <div>
            <p>CPC</p>
            <p>{campaign?.cpc !== null ? campaign?.cpc + ' %' : '-'}</p>
          </div>
          <div>
            <p>CPO</p>
            <p>{campaign?.cpo !== null ? campaign?.cpo + ' %' : '-'}</p>
          </div>
        </div>
        <div className={promotionCardPageCN('chart-stat-row')}>
          <div>
            <p>Количество заказов, шт.</p>
            <p>{campaign?.orders !== null ? campaign?.orders?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Добавления в корзину, шт.</p>
            <p>{campaign?.atbs !== null ? campaign?.atbs?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Количество просмотров, шт.</p>
            <p>{campaign?.views !== null ? campaign?.views?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Количество кликов, шт.</p>
            <p>{campaign?.clicks !== null ? campaign?.clicks?.toLocaleString() : '-'}</p>
          </div>
        </div>
      </div>
      <div className={promotionCardPageCN('chart')}>
        {campaign ? (
          <DinamicLineChart
            title="Статистика по дням"
            propNames={[
              new LineChartPropName(1, 'Просмотры', 'views', 'шт'),
              new LineChartPropName(2, 'Клики', 'clicks', 'шт'),
              new LineChartPropName(3, 'Добавления в корзину', 'atbs', 'шт'),
              new LineChartPropName(4, 'Заказы', 'orders', 'шт'),
              new LineChartPropName(5, 'CTR', 'ctr', ' '),
            ]}
            basePropName="date"
            basePropLabel="Дата"
            data={campaign?.stats}
          />
        ) : (
          <SmallLoader />
        )}
      </div>
    </div>
  );
};
