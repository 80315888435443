import { LabelDto } from '../../api/Api';

const defaultState = {
  labels: [] as LabelDto[],
};

export const favoriteLabelsReducer = (_state = defaultState, _action: any) => {
  switch (_action.type) {
    case 'SET_LABELS': {
      if (_action.payload === 'null' || _action.payload === '')
        return { ..._state, labels: defaultState.labels };
      else return { ..._state, labels: _action.payload };
    }
    default: {
      return _state;
    }
  }
};
