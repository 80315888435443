import './line-chart.scss';
import { memo, useEffect, useId, useState } from 'react';
import {
  LineChart as RechartLineChart,
  Line,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
} from 'recharts';

import { bemCN } from '../../configs/bem-classname';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { Tooltip as CustomTooltip } from '../tooltip/tooltip';
import { LineChartPropName } from '../../view-models/product-analitics-line-chart-item';

export enum ColorLineChart {
  blue,
  green,
  red,
  orange,
  violet,
}

export enum TypeLineChart {
  line = 1,
  area,
}

type LineChartProps = {
  id?: string;
  color: ColorLineChart;
  typeLineChart: TypeLineChart;
  data: ChartDataItem[];
  labelY?: string;
  labelX?: string;
  unit?: string;
  errorText?: string;
  height?: number;
};

const colorsGradient = new Map<string, string[]>([
  ['red', ['rgba(193, 75, 75, 0.8)', 'rgba(193, 75, 75, 0.25)']],
  ['orange', ['rgba(198, 140, 71, 0.8)', 'rgba(198, 140, 71, 0.25)']],
  ['yellow', ['rgba(206, 208, 85, 0.8)', 'rgba(206, 208, 85, 0.25)']],
  ['green', ['rgba(90, 180, 62, 0.8)', 'rgba(90, 180, 62, 0.25)']],
  ['lightBlue', ['rgba(65, 188, 168, 0.8)', 'rgba(65, 188, 168, 0.25)']],
  ['blue', ['rgba(81, 149, 191, 0.8)', 'rgba(81, 149, 191, 0.25)']],
  ['violet', ['rgba(87, 92, 194, 0.8)', 'rgba(87, 92, 194, 0.25)']],
]);

const colors = new Map<string, string>([
  ['red', '#EF0B0B'],
  ['green', '#50C5B0'],
  ['blue', '#1D75BD99'],
]);
const customBarChartCN = bemCN('barChart');

export const LineChart = memo((props: LineChartProps) => {
  let { id, data, color, typeLineChart, labelY, labelX, errorText, unit, height } = props;

  let idGrad = useId();
  const colorType = ColorLineChart[color];

  let colorGradient = colorType ? colorsGradient.get(colorType) : colorsGradient.get('blue');
  // let idGradient = colorType ? colorType : 'blue';
  // idGradient = idGradient + 'id';

  return (
    <div className={customBarChartCN()}>
      {data.length === 0 ? (
        <div className={customBarChartCN('error')}>{errorText}</div>
      ) : (
        <ResponsiveContainer width="100%" height={height ? height : 278}>
          {typeLineChart === TypeLineChart.area ? (
            <AreaChart
              width={854}
              height={578}
              data={data}
              margin={{ top: 10, right: 20, left: 10, bottom: 10 }}
            >
              <defs>
                <linearGradient id={idGrad} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={colorGradient && colorGradient[0]} />
                  <stop offset="95%" stopColor={colorGradient && colorGradient[1]} />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} />
              <XAxis
                tickCount={7}
                dy={15}
                //dx={-25}
                //angle={-45}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fill: 'var(--font-color)',
                }}
                axisLine={false}
                dataKey={'label'}
                // interval={1}
              />
              <Area
                dot={false}
                type="monotone"
                dataKey="value"
                stroke={colorGradient && colorGradient[0]}
                fill={`url(#${idGrad})`}
              />
              <YAxis
                height={578}
                width={labelY ? 80 : 35}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fill: 'var(--bar-chart-y-axis-label)',
                }}
                type="number"
                axisLine={false}
              >
                <Label
                  offset={35}
                  dy={-75}
                  value={labelY}
                  angle={-90}
                  position={'left'}
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Manrope',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '33px',
                    fill: '#222',
                  }}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip unit={unit} />} cursor={{ fill: 'transparent' }} />
            </AreaChart>
          ) : (
            <RechartLineChart
              width={854}
              height={578}
              data={data}
              margin={{ top: 10, right: 20, left: 10, bottom: 10 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                tickCount={7}
                dy={15}
                //dx={-25}
                //angle={-45}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fill: 'var(--font-color)',
                }}
                axisLine={false}
                dataKey={'label'}
                // interval={1}
              />
              <Line
                dot={false}
                type="monotone"
                dataKey="value"
                stroke={colors.get(colorType)}
                strokeWidth={2}
              />
              <YAxis
                height={578}
                width={labelY ? 80 : 35}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fill: 'var(--bar-chart-y-axis-label)',
                }}
                type="number"
                axisLine={false}
              >
                <Label
                  offset={35}
                  dy={-75}
                  value={labelY}
                  angle={-90}
                  position={'left'}
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Manrope',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '33px',
                    fill: '#222',
                  }}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip unit={unit} />} cursor={{ fill: 'transparent' }} />
            </RechartLineChart>
          )}
        </ResponsiveContainer>
      )}
    </div>
  );
});

export default LineChart;
