import { bemCN } from '../../configs/bem-classname';
import './sidebar.scss';
import { ReactComponent as Logo } from '../../shared/image/svg/logo.svg';
import { menu } from '../../consts/sidebar-links';
import SideBarItem from './sidebar-item/sidebar-item';
import { useEffect, useState } from 'react';
import Toggle from '../../ui/toggle/toggle';
import { ReactComponent as Moon } from '../../shared/image/svg/moon.svg';
import { ReactComponent as Sun } from '../../shared/image/svg/sun.svg';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { Link } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';

const sidebarCn = bemCN('sidebar');

const SideBar = () => {
  const [activeId, setActiveId] = useState<number | null>(null);

  let theme = localStorageService.getValue(LocalStorageVariableName.theme);

  const [isChecked, setIsCheked] = useState(theme && theme === 'dark' ? false : true);

  const changeTheme = (themeName: string) => {
    localStorageService.setValue(LocalStorageVariableName.theme, themeName);
  };

  useEffect(() => {
    isChecked ? changeTheme('light') : changeTheme('dark');
    let body = document.getElementsByTagName('body')[0];
    if (body) {
      isChecked ? body.classList.remove('_dark') : body.classList.add('_dark');
    }
  }, [isChecked]);

  const [isOpenMenu, setIsOpenMune] = useState(false);

  const onClickMenuButton = () => {
    let navIcon: HTMLElement = document.getElementById('nav-icon') as HTMLElement;
    let sideBar: HTMLElement = document.getElementsByClassName('sidebar')[0] as HTMLElement;
    if (navIcon) {
      if (!isOpenMenu) {
        navIcon.classList.add('open');
        sideBar.classList.add('open');
      } else {
        navIcon.classList.remove('open');
        sideBar.classList.remove('open');
      }
      setIsOpenMune(!isOpenMenu);
    }
  };

  return (
    <div className={sidebarCn()}>
      <Link to={linksUrl.analyitcs + linksUrl.analyticsProduct} className={sidebarCn('logo')}>
        <div>
          <Logo />
        </div>
        <div className="h2">STAT</div>
      </Link>
      <div onClick={onClickMenuButton} id="top-menu-btn">
        <div id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {menu.map((item, i) => {
        return (
          <SideBarItem
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            path={item?.path}
            links={item?.links}
            active={activeId === item.id}
            setActive={setActiveId}
            onHideMenuModile={onClickMenuButton}
          />
        );
      })}
      <div className={sidebarCn('toggle')}>
        <div className={sidebarCn('toggle-icon')}>
          <Sun />
        </div>
        <Toggle
          isChecked={!isChecked}
          onChangeChecked={(isCheck: boolean) => setIsCheked(!isCheck)}
        />
        <div className={sidebarCn('toggle-icon')}>
          <Moon />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
