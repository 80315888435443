import { FC, useEffect, useState } from 'react';
import './profile-info-page.scss';
import { bemCN } from '../../configs/bem-classname';
import { Input, TypeDataInput, TypeViewInput } from '../../ui/input/input';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { useSelector } from 'react-redux';
import { RootState, typedDispatch } from '../../store';
import { ReactComponent as ProfileIcon } from '../../shared/image/svg/profile.svg';
import { ReactComponent as CameraIcon } from '../../shared/image/svg/camera.svg';
import { ProfileDto } from '../../api/Api';
import { profileService } from '../../services/profile-service';
import { getBase64 } from '../../utils/base64';
import { setPhoto } from '../../store/profile-image/action';
import { User } from '../../view-models/user';
import { ColorsModal, Modal } from '../../ui/modal/modal';
import { Link } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';
import { passwordHasError, phoneHasError } from '../../utils/validation-input';
interface ProfileInfoProps {}
const profileInfoCN = bemCN('profile-info-page');

enum TypeUpdateData {
  name = 1,
  phone,
  image,
}
export const ProfileInfoPage: FC<ProfileInfoProps> = ({}) => {
  const photo: string = useSelector((state: RootState) => state.profileImageReducer)?.profilePhoto;
  const [user, setUser] = useState<User>();
  const [updateUser, setUpdateUser] = useState<User>();
  const [isShowModal, setIsShowModal] = useState(false);

  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [oldPasswors, setOldPasswor] = useState('');
  const [newPasswors1, setNewPasswor1] = useState('');
  const [newPasswors2, setNewPasswor2] = useState('');

  useEffect(() => {
    profileService.getProfile().then((res: ProfileDto) => {
      let u: User = new User(res.name || '', res.phoneNumber || '', res.email || '', photo);
      setUser({ ...u });
      setUpdateUser({ ...u });
    });
  }, []);

  const [isUpdate, setIsUpdate] = useState<{ isName: Boolean; isPhone: Boolean; isImage: Boolean }>(
    {
      isName: false,
      isPhone: false,
      isImage: false,
    },
  );

  const [isDisableSave, setIsDisableSave] = useState(true);
  const [isDisableSavePassword, setIsDisableSavePassword] = useState(true);

  const updateInput = (v: string, typeData: TypeUpdateData) => {
    if (updateUser) {
      switch (typeData) {
        case TypeUpdateData.name: {
          setUpdateUser({ ...updateUser, name: v });
          break;
        }
        case TypeUpdateData.phone: {
          setUpdateUser({ ...updateUser, phone: v });
          break;
        }
      }
    }
  };

  const onClickEditImage = () => {
    const input: HTMLInputElement = document.createElement('input');
    input.setAttribute('type', 'file');
    input.accept = 'image/jpg, image/jpeg';

    input.onchange = () => {
      let file;
      if (input !== null && input.files != null) {
        file = input.files[0];
        if (file.size / (1024 * 1024) < 10) {
          getBase64(file, (res: string) => {
            setUpdateUser(updateUser ? { ...updateUser, imageBase64: res as string } : undefined);
            typedDispatch(setPhoto(res as string));
          });
        } else {
          alert('Размер файла не должен превышать 10 МБ.');
        }
      }
    };

    input.click();
  };

  const deleteImage = () => {
    if (updateUser) {
      typedDispatch(setPhoto('' as string));
      setUpdateUser({ ...updateUser, imageBase64: '' });
    }
  };

  useEffect(() => {
    if (updateUser && user) {
      let current = {
        isName: false,
        isPhone: false,
        isImage: false,
      };
      if (updateUser.name !== user.name) {
        current.isName = true;
      } else {
        current.isName = false;
      }

      if (updateUser.phone !== user?.phone) {
        current.isPhone = true;
      } else {
        current.isPhone = false;
      }

      if (updateUser.imageBase64 !== user?.imageBase64) {
        current.isImage = true;
      } else {
        current.isImage = false;
      }

      setIsUpdate(current);
    }
  }, [updateUser]);

  useEffect(() => {
    if (updateUser && phoneHasError(updateUser.phone)) {
      setIsDisableSave(true);
    } else if (isUpdate.isImage || isUpdate.isName || isUpdate.isPhone) {
      setIsDisableSave(false);
    } else if (!isUpdate.isImage && !isUpdate.isName && !isUpdate.isPhone) {
      setIsDisableSave(true);
    }
  }, [isUpdate]);

  const onClickSave = () => {
    if (updateUser) {
      let current = {
        isName: false,
        isPhone: false,
        isImage: false,
      };
      if (isUpdate.isName) {
        profileService.updateName(updateUser.name).then(() => {
          current.isName = false;
          setIsUpdate(current);
        });
      }
      if (isUpdate.isPhone) {
        profileService.updatePhone(updateUser.phone).then(() => {
          current.isPhone = false;
          setIsUpdate(current);
        });
      }
      if (isUpdate.isImage) {
        profileService.updateImage(updateUser.imageBase64).then(() => {
          current.isImage = false;
          setIsUpdate(current);
        });
      }
    }
  };

  useEffect(() => {
    if (newPasswors1 !== '' && newPasswors2 !== '') {
      if (newPasswors1 !== newPasswors2) {
        setPasswordErrorText('Пароли не совпадают');
        setIsDisableSavePassword(true);
      } else if (passwordHasError(newPasswors1) || passwordHasError(newPasswors2)) {
        setIsDisableSavePassword(true);
        setPasswordErrorText('');
      } else {
        setPasswordErrorText('');
        setIsDisableSavePassword(false);
      }
    }
  }, [newPasswors1, newPasswors2]);

  const savePassword = () => {
    profileService.updatePassword(oldPasswors, newPasswors1).then((res) => {
      if (res) {
        setPasswordErrorText(res);
      } else {
        setNewPasswor1('');
        setNewPasswor2('');
        setOldPasswor('');
        setIsDisableSavePassword(true);
      }
    });
  };

  return (
    <div className={profileInfoCN()}>
      <div className={profileInfoCN('data')}>
        <div className={profileInfoCN('input')}>
          <p>Имя пользователя</p>
          <Input
            value={updateUser?.name || ''}
            typeView={TypeViewInput.normal}
            typeData={TypeDataInput.text}
            onChange={(v: string) => {
              updateInput(v, TypeUpdateData.name);
            }}
          />
        </div>
        <div className={profileInfoCN('input')}>
          <p>Номер телефона</p>
          <Input
            isValidate
            value={updateUser?.phone || ''}
            typeView={TypeViewInput.normal}
            typeData={TypeDataInput.phone}
            onChange={(v: string) => {
              updateInput(v, TypeUpdateData.phone);
            }}
          />
        </div>
        <div className={profileInfoCN('input')}>
          <p>Email</p>
          <Input
            isDisable
            value={updateUser?.email || ''}
            typeView={TypeViewInput.normal}
            typeData={TypeDataInput.text}
            onChange={(v: string) => {}}
          />
        </div>
        <Button
          onClick={onClickSave}
          noDisabled={isDisableSave}
          size={SizesButton.big}
          color={ColorsButton.violet}
          text="Сохранить изменения"
        />
        <Button
          onClick={() => setIsShowModal(true)}
          size={SizesButton.big}
          color={ColorsButton.white}
          text="Изменить пароль"
        />
      </div>
      <div className={profileInfoCN('image')}>
        {photo === null ? <ProfileIcon /> : <img src={'data:image/jpeg;base64, ' + photo} />}
        <Button
          onClick={onClickEditImage}
          size={SizesButton.small}
          color={ColorsButton.violet}
          icon={<CameraIcon />}
        />
        <Button
          onClick={deleteImage}
          size={SizesButton.big}
          color={ColorsButton.white}
          text="Удалить фото"
        />
      </div>
      <Modal
        title="Изменение пароля"
        isShowDialog={isShowModal}
        setIsShowDialog={setIsShowModal}
        color={ColorsModal.light}
      >
        <div className={profileInfoCN('password-modal')}>
          <div className={profileInfoCN('input')}>
            <p>Старый пароль</p>
            <Input
              onChange={setOldPasswor}
              value={oldPasswors}
              typeData={TypeDataInput.password}
              typeView={TypeViewInput.password}
            />
          </div>
          <div className={profileInfoCN('input')}>
            <p>Новый пароль</p>
            <Input
              isValidate
              onChange={setNewPasswor1}
              value={newPasswors1}
              typeData={TypeDataInput.password}
              typeView={TypeViewInput.password}
            />
          </div>
          <div className={profileInfoCN('input')}>
            <p>Повторите новый пароль</p>
            <Input
              isValidate
              onChange={setNewPasswor2}
              value={newPasswors2}
              typeData={TypeDataInput.password}
              typeView={TypeViewInput.password}
            />
          </div>
          <p>{passwordErrorText}</p>
          <Link to={linksUrl.passwordRecovery}>Забыли пароль?</Link>
          <Button
            onClick={savePassword}
            noDisabled={isDisableSavePassword}
            size={SizesButton.big}
            color={ColorsButton.violet}
            text="Сохранить изменения"
          />
        </div>
      </Modal>
    </div>
  );
};
