
import { API } from '../configs/api';
import { Country } from '../view-models/country';

class DictionaryService {
  async getCountries() {
    try {
      let res = API.fields.getCountriesCreate();
      let result = (await res).data;
      return (result as Country[]) || []; 
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }
}

export const dictionaryService: DictionaryService = new DictionaryService();
