import { Outlet, redirect } from 'react-router-dom';
import { TopMenu } from '../../components/top-menu/top-menu';
import { bemCN } from '../../configs/bem-classname';
import './profile-page.scss';
import { topMenuLinksProfile } from '../../consts/top-menu-links';
const profilePageCn = bemCN('profile-page');

export const ProfilePage = () => {
  return (
    <div className={profilePageCn()}>
      <TopMenu links={topMenuLinksProfile} />
      <Outlet />
    </div>
  );
};
