import { linksUrl } from './linksUrl';
import { ReactComponent as AnalisisIcon } from '../shared/image/svg/analysis.svg';
import { ReactComponent as GoodsIcon } from '../shared/image/svg/goods.svg';
import { ReactComponent as ProfileIcon } from '../shared/image/svg/profile-sidebar.svg';
import { ReactComponent as HelpIcon } from '../shared/image/svg/help.svg';
import { ReactComponent as LogoutIcon } from '../shared/image/svg/logout.svg';

export const menu: any[] = [
  {
    id: 0,
    icon: <AnalisisIcon></AnalisisIcon>,
    title: 'Аналитика',
    links: [
      {
        name: 'Товары',
        path: linksUrl.analyitcs + linksUrl.analyticsProduct,
      },
      {
        name: 'Категории',
        path: linksUrl.analyitcs + linksUrl.analyitcsCategory,
      },
      {
        name: 'Продавцы',
        path: linksUrl.analyitcs + linksUrl.analyitcsSeller,
      },
      {
        name: 'Бренды',
        path: linksUrl.analyitcs + linksUrl.analyticsBrand,
      },
    ],
  },
  {
    id: 1,
    icon: <GoodsIcon></GoodsIcon>,
    title: 'Моя реклама',
    path: linksUrl?.promotion,
  },
  {
    id: 2,
    icon: <ProfileIcon></ProfileIcon>,
    title: 'Профиль',
    links: [
      {
        name: 'Мой профиль',
        path: linksUrl?.profile + linksUrl?.profileInfo,
      },
      {
        name: 'Тариф и оплата',
        path: linksUrl?.profile + linksUrl?.profileTariffs,
      },
      {
        name: 'История запросов',
        path: linksUrl?.profile + linksUrl?.profileHistory,
      },
      {
        name: 'Ключи',
        path: linksUrl?.profile + linksUrl?.profileKeys,
      },
      // {
      //   name: 'Уведомления',
      //   path: linksUrl?.profile + '/' + linksUrl?.profileNotices,
      // },
    ],
  },
  {
    id: 3,
    icon: <HelpIcon></HelpIcon>,
    title: 'Помощь',
    path: linksUrl.help,
  },
  {
    id: 4,
    icon: <LogoutIcon></LogoutIcon>,
    title: 'Выход',
    path: '#',
  },
];
