export class HelpItem {
  id: number;
  question: string;
  answer: string;
  link?: HelpLink;

  constructor(id: number, question: string, answer: string, link?: HelpLink) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.link = link;
  }
}

export class HelpLink {
  text: string;
  link: string;
  constructor(text: string, link: string) {
    this.text = text;
    this.link = link;
  }
}
