import { FeedbackFormDto } from '../api/Api';
import { API } from '../configs/api';

class FeedbackService {
  async sendMessage(feedback: FeedbackFormDto) {
    try {
      let res = API.feedback.feedbackFormCreate(feedback);
      let result = (await res).data;
      return true;
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }
}

export const feedbackService: FeedbackService = new FeedbackService();
