import {
  CatalogFilterDto,
  CatalogStatisticsByDate,
  CatalogStocksDto,
  CategoryStatisticsDto,
  FilterFieldDto,
  MenuDto,
  ProductFilterDateDto,
  ProductFilterDto,
  SellerCatalogFilter,
  SellerRowDto,
  SubCatalogsDto,
} from '../api/Api';
import { API } from '../configs/api';
import { linkColumnsName } from '../consts/link-column-names';
import { formatFullDate } from '../utils/date';
import { CategoryTree } from '../view-models/category';
import { SellerFilterDto } from '../view-models/seller-filter-dto';

import { TableColumn, TypeColumn } from '../view-models/table-column';
import { sellersService } from './sellers-service';

class CategoriesService {
  async getCategories() {
    try {
      let res = API.categories.getMainMenuList();
      let result = (await res).data;

      let cats: CategoryTree[] = this.mapToCheckGroupTree(result);
      return cats || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  mapToCheckGroupTree(categories: MenuDto[]) {
    let categoryTree: CategoryTree[] = [];
    categories.map((c: MenuDto, index: number) => {
      let childrens = this.mapToCheckGroupTree(c.childs || []);
      let newCat: CategoryTree = new CategoryTree(
        c.id || index,
        c.name || '',
        c.productCount || 0,
        childrens.length > 0 ? childrens : undefined,
      );
      categoryTree.push(newCat);
    });

    return categoryTree;
  }

  async getSubcategory(filter: ProductFilterDto) {
    try {
      let res = API.categories.getCategoryStatisticsCreate(filter);
      let result = (await res).data;
      return result as CategoryStatisticsDto;
    } catch (err: any) {
      return {};
    }
  }

  async getSubcategoryStats(filter: ProductFilterDateDto) {
    try {
      let res = API.categories.getCategoryStatisticsByDateCreate(filter);
      let result = (await res).data;
      return result as CatalogStatisticsByDate;
    } catch (err: any) {
      return {};
    }
  }

  async getSubcategoryTable(filter: ProductFilterDateDto) {
    try {
      let res = API.categories.getSubcategoriesStatisticsCreate({
        from: filter.dateFrom,
        to: filter.dateTo,
        themes: filter.themeCategories,
      });
      let result = (await res).data;
      return result as SubCatalogsDto[];
    } catch (err: any) {
      return [];
    }
  }

  async getSubcategoryDinamicStats(filter: ProductFilterDateDto) {
    try {
      let res = API.categories.getScheduleCreate({
        from: filter.dateFrom,
        to: filter.dateTo,
        themes: filter.themeCategories,
      });
      let result = (await res).data;
      return (result as CatalogStocksDto[]).map((item: CatalogStocksDto) => {
        return { ...item, date: formatFullDate(item.date || '') } as CatalogStocksDto;
      });
    } catch (err: any) {
      return [];
    }
  }

  async getSellersSubcategory(filter: SellerFilterDto) {
    try {
      let apiFilter = {
        navigation: {
          desc: filter.desc || false,
          limit: 10,
          offset: filter.offset || 0,
          sortFieldId: Number(filter.sortFieldId) || 108,
        },
        from: filter.dateFrom,
        to: filter.dateTo,
        themes: filter.themeCategories,
      } as SellerCatalogFilter;
      let res = API.categories.getSellersCreate(apiFilter);
      let result = (await res).data;
      return result as SellerRowDto[];
    } catch (err: any) {
      return [];
    }
  }
}

export const categoriesService: CategoriesService = new CategoriesService();
