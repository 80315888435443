import { TableColumn, TypeColumn } from "../view-models/table-column";

export const subcategoryColumns = [
    // new TableColumn(1, 'id', 'Id', TypeColumn.string, true, 100, false, false, 1),
    new TableColumn(2, 'name', 'Название', TypeColumn.string, true, 145, false, false, 2),
    new TableColumn(3, 'revenue', 'Выручка, ₽', TypeColumn.string, true, 250, false, false, 3),
    new TableColumn(4, 'sales', 'Продажи', TypeColumn.string, true, 200, false, false, 4),
    new TableColumn(5, 'productsCount', 'Товары, шт', TypeColumn.string, true, 200, false, false, 5),
    new TableColumn(6, 'productsCountSales', 'Товары с продажами, шт', TypeColumn.string, true, 210, false, false, 6),
    new TableColumn(6, 'brandsCount', 'Бренды, шт', TypeColumn.string, true, 210, false, false, 6),
    new TableColumn(6, 'brandsSales', 'Бренды с продажами, шт', TypeColumn.string, true, 210, false, false, 6),
  ];