import { FC, useEffect, useState } from 'react';
import { SearchInput } from '../../search-input/search-input';
import { RangeInputs, TypeRangeValue } from '../../range-inputs/range-inputs';
import { bemCN } from '../../../configs/bem-classname';
import { CheckGroup, CheckMarkPosition } from '../../check-group/check-group';
import { SelectItem } from '../../../view-models/select-item';
import { Select, TypeSelectChild } from '../../select/select';
import './products-filter.scss';
import { BrandDto, ProductFilterDto } from '../../../api/Api';
import { categoriesService } from '../../../services/categories-service';
import { dictionaryService } from '../../../services/dictionary-service';
import { Country } from '../../../view-models/country';
import { brandService } from '../../../services/brands-service';
import { CategoryTree } from '../../../view-models/category';
import { FilterCategorySelect } from '../../category-selected-item/filter-category-select';
import { SearchFilterCategorySelect } from '../../category-selected-item/search-filter-category-select';
import { defaultProductFilter } from '../../../consts/default-filter-values';

export interface ProductsFilterProps {
  filterValues: ProductFilterDto;
  setFilterValues: (newF: ProductFilterDto) => void;
  isHideBrand?: boolean;
  isHideCategory?: boolean;
}
const productFilterBarCn = bemCN('product-filter');
export const ProductFilter: FC<ProductsFilterProps> = ({
  filterValues,
  setFilterValues: setFilterValues,
  isHideBrand,
  isHideCategory,
}) => {
  const [categories, setCategories] = useState<CategoryTree[]>();
  const [checkCategoryNames, setCheckCategoryNames] = useState<string[]>([]);
  const [filterCategories, setFilterCategories] = useState<CategoryTree[]>();
  const [searchValueCategory, setSearchValueCategory] = useState('');

  const [searchValueBrand, setSearchValueBrand] = useState('');
  const [brands, setBrands] = useState<BrandDto[]>([]);
  const [selectBrands, setSelectBrands] = useState<BrandDto[]>([]);

  const [countries, setСountries] = useState<Country[]>();
  const [filterCountries, setFilterСountries] = useState<Country[]>();
  const [searchValueCountry, setSearchValueCountry] = useState('');

  useEffect(() => {
    dictionaryService.getCountries().then((res: Country[]) => {
      setСountries(res);
      setFilterСountries(res);
    });
  }, [1]);

  useEffect(() => {
    if (!isHideBrand) {
      getBrandsByName('A');
    }
  }, [1]);

  useEffect(() => {
    if (!isHideCategory)
      categoriesService.getCategories().then((res: CategoryTree[]) => {
        setCategories(res);
      });
  }, [1]);

  useEffect(() => {
    return () => {
      if (!isHideBrand) setSearchValueBrand('');
      // if (!isHideCategory) setSearchValueCategory('');
      setSearchValueCountry('');
    };
  });

  useEffect(() => {
    if (JSON.stringify(filterValues) === JSON.stringify(defaultProductFilter)) {
      if (!isHideCategory) {
        setSearchValueCategory('');
        setCheckCategoryNames([]);
      }
      if (!isHideBrand) {
        setSelectBrands([]);
        getBrandsByName('A');
      }
    }
  }, [filterValues]);

  const getBrandsByName = (v: string) => {
    brandService.getBrands({ name: v, limit: 10 }).then((res: BrandDto[]) => {
      setBrands(res);
      setSelectBrands(res.filter((b: BrandDto) => filterValues.brandIds?.includes(b.id || -1)));
    });
  };

  //Поиск в странах
  const onChangeSearchCounty = (v: string) => {
    setSearchValueCountry(v);
    setFilterСountries([
      ...(countries || []).filter((c: Country) => c.name.toLowerCase().includes(v.toLowerCase())),
    ]);
  };

  //Поиск в брендах
  const onChangeSearchBrand = (v: string) => {
    setSearchValueBrand(v);
    getBrandsByName(v);
  };

  //Поиск в категориях
  const onChangeSearchCategory = (v: string) => {
    const extractVal = (items: CategoryTree[]): CategoryTree[] => {
      return items
        .map((item: CategoryTree) => {
          return { ...item, children: extractVal((item.children as CategoryTree[]) || []) };
        })
        .filter(
          (item: CategoryTree) =>
            (item.children && item.children.length > 0) ||
            item.label.toLowerCase().includes(v.toLowerCase()),
        );
    };
    let newFilterCategories: CategoryTree[] = extractVal(categories || []);
    setFilterCategories(newFilterCategories);
    setSearchValueCategory(v);
  };

  const onChangeRangeValue = (typeRangeValue: TypeRangeValue, value: number, name: string) => {
    if (typeRangeValue === TypeRangeValue.from) {
      setFilterValues({ ...filterValues, [`${name}From`]: Number(value) });
    } else if (typeRangeValue === TypeRangeValue.to) {
      if (Number(value) === 0) setFilterValues({ ...filterValues, [`${name}To`]: undefined });
      else setFilterValues({ ...filterValues, [`${name}To`]: Number(value) });
    }
  };

  //Получение активных категорий для подписи в селектор
  const getActiveLabels = (cats: CategoryTree[]) => {
    let result: string[] = [];
    cats.map((c: CategoryTree, index: number) => {
      if (c.children) {
        let adding = getActiveLabels(c.children || []);
        result = result.concat(adding);
      } else {
        filterValues.themeCategories &&
          filterValues.themeCategories.includes(Number(c.value)) &&
          result.push(c.label);
      }
    });
    return result;
  };

  useEffect(() => {
    setCheckCategoryNames(getActiveLabels(categories || []));
  }, [filterValues.themeCategories, categories]);

  return (
    <div className={productFilterBarCn()}>
      <SearchInput
        value={filterValues.name || ''}
        onChange={(v: string) => setFilterValues({ ...filterValues, name: v })}
        onEnterClick={() => {}}
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'price');
        }}
        toValue={filterValues.priceTo || undefined}
        fromValue={filterValues.priceFrom || undefined}
        min={1}
        title="Цена со скидкой, ₽"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'oldPrice');
        }}
        toValue={filterValues.oldPriceTo || undefined}
        fromValue={filterValues.oldPriceFrom || undefined}
        min={1}
        title="Цена, ₽"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'discount');
        }}
        toValue={filterValues.discountTo || undefined}
        fromValue={filterValues.discountFrom || undefined}
        min={0}
        max={100}
        title="Скидка, %"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'balance');
        }}
        toValue={filterValues.balanceTo || undefined}
        fromValue={filterValues.balanceFrom || undefined}
        min={0}
        title="Текущий остаток, шт"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'sales');
        }}
        toValue={filterValues.salesTo || undefined}
        fromValue={filterValues.salesFrom || undefined}
        min={0}
        title="Продажи, шт"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'revenue');
        }}
        toValue={filterValues.revenueTo || undefined}
        fromValue={filterValues.revenueFrom || undefined}
        min={0}
        title="Выручка, ₽"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'feedbaks');
        }}
        toValue={filterValues.feedbaksTo || undefined}
        fromValue={filterValues.feedbaksFrom || undefined}
        min={0}
        title="Отзывы, шт"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'raiting');
        }}
        toValue={filterValues.raitingTo || undefined}
        fromValue={filterValues.raitingFrom || undefined}
        min={1}
        max={5}
        title="Рейтинг"
      />
      {/* <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'countCategory');
        }}
        toValue={filterValues.countCategoryTo || undefined}
        fromValue={filterValues.countCategoryFrom || undefined}
        min={1}
        title="Количество категорий, шт"
      /> */}
      {!isHideCategory && (
        <div className={productFilterBarCn('selected')}>
          <span>Категория</span>
          <Select typeSelectChild={TypeSelectChild.check} activeNames={checkCategoryNames}>
            <SearchInput
              value={searchValueCategory}
              onChange={onChangeSearchCategory}
              onEnterClick={() => {}}
            />
            <div className={productFilterBarCn('category-container')}>
              {categories && searchValueCategory === '' && (
                <FilterCategorySelect
                  checkedItems={filterValues.themeCategories || []}
                  setCheckedItems={(ids: number[]) => {
                    setFilterValues({ ...filterValues, themeCategories: ids });
                  }}
                  items={categories || []}
                />
              )}
              {filterCategories && searchValueCategory !== '' && (
                <SearchFilterCategorySelect
                  checkedItems={filterValues.themeCategories || []}
                  setCheckedItems={(ids: number[]) => {
                    setFilterValues({ ...filterValues, themeCategories: ids });
                  }}
                  items={filterCategories || []}
                />
              )}
            </div>
          </Select>
        </div>
      )}

      {!isHideBrand && (
        <div className={productFilterBarCn('selected')}>
          <span>Бренд</span>
          <Select
            typeSelectChild={TypeSelectChild.check}
            activeNames={selectBrands.map((item: BrandDto) => item.name || '')}
          >
            <SearchInput
              value={searchValueBrand}
              onChange={onChangeSearchBrand}
              onEnterClick={() => {}}
            />
            <CheckGroup
              items={(brands || [])
                .concat(selectBrands)
                .map((item: BrandDto) => new SelectItem(item.id || -1, item.name || ''))}
              checkMarkPosition={CheckMarkPosition.left}
              activeIds={filterValues.brandIds || []}
              onChange={(ids: number[]) => {
                setSelectBrands(brands.filter((br: BrandDto) => ids.includes(br.id || -1)));
                setFilterValues({ ...filterValues, brandIds: ids });
              }}
            />
          </Select>
        </div>
      )}

      <div className={productFilterBarCn('selected')}>
        <span>Страна производителя</span>
        <Select
          typeSelectChild={TypeSelectChild.check}
          activeNames={(countries || [])
            .filter((c: Country) => filterValues.countryIds?.includes(c.id))
            .map((item: Country) => item.name)}
        >
          <SearchInput
            value={searchValueCountry}
            onChange={onChangeSearchCounty}
            onEnterClick={() => {}}
          />
          <CheckGroup
            items={
              filterCountries
                ? filterCountries.map((item: Country) => new SelectItem(item.id, item.name))
                : []
            }
            checkMarkPosition={CheckMarkPosition.left}
            activeIds={filterValues.countryIds || []}
            onChange={(ids: number[]) => {
              setFilterValues({ ...filterValues, countryIds: ids });
            }}
          />
        </Select>
      </div>
    </div>
  );
};
