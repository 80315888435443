import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { profileImageReducer } from './profile-image/reducer';
import { favoriteLabelsReducer } from './favorite-labels/reducer';
import { allFavoriteProductssReducer, filterFavoriteProductssReducer } from './favorite-products/reducer';

export const rootReducer = combineReducers({
  profileImageReducer: profileImageReducer,
  favoriteLabelsReducer: favoriteLabelsReducer,
  filterFavoriteProductssReducer: filterFavoriteProductssReducer,
  allFavoriteProductssReducer: allFavoriteProductssReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const initialState: Partial<RootState> = {};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
});

export const typedDispatch = store.dispatch;
