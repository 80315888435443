import { useEffect, useState } from 'react';
import { Calendar, PeriodType } from '../../components/calendar/calendar';
import { bemCN } from '../../configs/bem-classname';
import { CalendarPeriod } from '../../view-models/calendar-period';
import './promotion-page.scss';
import { ReactComponent as FilterIcon } from '../../shared/image/svg/filter.svg';
import { ReactComponent as QuestionIcon } from '../../shared/image/svg/question.svg';
import {
  CampaignContainerDto,
  CampaignDto,
  CampaignFilterDto,
  ClickCampaignDto,
  CostCampaignDto,
  CpcCampaignDto,
  CtrCampaignDto,
  EfficiencyCampaignDto,
  OrderCampaignDto,
  RevenueCampaignDto,
  StatisticsCampaignDto,
  SumAtbsCampaignDto,
  SumOrderCampaignDto,
  ViewCampaignDto,
} from '../../api/Api';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { PromotionFilter } from '../../view-models/promotion-filter';
import { defaultPromotionCampaniesFilter } from '../../consts/default-filter-values';
import { Select, TypeSelectChild } from '../../components/select/select';
import { RadioGroup } from '../../components/radio-group/radio-group';
import { promotionService } from '../../services/promotion-service';
import { PromotionCabinet } from '../../view-models/promotion-cabinet';
import BigLoader from '../../components/big-loader/big-loader';
import { SelectItem } from '../../view-models/select-item';
import { PieChart } from '../../ui/pie-chart/pie-chart';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { SmallLoader } from '../../components/small-loader/small-loader';
import LineChart, { ColorLineChart, TypeLineChart } from '../../ui/line-chart/line-chart';
import { formatShortDate, toCalendarDate } from '../../utils/date';
import BarChart, { ColorsBarChart } from '../../ui/bar-chart/bar-chart';
import { colors } from '../../consts/colors';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { useTimer } from 'react-timer-hook';
import { TableLoad } from '../../components/table-load/table-load';
import { campaignsColumns } from '../../consts/campaignsColumns';
import { Table } from '../../ui/table/table';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { Pagination } from '../../components/pagination/pagination';
import { useTable } from '../../hooks/useTable';
import { BarPanel } from '../../components/bar-panel/bar-panel';
import { FilterBar } from '../../components/filter-bar/filter-bar';
import { CampaniesFilter } from '../../components/filters/campanies-filter/campanies-filter';
import { PromotionSteps } from '../../consts/promotion-steps';
import { PromotionSteps as PromotionStepsComponent } from '../../components/promotion-steps/promotion-steps';

const promotionPageCn = bemCN('promotion-page');

export const PromotionPage = () => {
  const [isShowSteps, setIsShowSteps] = useState<boolean>(
    localStorageService.getValue<PromotionSteps>(LocalStorageVariableName.promotionSteps) ==
      PromotionSteps.all
      ? false
      : true,
  );

  const [stats, setStats] = useState<StatisticsCampaignDto>();
  const [campaniesFilter, setCampaniesFilter] = useState<CampaignFilterDto>({
    ...defaultPromotionCampaniesFilter,
  });
  const [cabinets, setCabinets] = useState<PromotionCabinet[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAccessRequest, setIsAccessRequest] = useState(!isShowSteps);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60);
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([]);
  const [isLoadCampaigns, setIsLoadCampaigns] = useState(true);
  const [campaignCount, setCampaignCount] = useState(0);
  const [isShowFilterBar, setIsShowFilterBar] = useState(false);

  const { seconds, minutes, start, pause, restart } = useTimer({
    expiryTimestamp: expiryTimestamp,
    autoStart: false,
    onExpire: () => setIsAccessRequest(true),
  });

  //Получение кабинетов
  useEffect(() => {
    if (!isShowSteps) {
      let startFilterCampanies = getParams();
      promotionService.getCabinets().then((res: any[]) => {
        setCabinets(res);
        localStorageService.setValue(LocalStorageVariableName.promotionCabinetId, res[0].id);
        setCampaniesFilter({
          ...startFilterCampanies,
          cabinetId: startFilterCampanies.cabinetId || res[0].id,
        });
        setIsLoading(false);
      });
    }
  }, []);

  //Получение кампаний
  const getCampaigns = (f: CampaignFilterDto) => {
    setIsLoadCampaigns(true);
    promotionService.getCampaigns(f).then((res: CampaignContainerDto) => {
      setCampaigns(res.campaigns || []);
      setCampaignCount(res.count || 0);
      setIsLoadCampaigns(false);
    });
  };

  //Получение дашборда
  const getStats = (f: PromotionFilter) => {
    setStats(undefined);
    f.cabinetId &&
      promotionService.getStatistics(f).then((res: StatisticsCampaignDto) => {
        setStats(res);
        setIsAccessRequest(false);
        restart(expiryTimestamp);
      });
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, onChangeDate, pageNumber, setPageNumber } =
    useTable(
      campaignCount || 0,
      campaniesFilter,
      defaultPromotionCampaniesFilter,
      setCampaniesFilter,
      getCampaigns,
    );

  useEffect(() => {
    getStats({
      ...campaniesFilter,
      cabinetId: campaniesFilter.cabinetId,
      dateFrom: campaniesFilter.dateFrom,
      dateTo: campaniesFilter.dateTo,
    });
  }, [campaniesFilter.cabinetId, campaniesFilter.dateTo, campaniesFilter.dateFrom]);

  // useEffect(() => {
  //   if (isAccessRequest === true && !stats?.atbs) {
  //     getStats(campaniesFilter);
  //   }
  // }, [isAccessRequest]);

  //Сброс фильтров
  const onResetFilter = () => {
    saveParams({
      ...defaultPromotionCampaniesFilter,
      cabinetId: campaniesFilter.cabinetId,
      dateFrom: campaniesFilter.dateFrom,
      dateTo: campaniesFilter.dateTo,
    });
    setCampaniesFilter({
      ...defaultPromotionCampaniesFilter,
      cabinetId: campaniesFilter.cabinetId,
      dateFrom: campaniesFilter.dateFrom,
      dateTo: campaniesFilter.dateTo,
    });
    getCampaigns({
      ...defaultPromotionCampaniesFilter,
      cabinetId: campaniesFilter.cabinetId,
      dateFrom: campaniesFilter.dateFrom,
      dateTo: campaniesFilter.dateTo,
    });
    setIsShowFilterBar(false);
  };

  //Применить фильтры
  const onApplyFilter = () => {
    saveParams(campaniesFilter);
    getCampaigns(campaniesFilter);
    setIsShowFilterBar(false);
  };

  if (isShowSteps) return <PromotionStepsComponent />;
  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={promotionPageCn()}>
      <div className={promotionPageCn('header')}>
        <p className="h1">Статистика по рекламе</p>
        <div>
          <Select
            activeNames={
              cabinets && cabinets?.length > 0
                ? [
                    cabinets.find((c: PromotionCabinet) => c.id == campaniesFilter.cabinetId)
                      ?.name || '',
                  ]
                : []
            }
            typeSelectChild={TypeSelectChild.radio}
          >
            <RadioGroup
              onChange={(id: number) => {
                setCampaniesFilter({ ...campaniesFilter, cabinetId: id });
                localStorageService.setValue(LocalStorageVariableName.promotionCabinetId, id);
              }}
              activeId={campaniesFilter.cabinetId || -1}
              items={cabinets?.map((c: PromotionCabinet) => new SelectItem(c.id, c.name))}
            />
          </Select>
          <div
            data-title={
              'Получить данные можно 1 раз в минуту. Повторите попытку через ' +
              minutes +
              ':' +
              (seconds < 10 ? '0' + seconds.toString() : seconds.toString())
            }
            className={promotionPageCn('calendar-container', { isShow: !isAccessRequest })}
          >
            <Calendar
              isDisable={!isAccessRequest}
              changeValue={(v: CalendarPeriod) => {
                setCampaniesFilter({
                  ...campaniesFilter,
                  dateFrom: toCalendarDate(v.dateFrom),
                  dateTo: toCalendarDate(v.dateTo),
                });
              }}
              value={
                new CalendarPeriod(
                  new Date(campaniesFilter.dateFrom || lastMonthRange),
                  new Date(campaniesFilter.dateTo || yeasteday),
                )
              }
              maxDate={yeasteday}
              maxPeriod={PeriodType.month}
            />
          </div>
          <div
            className={promotionPageCn('help', { isRed: !isAccessRequest })}
            data-title={
              'Получить данные для дашборда можно 1 раз в минуту. Максимальный период между датами в календаре 30 дней'
            }
          >
            <Button
              icon={<QuestionIcon />}
              size={SizesButton.small}
              color={ColorsButton.transition}
            />
          </div>
        </div>
      </div>
      <div className={promotionPageCn('dashboard')}>
        <div className={promotionPageCn('chart-card')}>
          <p>Распределение заказов по кампаниям, шт.</p>
          {stats ? (
            <PieChart
              height={170}
              isLegend
              unit="шт."
              data={
                stats && stats.orders
                  ? stats.orders.map(
                      (v: OrderCampaignDto, index: number) =>
                        new ChartDataItem(
                          v.idCampaign || -1,
                          v.idCampaign?.toString() || '',
                          v.ordersCount || 0,
                          colors[index] || '#' + Math.floor(Math.random() * 16777215).toString(16),
                          // '#' + Math.floor(Math.random() * 16777215).toString(16),
                        ),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Распределение затрат по кампаниям, ₽</p>
          {stats ? (
            <PieChart
              height={170}
              isLegend
              unit="руб."
              data={
                stats && stats.costs
                  ? stats.costs.map(
                      (v: CostCampaignDto, index: number) =>
                        new ChartDataItem(
                          v.idCampaign || -1,
                          v.idCampaign?.toString() || '',
                          v.costs || 0,
                          colors[index] || '#' + Math.floor(Math.random() * 16777215).toString(16),
                        ),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Распределение выручки по кампаниям, ₽</p>
          {stats ? (
            <PieChart
              height={170}
              isLegend
              unit="руб."
              data={
                stats && stats.revenues
                  ? stats.revenues.map(
                      (v: RevenueCampaignDto, index: number) =>
                        new ChartDataItem(
                          v.idCampaign || -1,
                          v.idCampaign?.toString() || '',
                          v.revenue || 0,
                          colors[index] || '#' + Math.floor(Math.random() * 16777215).toString(16),
                        ),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-stat-row')}>
          <div>
            <p>CR</p>
            <p>{stats?.cr !== null ? stats?.cr : '-'}</p>
          </div>
          <div>
            <p>CTR</p>
            <p>{stats?.ctr !== null ? stats?.ctr : '-'}</p>
          </div>
          <div>
            <p>ROMI</p>
            <p>{stats?.roi !== null ? stats?.roi : '-'}</p>
          </div>
          <div>
            <p>ROAS</p>
            <p>{stats?.roas !== null ? stats?.roas : '-'}</p>
          </div>
          <div>
            <p>CPO</p>
            <p>{stats?.cpa !== null ? stats?.cpa : '-'}</p>
          </div>
          <div>
            <p>Выручка, ₽</p>
            <p>{stats?.revenue !== null ? stats?.revenue?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Чистая прибыль, ₽</p>
            <p>{stats?.profit !== null ? stats?.profit?.toLocaleString() : '-'}</p>
          </div>
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Общее количество кликов, шт.</p>
          {stats ? (
            <LineChart
              height={170}
              unit="шт."
              color={ColorLineChart.red}
              typeLineChart={TypeLineChart.line}
              data={
                stats && stats.clicks
                  ? stats.clicks.map(
                      (v: ClickCampaignDto, index: number) =>
                        new ChartDataItem(index, formatShortDate(v.date || ''), v.sumClicks || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Общее количество добавлений в корзину, шт.</p>
          {stats ? (
            <LineChart
              height={170}
              unit="шт."
              color={ColorLineChart.blue}
              typeLineChart={TypeLineChart.area}
              data={
                stats && stats.sumAtbs
                  ? stats.sumAtbs.map(
                      (v: SumAtbsCampaignDto, index: number) =>
                        new ChartDataItem(index, formatShortDate(v.date || ''), v.sumAtbs || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Общее количество заказов, шт.</p>
          {stats ? (
            <LineChart
              height={170}
              unit="шт."
              color={ColorLineChart.red}
              typeLineChart={TypeLineChart.area}
              data={
                stats && stats.sumOrders
                  ? stats.sumOrders.map(
                      (v: SumOrderCampaignDto, index: number) =>
                        new ChartDataItem(index, formatShortDate(v.date || ''), v.sumOrders || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-stat-row')}>
          <div>
            <p>Затраты на рекламу, ₽</p>
            <p>{stats?.cost !== null ? stats?.cost?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Добавления в корзину, шт.</p>
            <p>{stats?.atbs !== null ? stats?.atbs?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Заказы, шт.</p>
            <p>{stats?.order !== null ? stats?.order?.toLocaleString() : '-'}</p>
          </div>
          <div>
            <p>Количество просмотров, шт.</p>
            <p>{stats?.view !== null ? stats?.view?.toLocaleString() : '-'}</p>
          </div>
        </div>

        <div className={promotionPageCn('chart-card')}>
          <p>Общее количество просмотров, шт.</p>
          {stats ? (
            <LineChart
              height={170}
              unit="шт."
              color={ColorLineChart.green}
              typeLineChart={TypeLineChart.line}
              data={
                stats && stats.views
                  ? stats.views.map(
                      (v: ViewCampaignDto, index: number) =>
                        new ChartDataItem(index, formatShortDate(v.date || ''), v.sumViews || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Показатель кликабельности периода (CTR)</p>
          {stats ? (
            <BarChart
              height={170}
              unit=" "
              isNormalLabel
              color={ColorsBarChart.blue}
              data={
                stats && stats.ctrs
                  ? stats.ctrs.map(
                      (v: CtrCampaignDto, index: number) =>
                        new ChartDataItem(index, v.idCampaign?.toString() || '', v.ctr || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Средняя стоимость клика, ₽</p>
          {stats ? (
            <BarChart
              height={170}
              isNormalLabel
              unit="руб."
              color={ColorsBarChart.green}
              data={
                stats && stats.cpcs
                  ? stats.cpcs.map(
                      (v: CpcCampaignDto, index: number) =>
                        new ChartDataItem(index, v.idCampaign?.toString() || '', v.cpc || 0),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
        <div className={promotionPageCn('chart-card')}>
          <p>Эффективность работы кампаний, %</p>
          {stats ? (
            <BarChart
              height={170}
              isNormalLabel
              unit="%"
              color={ColorsBarChart.green}
              data={
                stats && stats.efficiency
                  ? stats.efficiency.map(
                      (v: EfficiencyCampaignDto, index: number) =>
                        new ChartDataItem(
                          index,
                          v.idCAmpaign?.toString() || '',
                          v.efficiency || 0,
                          v.efficiency && v.efficiency < 50
                            ? ColorsBarChart.red
                            : v.efficiency && v.efficiency > 50 && v.efficiency < 100
                            ? ColorsBarChart.orange
                            : ColorsBarChart.green,
                        ),
                    )
                  : []
              }
              errorText={'Не удалось загрузить данные'}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
      </div>
      <Button
        color={ColorsButton.white}
        size={SizesButton.big}
        text="Фильтры"
        icon={<FilterIcon />}
        onClick={() => {
          setIsShowFilterBar(!isShowFilterBar);
        }}
      />
      <div>
        {isLoadCampaigns ? (
          <TableLoad />
        ) : (
          <>
            <Pagination
              currentPageNumber={pageNumber}
              totalPagesCount={campaignCount ? Math.ceil(campaignCount / 50) : 1}
              onClick={onPagination}
            />
            <Table
              localStorageColumns={LocalStorageVariableName.promotionColumns}
              columns={campaignsColumns}
              data={campaigns}
              onSort={onSort}
              idActiveSort={campaniesFilter.sortFieldId || -1}
              decs={false}
            />
          </>
        )}
      </div>
      <BarPanel title="Фильтры" isShow={isShowFilterBar} setIsShow={setIsShowFilterBar}>
        <FilterBar onResetFilter={onResetFilter} onApplyFilter={onApplyFilter}>
          <CampaniesFilter filterValues={campaniesFilter} setFilterValues={setCampaniesFilter} />
        </FilterBar>
      </BarPanel>
    </div>
  );
};
