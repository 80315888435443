import { PhotoDto, ProfileDto } from '../api/Api';
import { API } from '../configs/api';

class ProfileService {
  async getPhoto() {
    try {
      let res = API.profile.getPhoto2Create();
      let result = (await res).data;
      return result as PhotoDto;
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }

  async getProfile() {
    try {
      let res = API.profile.getProfileCreate();
      let result = (await res).data;
      return result as ProfileDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async updateName(newName: string) {
    try {
      let res = API.profile.setNameCreate({ name: newName });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async updatePhone(newPhone: string) {
    try {
      let res = API.profile.setPhoneNumberCreate({ phoneNumber: newPhone });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async updateImage(newImage: string) {
    try {
      let res = API.profile.setPhoto2Create({ base64: newImage });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    try {
      let res = API.profile.chanagePasswordCreate({ oldPassword, newPassword });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return 'Не удалось изменить пароль!';
    }
  }
}

export const profileService: ProfileService = new ProfileService();
