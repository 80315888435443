import { bemCN } from '../../configs/bem-classname';
import './error-developmant-page.scss';
import {ReactComponent as DevelopmentIcon} from '../../shared/image/svg/development.svg';

const errorDevelopmentPageCn = bemCN('error-developmant-page');

export const ErrorDevelopmentPage = () => {
  return (
    <div className={errorDevelopmentPageCn()}>
        <DevelopmentIcon/>
      <p>Cтаница находится в процессе рефакторинга</p>
    </div>
  );
};
