import { bemCN } from '../../configs/bem-classname';
import './button.scss';

import { FC, ReactNode } from 'react';

export enum SizesButton {
  small,
  big,
}

export enum ColorsButton {
  transition,
  green,
  violet,
  white,
}

type ButtonProps = {
  id?: string;
  text?: string;
  icon?: ReactNode;
  color: ColorsButton;
  size: SizesButton;
  noDisabled?:boolean;
  onClick?: () => void;
};

export const Button: FC<ButtonProps> = ({ id, text, icon, color, size, noDisabled, onClick }) => {
  const buttonCN = bemCN('button');

  let sizeClass = SizesButton[size];
  let colorClass = ColorsButton[color];

return <button id={id} onClick={() =>onClick && !noDisabled && onClick()} className={buttonCN({ size: sizeClass, color: colorClass, disable:noDisabled })}>{icon}{text}</button>;
};
