import { useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import { Input, TypeDataInput, TypeViewInput } from '../../ui/input/input';
import './recovery-password-page.scss';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { emailHasError, passwordHasError } from '../../utils/validation-input';
import { useTimer } from 'react-timer-hook';
import { authService } from '../../services/auth-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as CheckMarkGreenIcon } from '../../shared/image/svg/check-mark-green.svg';
import { Modal, ColorsModal } from '../../ui/modal/modal';
import { linksUrl } from '../../consts/linksUrl';

const recoveryPasswordCn = bemCN('recovery-password-page');

export const RecoveryPasswordPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('key');

  //Отправка почты
  const [email, setEmail] = useState('');
  const [isActiveButton, setIsActiveButton] = useState(false);
  const [isActiveDoubleSend, setIsActiveDoubleSend] = useState(false);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 300);
  const { seconds, minutes, start, pause, restart } = useTimer({
    expiryTimestamp: expiryTimestamp,
    autoStart: false,
    onExpire: () => setIsActiveDoubleSend(true),
  });

  const onSendRecovery = () => {
    setIsActiveButton(false);
    setIsActiveDoubleSend(false);
    restart(expiryTimestamp);
    authService.recoveryPasswordRequest(email).then(() => {});
  };

  useEffect(() => {
    if (!emailHasError(email)) {
      setIsActiveButton(true);
    } else {
      setIsActiveButton(false);
    }
  }, [email]);

  //Обновление пароля
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [isActiveSavePasswordButton, setIsActiveSavePasswordButton] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const savePassword = () => {
    setIsActiveSavePasswordButton(false);
    key &&
      authService.updatePassword(key, password).then((res: number) => {
        if (res === 200) {
          setIsShowSuccessModal(true);
          
        } else {
          setErrorText('Не удалось изменить пароль, так как ключ из письма устарел. {апросите письмо на смену пароля снова.');
        }
      });
  };

  useEffect(() => {
    if (password === password1 && !passwordHasError(password)) {
      setErrorText('');
      setIsActiveSavePasswordButton(true);
    } else {
      setErrorText('Пароли не совпадают!');
      setIsActiveSavePasswordButton(false);
    }
  }, [password, password1]);

  return (
    <div className={recoveryPasswordCn()}>
      <div>
        <h1>Восстановление пароля</h1>

        {!key ? (
          <>
            <p>
              На указанный Вами адрес электронной почты будет отправлено письмо с дальнейшими
              инструкциями по изменению пароля
            </p>
            <div className={recoveryPasswordCn('input')}>
              <p>Адрес электронной почты</p>
              <Input
                value={email}
                placeholder="Почта"
                typeView={TypeViewInput.dark}
                typeData={TypeDataInput.email}
                onChange={(v: string) => {
                  setEmail(v);
                }}
                isValidate
              />
            </div>
            <Button
              onClick={onSendRecovery}
              noDisabled={!isActiveButton}
              text="Восстановить пароль"
              color={ColorsButton.green}
              size={SizesButton.big}
            />
            <div className={recoveryPasswordCn('double-send')}>
              <span>
                {minutes}:{seconds < 10 ? '0' + seconds.toString() : seconds.toString()}
              </span>
              <a
                onClick={() => {
                  isActiveDoubleSend ? onSendRecovery() : {};
                }}
                className={isActiveDoubleSend ? 'active' : ''}
              >
                Отправить повторно
              </a>
            </div>
          </>
        ) : (
          <>
            <div className={recoveryPasswordCn('input')}>
              <p>Новый пароль</p>
              <Input
                value={password}
                placeholder="Новый пароль"
                typeView={TypeViewInput.dark}
                typeData={TypeDataInput.password}
                onChange={(v: string) => {
                  setPassword(v);
                }}
                isValidate
              />
            </div>
            <div className={recoveryPasswordCn('input')}>
              <p>Повторите пароль</p>
              <Input
                value={password1}
                placeholder="Новый пароль"
                typeView={TypeViewInput.dark}
                typeData={TypeDataInput.password}
                onChange={(v: string) => {
                  setPassword1(v);
                }}
                isValidate
              />
            </div>
            {errorText && <p className={recoveryPasswordCn('error')}>{errorText}</p>}
            <Button
              onClick={savePassword}
              noDisabled={!isActiveSavePasswordButton}
              text="Сохранить новый пароль"
              color={ColorsButton.green}
              size={SizesButton.big}
            />
          </>
        )}
      </div>
      <Modal
        isShowDialog={isShowSuccessModal}
        setIsShowDialog={setIsShowSuccessModal}
        title={''}
        color={ColorsModal.dark}
      >
        <div className={recoveryPasswordCn('modal')}>
          <CheckMarkGreenIcon />
          <p>Пароль успешно изменен!</p>
          <p>Теперь Вы можете авторизоваться с новым паролем.</p>
          <Button
            onClick={() => navigate(linksUrl.login)}
            color={ColorsButton.green}
            size={SizesButton.big}
            text="К сервису"
          />
        </div>
      </Modal>
    </div>
  );
};
