export class Warehouse {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class WarehouseStock {
  id: number;
  name: string;
  stoksCount: number;
  renevue: number;
  salesCount: number;

  constructor(id: number, name: string, stoksCount: number, renevue: number, salesCount: number) {
    this.id = id;
    this.name = name;
    this.stoksCount = stoksCount;
    this.renevue = renevue;
    this.salesCount = salesCount;
  }
}

export class Stock {
  date: string;
  stoksCount: number;
  renevue: number;
  salesCount: number;
  stocks: WarehouseStock[];

  constructor(
    date: string,
    stoksCount: number,
    renevue: number,
    salesCount: number,
    stocks: WarehouseStock[],
  ) {
    this.date = date;
    this.stoksCount = stoksCount;
    this.renevue = renevue;
    this.salesCount = salesCount;
    this.stocks = stocks;
  }
}
