import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as ArrowBackIcon } from '../../shared/image/svg/arrow-back.svg';
import { ReactComponent as StarIcon } from '../../shared/image/svg/star.svg';
import { ReactComponent as NotFoundIcon } from '../../shared/image/svg/404.svg';
import { ReactComponent as WBLogoIcon } from '../../shared/image/svg/wb-logo.svg';
import { ReactComponent as LinkIcon } from '../../shared/image/svg/link.svg';
import { ReactComponent as FavoriteIcon } from '../../shared/image/svg/favorite.svg';
import './product-page.scss';
import { useEffect, useState } from 'react';
import BigLoader from '../../components/big-loader/big-loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatFullDate, toCalendarDate } from '../../utils/date';
import { linksUrl } from '../../consts/linksUrl';
import { Calendar } from '../../components/calendar/calendar';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { productsService } from '../../services/products-service';
import {
  ContainerStockByDateDto,
  PathDto,
  ProductCardDto,
  ProductCardStatisticsDto,
  ProductCardStatisticsFilterDto,
  SalesStatisticsDto,
  StockByDateDto,
  WarehouseDto,
  WarehouseStockDto,
} from '../../api/Api';
import LineChart, { ColorLineChart, TypeLineChart } from '../../ui/line-chart/line-chart';
import { LineChartPropName } from '../../view-models/product-analitics-line-chart-item';
import DinamicLineChart from '../../ui/dinamic-line-chart/dinamic-line-chart';
import BarChart, { ColorsBarChart } from '../../ui/bar-chart/bar-chart';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { WarehouseTable } from '../../components/warehouse-table/warehouse-table';
import { favoriteService } from '../../services/favorite-service';
import { defaultProductCardStatisticsFilterDto } from '../../consts/default-filter-values';
import { SmallLoader } from '../../components/small-loader/small-loader';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { Stock, Warehouse, WarehouseStock } from '../../view-models/warehouse';
import { useTable } from '../../hooks/useTable';

const productPageCn = bemCN('product-page');

export const ProductPage = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductCardDto>();
  const [isFavorite, setIsFavorite] = useState(false);
  const [stats, setStats] = useState<ProductCardStatisticsDto>();
  const [filter, setFilter] = useState<ProductCardStatisticsFilterDto>({
    ...defaultProductCardStatisticsFilterDto,
    id: Number(id),
  });
  const [isEmptyProduct, setIsEmptyProduct] = useState(false);
  const [backCount, setBackCount] = useState(0);

  const [statSizeWirehouse, setStatSizeWirehouse] = useState<SalesStatisticsDto>();
  const [stocks, setStocks] = useState<ContainerStockByDateDto>();

  useEffect(() => {
    let startFilter = getParams();
    productsService.getProduct(Number(id)).then((res: ProductCardDto) => {
      if (Object.keys(res).length === 0) {
        setIsEmptyProduct(true);
        setIsLoading(false);
        return;
      }
      setProduct(res);
      setIsFavorite(res.isFavorite || false);
      setIsLoading(false);
    });
    // getStats(filter);
    getDataForCalendar({ ...startFilter, id: Number(id) });
  }, [1]);

  const getDataForCalendar = (filter: ProductCardStatisticsFilterDto) => {
    setStats(undefined);
    getStats(filter);
    getStatSizeWirehouse(filter);
    getStocks(filter);
    setBackCount(backCount - 1);
  };

  const getStats = (filter: ProductCardStatisticsFilterDto) => {
    productsService.getProductStats(filter).then((res: ProductCardStatisticsDto) => {
      setStats(res);
    });
  };

  const getStatSizeWirehouse = (filter: ProductCardStatisticsFilterDto) => {
    productsService.getStatSizeWirehouse(filter).then((res: SalesStatisticsDto) => {
      setStatSizeWirehouse(res);
    });
  };

  const getStocks = (filter: ProductCardStatisticsFilterDto) => {
    productsService.getStocksCard(filter).then((res: ContainerStockByDateDto) => {
      setStocks(res);
    });
  };
  const onClickFavorite = () => {
    let newIsFavorite = !isFavorite;
    if (newIsFavorite) {
      favoriteService.addProduct(Number(product?.articul) || -1).then((res: boolean) => {
        if (res) {
          setIsFavorite(true);
        }
      });
    } else {
      favoriteService.deleteProduct(Number(product?.articul) || -1).then((res: boolean) => {
        if (res) {
          setIsFavorite(false);
        }
      });
    }
  };

  //Подключение инструментов для работы с таблицей
  const { getParams, saveParams, onChangeDate } = useTable(
    0,
    { ...filter, id: Number(id) },
    defaultProductCardStatisticsFilterDto,
    setFilter,
    getDataForCalendar,
  );

  if (isLoading) return <BigLoader color="dark" />;
  if (isEmptyProduct)
    return (
      <div className={productPageCn('empty')}>
        <NotFoundIcon />
        <p>Товар не найден.</p>
        <p>Запрашиваемый артикул не найден в системе.</p>
        <Button
          onClick={() => navigate(linksUrl.analyitcs + linksUrl.analyticsProduct)}
          color={ColorsButton.white}
          text="На главную"
          size={SizesButton.big}
        />
      </div>
    );
  return (
    <div className={productPageCn()}>
      <div>
        <Button
          onClick={() => navigate(backCount)}
          color={ColorsButton.transition}
          size={SizesButton.small}
          icon={<ArrowBackIcon />}
        />
        <div className={productPageCn('product-info')}>
          <img src={product?.imageUrl || ''} />
          <div>
            {' '}
            <div>
              <p className={productPageCn('name')}>
                {product?.name || 'Не удалось получить название товара'}
              </p>
              <div className={productPageCn('first-row')}>
                <p className={productPageCn('rating')}>
                  <StarIcon />
                  <span>{product?.raiting?.toFixed(1)}</span>{' '}
                </p>
                <p className={productPageCn('feetback')}>
                  {product?.feedBackCount?.toLocaleString('RU-ru')} отзыв
                </p>
                <p className={productPageCn('articul')}>
                  <WBLogoIcon />
                  <span>Артикул: {product?.articul}</span>
                </p>
                <p className={productPageCn('date-create')}>
                  Появление в сервисе:<span>{formatFullDate(product?.dateCreate || '')}</span>
                </p>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>Категории</td>
                    <td>
                      {product?.paths ? (
                        product.paths.map((path: PathDto[], i: number) => (
                          <p key={i}>
                            {path.map((p: PathDto, index: number) => (
                              <Link
                                className={productPageCn('category-link')}
                                key={p.id}
                                to={
                                  linksUrl.subcategory +
                                  (p.isSubject
                                    ? '?subCategoriesIds=' +
                                      p.id +
                                      '&themeCategories=' +
                                      path[index - 1].id
                                    : '?themeCategories=' + p.id)
                                }
                              >
                                {p.name}
                                {index < path.length - 1 && <span> / </span>}
                              </Link>
                            ))}
                          </p>
                        ))
                      ) : (
                        <Link
                          className={productPageCn('category-link')}
                          to={linksUrl.subcategory + '?themeCategories=' + product?.subCategoryId}
                        >
                          {product?.category}
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Предмет</td>
                    <td>
                      <Link
                        to={linksUrl.subcategory + '?themeCategories=' + product?.subCategoryId}
                      >
                        {product?.category}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Бренд</td>
                    <td>
                      {product?.brandId ? (
                        <Link to={linksUrl.brand + '/' + product?.brandId}>
                          {product?.brandName || ' - '}
                        </Link>
                      ) : (
                        <p>{product?.brandName || ' - '}</p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Продавец</td>
                    <td>
                      {product?.sellerId ? (
                        <Link to={linksUrl.seller + '/' + product?.sellerId}>
                          {product?.salerName || ' - '}
                        </Link>
                      ) : (
                        <p>{product?.salerName || ' - '}</p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Цена / Цена со скидкой</td>
                    <td>
                      {product?.priceTotal?.toLocaleString('Ru-ru')} ₽ /
                      {product?.price?.toLocaleString('RU-ru')} ₽
                    </td>
                  </tr>
                  <tr>
                    <td>Скидка</td>
                    <td>{product?.discount} %</td>
                  </tr>
                  <tr>
                    <td>Выкуп</td>
                    <td>{product?.buyout} %</td>
                  </tr>
                  <tr>
                    <td>Комиссия МП</td>
                    <td>{product?.commision} %</td>
                  </tr>
                  {/* <tr>
                  <td>Популярные размеры</td>
                  <td style={{ color: 'red' }}>Нет API</td>
                </tr>
                <tr>
                  <td>Популярные склады</td>
                  <td style={{ color: 'red' }}>Нет API</td>
                </tr> */}
                  {/* <tr>
                  <td>Количество цветов</td>
                  <td>3</td>
                </tr> */}
                </tbody>
              </table>
            </div>
            <div className={productPageCn('header-buttons')}>
              <Button
                onClick={() => {
                  window.open(
                    'https://www.wildberries.ru/catalog/' + product?.articul + '/detail.aspx',
                    '_blank',
                  );
                }}
                color={ColorsButton.white}
                size={SizesButton.small}
                icon={<LinkIcon />}
              />
              <div className={productPageCn('favorite-btn', { isFavorite: isFavorite })}>
                <Button
                  onClick={onClickFavorite}
                  color={ColorsButton.white}
                  size={SizesButton.small}
                  icon={<FavoriteIcon />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Calendar
          value={
            new CalendarPeriod(
              new Date(filter.dateFrom || lastMonthRange),
              new Date(filter.dateTo || yeasteday),
            )
          }
          minDate={product && product.dateCreate ? new Date(product.dateCreate) : undefined}
          maxDate={yeasteday}
          changeValue={onChangeDate}
        />
      </div>
      <div className={productPageCn('stat-panel')}>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.revenue) || stats?.revenue === 0 ? (
              stats.revenue.toLocaleString('ru-RU') + ' руб.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Выручка</p>
        </div>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.sales) || stats?.sales === 0 ? (
              stats.sales.toLocaleString('ru-RU') + ' шт.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Продажи</p>
        </div>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.outOfStokcs) || stats?.outOfStokcs === 0 ? (
              stats.outOfStokcs.toLocaleString('ru-RU') + ' дн.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Отсутствовал в наличии</p>
        </div>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.revenuePerDay) || stats?.revenuePerDay === 0 ? (
              stats.revenuePerDay.toLocaleString('ru-RU') + ' руб.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Выручка в день</p>
        </div>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.salesPerDay) || stats?.salesPerDay === 0 ? (
              stats.salesPerDay.toLocaleString('ru-RU') + ' шт.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Продажи в день</p>
        </div>
        <div className={productPageCn('stat-item')}>
          <p>
            {(stats && stats.loseRevenue) || stats?.loseRevenue === 0 ? (
              stats.loseRevenue.toLocaleString('ru-RU') + ' руб.'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Упущенная выручка</p>
        </div>
      </div>
      <div className={productPageCn('line-chart')}>
        {stats ? (
          <DinamicLineChart
            title="Анализ сезонности товаров"
            propNames={[
              new LineChartPropName(1, 'Остатки', 'stock', 'шт'),
              new LineChartPropName(2, 'Цены', 'price', 'руб'),
              new LineChartPropName(3, 'Выручка', 'revenue', 'руб'),
              new LineChartPropName(4, 'Продажи', 'sales', 'шт'),
            ]}
            basePropName="date"
            basePropLabel="Дата"
            data={stats?.stocks}
          />
        ) : (
          <SmallLoader />
        )}
      </div>
      <div className={productPageCn('bar-charts')}>
        {/* <div>
          <p>Распределение выручки по цветам</p>
          <BarChart
            height={150}
            isNormalLabel
            isShortLable
            color={ColorsBarChart.colorful}
            data={colorBarChartMock.map(
              (item: any) => new ChartDataItem(item.id, item.name, item.value),
            )}
          />
        </div> */}
        <div>
          <p>Распределение выручки по размерам</p>
          {/* <BarChart
            height={160}
            isNormalLabel
            color={ColorsBarChart.colorful}
            unit={
              statSizeWirehouse &&
              statSizeWirehouse.salesBySize &&
              statSizeWirehouse.salesBySize[0] &&
              statSizeWirehouse.salesBySize[0].revenue &&
              statSizeWirehouse.salesBySize[0].revenue.toString().length > 5
                ? 'тыс. руб.'
                : 'руб.'
            }
            labelY={
              statSizeWirehouse &&
              statSizeWirehouse.salesBySize &&
              statSizeWirehouse.salesBySize[0] &&
              statSizeWirehouse.salesBySize[0].revenue &&
              statSizeWirehouse.salesBySize[0].revenue.toString().length > 5
                ? 'Выручка, тыс. руб.'
                : 'Выручка, руб.'
            }
            data={
              statSizeWirehouse && statSizeWirehouse?.salesBySize
                ? statSizeWirehouse?.salesBySize.map(
                    (item: any, index: number) =>
                      new ChartDataItem(
                        index,
                        item.name,
                        item.revenue.toString().length > 5
                          ? item.revenue / 1000
                          : item.revenue,
                      ),
                  )
                : []
            }
          /> */}
          <BarChart
            height={160}
            isNormalLabel
            color={ColorsBarChart.colorful}
            unit={'тыс. руб.'}
            labelY={'Выручка, тыс. руб.'}
            data={
              statSizeWirehouse && statSizeWirehouse?.salesBySize
                ? statSizeWirehouse?.salesBySize.map(
                    (item: any, index: number) =>
                      new ChartDataItem(index, item.name, item.revenue / 1000),
                  )
                : []
            }
          />
        </div>
        <div>
          <p>Распределение выручки по складам</p>
          {/* <BarChart
            height={160}
            isNormalLabel
            color={ColorsBarChart.colorful}
            unit={
              statSizeWirehouse &&
              statSizeWirehouse.salesByWarehouse &&
              statSizeWirehouse.salesByWarehouse[0] &&
              statSizeWirehouse.salesByWarehouse[0].revenue &&
              statSizeWirehouse.salesByWarehouse[0].revenue.toString().length > 5
                ? 'тыс. руб.'
                : 'руб.'
            }
            labelY={
              statSizeWirehouse &&
              statSizeWirehouse.salesByWarehouse &&
              statSizeWirehouse.salesByWarehouse[0] &&
              statSizeWirehouse.salesByWarehouse[0].revenue &&
              statSizeWirehouse.salesByWarehouse[0].revenue.toString().length > 5
                ? 'Выручка, тыс. руб.'
                : 'Выручка, руб.'
            }
            data={
              statSizeWirehouse && statSizeWirehouse?.salesByWarehouse
                ? statSizeWirehouse?.salesByWarehouse.map(
                    (item: any, index: number) =>
                      new ChartDataItem(
                        index,
                        item.warehouseName,
                        item.revenue.toString().length > 5 ? item.revenue / 1000 : item.revenue,
                      ),
                  )
                : []
            }
          /> */}
          <BarChart
            height={160}
            isNormalLabel
            color={ColorsBarChart.colorful}
            unit={'тыс. руб.'}
            labelY={'Выручка, тыс. руб.'}
            data={
              statSizeWirehouse && statSizeWirehouse?.salesByWarehouse
                ? statSizeWirehouse?.salesByWarehouse.map(
                    (item: any, index: number) =>
                      new ChartDataItem(index, item.warehouseName, item.revenue / 1000),
                  )
                : []
            }
          />
        </div>
      </div>
      <div className={productPageCn('stocks')}>
        {stocks && stocks.stocks && (
          <WarehouseTable
            warehouses={
              stocks && stocks.warehouses
                ? stocks.warehouses.map(
                    (w: WarehouseDto, i: number) => new Warehouse(w.id || i, w.name || ''),
                  )
                : []
            }
            warehousesStock={
              (stocks &&
                stocks.stocks &&
                stocks.stocks.map(
                  (s: StockByDateDto, index: number) =>
                    new Stock(
                      formatFullDate(s.date || '') || '',
                      s.stockCount || s.stockCount == 0 ? s.stockCount : -1,
                      s.revenue || s.revenue == 0 ? s.revenue : -1,
                      s.salesCount || s.salesCount == 0 ? s.salesCount : -1,
                      s.stocks?.map(
                        (st: WarehouseStockDto, i: number) =>
                          new WarehouseStock(
                            st.id || i,
                            stocks.warehouses?.find((w: WarehouseDto) => w.id === st.id)?.name ||
                              '',
                            st.stoksCount || st.stoksCount === 0 ? st.stoksCount : -1,
                            st.revenue || st.revenue === 0 ? st.revenue : -1,
                            st.salesCount || st.salesCount === 0 ? st.salesCount : -1,
                          ),
                      ) || [],
                    ),
                )) ||
              []
            }
          />
        )}
      </div>
    </div>
  );
};
