import { bemCN } from '../../configs/bem-classname';
import './popup.scss';

import { FC, ReactNode, useEffect, useRef } from 'react';

type PopupProps = {
  id?: string;
  children?: ReactNode;
  isShow: boolean;
  setIsShow(isShow: boolean): void;
};

const Popup: FC<PopupProps> = ({ id, children, isShow, setIsShow }) => {
  const popupCN = bemCN('popup');
  const popupRef = useRef<any>();
  const handleClick = (e: any) => {

    if (
      popupRef.current &&
      !popupRef.current.contains(e.target) &&
      e.target.parentElement != null &&
      !Array.from(e.target.parentElement.querySelectorAll(':scope > *')).includes(popupRef.current)
    ) {
      setIsShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [isShow]);

  return isShow ? (
    <div ref={popupRef} className={popupCN()}>
      {children}
    </div>
  ) : null;
};

export default Popup;
