import { FC, useState } from 'react';
import { RangeInputs, TypeRangeValue } from '../../range-inputs/range-inputs';
import { bemCN } from '../../../configs/bem-classname';
import './brands-filter.scss';
import { Checkbox } from '../../../ui/checkbox/checkbox';
import { Input, TypeDataInput, TypeViewInput } from '../../../ui/input/input';
import { BrandFilterDto } from '../../../api/Api';

export interface BrandsFilterProps {
  filterValues: BrandFilterDto;
  setFilterValues: (newF: BrandFilterDto) => void;
}
const brandsFilterBarCn = bemCN('brands-filter');
export const BrandFilter: FC<BrandsFilterProps> = ({ filterValues, setFilterValues }) => {
  const [isCheck, setIsCheck] = useState(true);

  const onChangeRangeValue = (typeRangeValue: TypeRangeValue, value: number, name: string) => {
    if (typeRangeValue === TypeRangeValue.from) {
      setFilterValues({ ...filterValues, [`${name}From`]: Number(value) });
    } else if (typeRangeValue === TypeRangeValue.to) {
      if (Number(value) === 0) setFilterValues({ ...filterValues, [`${name}To`]: undefined });
      else setFilterValues({ ...filterValues, [`${name}To`]: Number(value) });
    }
  };



  return (
    <div className={brandsFilterBarCn()}>
      <div className={brandsFilterBarCn('isActivePanel')}>
        <span>Название</span>
        <div>
          <Checkbox isCheck={isCheck} onClick={() => setIsCheck(!isCheck)} />
          <span>Активный</span>
        </div>
      </div>
      <Input
        typeData={TypeDataInput.text}
        typeView={TypeViewInput.normal}
        value={filterValues.name || ''}
        placeholder="Название"
        onChange={(v: string) => setFilterValues({ ...filterValues, name: v })}
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'countSale');
        }}
        toValue={filterValues.countSaleTo || undefined}
        fromValue={filterValues.countSaleFrom || undefined}
        min={1}
        title="Количество продаж, шт."
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'countSaler');
        }}
        toValue={filterValues.countSalerTo || undefined}
        fromValue={filterValues.countSalerFrom || undefined}
        min={1}
        title="Количество продавцов, шт."
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'countProduct');
        }}
        toValue={filterValues.countProductTo || undefined}
        fromValue={filterValues.countProductFrom || undefined}
        min={1}
        title="Количество товаров, шт."
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'raiting');
        }}
        toValue={filterValues.raitingTo || undefined}
        fromValue={filterValues.raitingFrom || undefined}
        min={1}
        max={5}
        title="Рейтинг"
      />
    </div>
  );
};
