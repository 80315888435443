import './modal.scss';
import { FC, ReactNode, useEffect } from 'react';
import { ReactComponent as Cross } from '../../shared/image/svg/cross.svg';
import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../button/button';

type ModalProps = {
  children: ReactNode;
  title: string;
  color: ColorsModal;
  isShowDialog: boolean;
  setIsShowDialog(isShow: boolean): void;
};

export enum ColorsModal {
  light = 1,
  dark,
}
const modalCN = bemCN('modal');

export const Modal: FC<ModalProps> = ({
  children,
  title,
  color,
  setIsShowDialog,
  isShowDialog,
}) => {
  let dialog: HTMLDialogElement | any;
  let colorClass = ColorsModal[color];

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsShowDialog(false);
      }
    });
    dialog = document.getElementById('modalDialog');
    isShowDialog ? dialog?.showModal() : dialog?.close();
  }, [isShowDialog]);

  return isShowDialog ? (
    <dialog id="modalDialog" className={modalCN({ color: colorClass })}>
      <div className={modalCN('header')}>
        <p>{title}</p>
        <Button
          onClick={() => {
            setIsShowDialog(false);
          }}
          size={SizesButton.small}
          color={ColorsButton.transition}
          icon={<Cross />}
        />
      </div>
      <div className={modalCN('content')}>{children}</div>
    </dialog>
  ) : null;
};
