import {
  BrandDto,
  BrandFilterDownloadingDto,
  BrandFilterDto,
  FilterFieldDto,
  FilterNavigationDto,
  ProductFilterDateDto,
  SellerFilterDto as SellerFilterDtoApi,
  SellerFilterStatisticsDto,
  SellerStatisticsDto,
} from '../api/Api';
import { API } from '../configs/api';
import { linkColumnsName } from '../consts/link-column-names';
import { saveFile } from '../utils/saveFile';
import { SellerFilterDto } from '../view-models/seller-filter-dto';
import { TableColumn, TypeColumn } from '../view-models/table-column';
import { TypeFile } from '../view-models/type-files';

class BrandsService {
  async getColumns() {
    try {
      let res = API.brand.getFieldsCreate();
      let result = (await res).data;
      let columns: TableColumn[] = result.map((f: FilterFieldDto) => {
        return new TableColumn(
          f.id || -1,
          f.siteFieldName || '',
          f.name || '',
          TypeColumn.string,
          f.defaultActive || false,
          undefined,
          f.isSortable,
          linkColumnsName.includes(f.siteFieldName || ''),
          f.sort,
        );
      });
      return columns || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getBrands(filter: BrandFilterDto) {
    try {
      // let apiFilter = this.getApiFilter(filter);
      let res = API.brand.getBrandsCreate({ ...filter, sourceLink: window.location.href });
      let result = (await res).data;
      return result || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getBrand(id: number) {
    try {
      let res = API.brandCard.getBrandCreate({ id });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getBrandsStat(filter: BrandFilterDto) {
    try {
      let statFilter = { ...filter, limit: 6, sortFieldId: 6, desc: true };
      let res = API.brand.getBrandsCreate(statFilter);
      let result = (await res).data;
      return result as BrandDto[];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getBrandStat(filter: ProductFilterDateDto) {
    try {
      let res = API.brandCard.getBrandStatisticsFilterCreate(filter);
      let result = (await res).data;
      return result as BrandDto[];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getBrandsCount(filter: BrandFilterDto) {
    try {
      let res = API.brand.countResultsCreate(filter);
      let result = (await res).data;
      return result as number;
    } catch (err: any) {
      console.log(err);
      return -1;
    }
  }

  async startExportFile(filter: BrandFilterDto, typeFile: TypeFile, fieldsIds: number[]) {
    try {
      let fileFilter: BrandFilterDownloadingDto = {
        typeFile: Number(typeFile),
        fieldsIds: fieldsIds,
        ...filter,
      };
      let res = API.brand.createDownloadFileCreate(fileFilter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return -1;
    }

  }

  async getFile(idFile: string, fileType: TypeFile) {
    try {
      let res = API.brand.getFileByIdList({ id: idFile });
      let result = await (await res).blob();
      saveFile(result, TypeFile[fileType]);
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }
}

export const brandService: BrandsService = new BrandsService();
