import { FC  } from 'react';
import { bemCN } from '../../../configs/bem-classname';
import { CheckGroup, CheckMarkPosition } from '../../check-group/check-group';
import { SelectItem } from '../../../view-models/select-item';
import { Select, TypeSelectChild } from '../../select/select';
import './campanies-filter.scss';
import { Input, TypeDataInput, TypeViewInput } from '../../../ui/input/input';
import {  CampaignFilterDto } from '../../../api/Api';
import { statusesCampanies, typesCampanies } from '../../../consts/campanies-params-value';

export interface CampaniesFilterProps {
  filterValues: CampaignFilterDto;
  setFilterValues: (newF: CampaignFilterDto) => void;
}
const campaniesFilterBarCn = bemCN('campanies-filter');
export const CampaniesFilter: FC<CampaniesFilterProps> = ({ filterValues, setFilterValues }) => {
 

  return (
    <div className={campaniesFilterBarCn()}>
      <Input
        typeData={TypeDataInput.text}
        typeView={TypeViewInput.normal}
        value={filterValues.name || ''}
        placeholder="Название или ID кампании"
        onChange={(v: string) => setFilterValues({ ...filterValues, name: v })}
      />

      <div className={campaniesFilterBarCn('selected')}>
        <span>Тип</span>
        <Select
          typeSelectChild={TypeSelectChild.check}
          activeNames={
            (filterValues &&
              filterValues.typeIds &&
              typesCampanies
                .filter((i: any) => filterValues.typeIds?.includes(i.id))
                .map((item: any) => item.name)) ||
            []
          }
        >
          <CheckGroup
            items={typesCampanies.map((i: any) => new SelectItem(i.id, i.name))}
            checkMarkPosition={CheckMarkPosition.left}
            activeIds={filterValues.typeIds || []}
            onChange={(ids: number[]) => {
              setFilterValues({ ...filterValues, typeIds: ids });
            }}
          />
        </Select>
      </div>
      <div className={campaniesFilterBarCn('selected')}>
        <span>Статус</span>
        <Select
          typeSelectChild={TypeSelectChild.check}
          activeNames={
            (filterValues &&
              filterValues.statusIds &&
              statusesCampanies
                .filter((i: any) => filterValues.statusIds?.includes(i.id))
                .map((item: any) => item.name)) ||
            []
          }
        >
          <CheckGroup
            items={statusesCampanies.map((i: any) => new SelectItem(i.id, i.name))}
            checkMarkPosition={CheckMarkPosition.left}
            activeIds={filterValues.statusIds || []}
            onChange={(ids: number[]) => {
              setFilterValues({ ...filterValues, statusIds: ids });
            }}
          />
        </Select>
      </div>
    </div>
  );
};
