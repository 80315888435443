import { PeriodType } from '../components/calendar/calendar';

export function formatFullDate(dateTime: string | Date): string {
  if (!dateTime) {
    return '';
  }
  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${days}.${month}.${year}`;
}

export function formatShortDate(dateTime: string | Date): string {
  if (!dateTime) {
    return '';
  }
  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  // const year = dateObj.getFullYear();

  return `${days}.${month}`;
}

export function getDateTime(dateTime: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  if (!dateTime || dateTime == '') {
    return '';
  }
  let newDateTime: string = new Intl.DateTimeFormat('ru-RU', options).format(new Date(dateTime));
  return newDateTime;
}

export function toCalendarDate(dateTime?: string | Date): string | undefined {
  if (!dateTime) {
    return undefined;
  }

  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${year}-${month}-${days}`;
}

export function getLast(dateTime: string | Date, periodType: PeriodType): Date {
  if (!dateTime || dateTime == '') {
    return new Date();
  }
  let last = new Date(dateTime);

  let newDay: Date = last;

  switch (periodType) {
    case PeriodType.month: {
      newDay = new Date(last.setDate(last.getDate() - 30));
      break;
    }
    case PeriodType.year: {
      newDay = new Date(last.setDate(last.getDate() - 365));
      break;
    }
  }
  return newDay;
}

export function getNext(dateTime: string | Date, periodType: PeriodType): Date {
  if (!dateTime || dateTime == '') {
    return new Date();
  }
  let last = new Date(dateTime);

  let newDay: Date = last;

  switch (periodType) {
    case PeriodType.month: {
      newDay = new Date(last.setDate(last.getDate() + 30));
      break;
    }
    case PeriodType.year: {
      newDay = new Date(last.setDate(last.getDate() + 365));
      break;
    }
  }
  return newDay;
}
