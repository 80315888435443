import { createAction } from "@reduxjs/toolkit";
import { LabelProductContentDto } from "../../api/Api";

export const  enum FavoriteProductsActions{
    SET_PRODUCTS, //Сохранить
    SET_ALL_PRODUCTS
    
}



export const setFavoriteProducts = createAction<LabelProductContentDto[]>('SET_PRODUCTS');
export const setAllFavoriteProducts = createAction<LabelProductContentDto[]>('SET_ALL_PRODUCTS');