import { FavoriteDownloadDto, FilterNavigationDto, LabelColorDto, LabelDto, LabelProductContentDto } from '../api/Api';
import { API } from '../configs/api';
import { saveFile } from '../utils/saveFile';
import { TypeFile } from '../view-models/type-files';

class FavoriteService {

  async getLabels() {
    try {
      let res = API.favoriteProducts.getLabelsCreate({ marketplaceId: 1 });
      let result = (await res).data;
      return (result as LabelDto[]) || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getProducts(filter: FilterNavigationDto) {
    try {
      let res = API.favoriteProducts.getProductsCreate(filter, { marketplaceId: 1 });
      let result = (await res).data;
      return (result as LabelProductContentDto[]) || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getCountProducts() {
    try {
      let res = API.favoriteProducts.getCountCreate();
      let result = (await res).data;
      return result || 0;
    } catch (err: any) {
      console.log(err);
      return 0;
    }
  }

  async changeProductLabel(productId: number, labelId: number) {
    try {
      let res = API.favoriteProducts.updateLabelProductPartialUpdate({
        lableId: labelId,
        productId: productId,
        marketplaceId: 1,
      });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
    }
  }

  async changeLabelName(newLabel: LabelDto) {
    try {
      let res = API.favoriteProducts.updateLabelPartialUpdate(newLabel);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return -1;
    
    }
  }

  async addProduct(idProduct: number) {
    try {
      API.favoriteProducts.insertProductLabelUpdate({
        lableId: -1,
        productId: idProduct,
        marketplaceId: 1,
      });
      return true;
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }

  async deleteProduct(idProduct: number) {
    try {
      API.favoriteProducts.deleteProductLabelDelete({ productId: idProduct, marketPlaceId: 1 });
      return true;
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }

  async startExportFile(typeFile: TypeFile, fieldsIds: number[]) {
    try {
      let fileFilter: FavoriteDownloadDto = {
        typeFile: Number(typeFile),
        fieldIds: fieldsIds,
      };
      let res = API.favoriteProducts.createDownloadFileCreate(fileFilter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }

  async getFile(idFile: string, fileType: TypeFile) {
    try {
      let res = API.productFilter.getFileByIdList({ id: idFile });
      let result = await (await res).blob();
      saveFile(result, TypeFile[fileType]);
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }
}

export const favoriteService: FavoriteService = new FavoriteService();
