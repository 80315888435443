import { ReactNode } from 'react';

export class TableColumn {
  id: number;
  name: string;
  title: string;
  typeColumn: TypeColumn;
  isActive: boolean;
  isSort?: boolean;
  width?: number;
  isLink?: boolean;
  defaultNumber?: number;
  buttonIcon?: ReactNode;
  buttonOnClick?: (idItem: number) => void;

  constructor(
    id: number,
    name: string,
    title: string,
    typeColumn: TypeColumn,
    isActive: boolean,
    width?: number,
    isSort?: boolean,
    isLink?: boolean,
    defaultNumber?: number,
    buttonIcon?: ReactNode,
    buttonOnClick?: (idItem: number) => void,
  ) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.typeColumn = typeColumn;
    this.isActive = isActive;
    this.isSort = isSort;
    this.width = width;
    this.isLink = isLink;
    this.defaultNumber = defaultNumber;
    this.buttonIcon = buttonIcon;
    this.buttonOnClick = buttonOnClick;
  }
}

export enum TypeColumn {
  string,
  image,
  button,
  favorite,
}
