import './expander.scss';
import { bemCN } from '../../configs/bem-classname';
import { FC, ReactNode, useState } from 'react';
import { ReactComponent as ArrowTop } from '../../shared/image/svg/arrow-top.svg';

type ExpanderProps = {
  id?: string;
  title: string;
  children: ReactNode;
  defaultActive?: boolean;
  onOpen?: () => void;
  onClose?:() =>void;
};

export const Expander: FC<ExpanderProps> = ({ id, title, children, defaultActive, onOpen, onClose }) => {
  const expanderCN = bemCN('expander');
  const [isShow, setIsShow] = useState(
    defaultActive !== undefined && defaultActive === false ? false : true,
  );

  const onClick = () => {
    if (!isShow && onOpen) {
      onOpen();
    }
    else{
      onClose && onClose();
    }
    setIsShow(!isShow);
  };

  return (
    <div id={id} className={expanderCN()}>
      <p
        onClick={onClick}
        className={expanderCN('title', ['h2', isShow ? 'expander__title-active' : ''])}
      >
        {title} <ArrowTop />
      </p>
      <div className={expanderCN('wrapper', [isShow ? 'expander__wrapper-active' : ''])}>
        <div className={isShow ? expanderCN('visible') : expanderCN('invisible')}>{children}</div>
      </div>
    </div>
  );
};
