import { linksUrl } from "./linksUrl";

export const topMenuLinksAnalytics:any[] = [
    {
        name:"Товары",
        path: linksUrl.analyitcs + linksUrl.analyticsProduct
    },
    {
        name:"Категории",
        path: linksUrl.analyitcs + linksUrl.analyitcsCategory
    },
    {
        name:"Продавцы",
        path: linksUrl.analyitcs + linksUrl.analyitcsSeller
    },
    {
        name:"Бренды",
        path: linksUrl.analyitcs + linksUrl.analyticsBrand
    },
];

export const topMenuLinksProfile:any[] = [
    {
        name:"Мой профиль",
        path: linksUrl.profile + linksUrl.profileInfo
    },
    {
        name:"Тарифы и оплата",
        path: linksUrl.profile + linksUrl.profileTariffs
    },
    {
        name:"История запросов",
        path: linksUrl.profile + linksUrl.profileHistory
    },
    {
        name:"Ключи",
        path: linksUrl.profile + linksUrl.profileKeys
    },
];