import { ReviewItem } from '../view-models/review-item';

export const reviews: ReviewItem[] = [
  new ReviewItem(
    1,
    5,
    'Xstat стал для меня настоящим открытием. Этот инструмент предоставляет невероятно ценные инсайты и помогает мне принимать обоснованные решения для развития моего бизнеса.',
    'Роман Симонов, продавец на Wildberries',
  ),
  new ReviewItem(
    2,
    4,
    'Когда я только начинал свой интернет-магазин на Wildberries, я совершенно не разбирался в аналитике и управлении рекламой. Мне было очень сложно понять, какие товары лучше продавать, как оптимизировать рекламные кампании и многое другое. Друзья посоветовали попробовать XSTAT, и я решил дать ему шанс. И я очень рад, что сделал этот выбор! Сервис действительно оказался очень простым и понятным в использовании, при этом предоставляя невероятно ценную аналитику. Уже после первого месяца работы с XSTAT я смог значительно увеличить продажи и оптимизировать рекламный бюджет. Теперь я точно знаю, что XSTAT будет моим постоянным помощником в развитии бизнеса на Wildberries',
    'Ольга Иванова, продавец на Wildberries',
  ),
  new ReviewItem(
    3,
    5,
    'Я совсем недавно начал продавать на Wildberries и сразу столкнулся с проблемой - как анализировать данные, выбирать перспективные ниши и управлять рекламой? Перепробовал множество инструментов, но они были либо слишком сложными, либо не давали нужной аналитики. Тогда я наткнулся на XSTAT и решил попробовать. И знаете, я очень рад, что сделал этот выбор! Сервис оказался действительно простым и интуитивно понятным в использовании, при этом предоставляя невероятно полезные данные. Уже после первых дней работы с XSTAT я смог выявить несколько перспективных ниш и настроить эффективные рекламные кампании. Теперь я уверен, что этот инструмент станет незаменимым помощником в развитии моего бизнеса на Wildberries.',
    'Алексей Соколов, продавец на Wildberries',
  ),
  new ReviewItem(
    4,
    4,
    'Одной из самых ценных возможностей XSTAT для меня стал автобидер. Раньше я тратила много времени на ручное управление ставками, пытаясь занять топовые позиции в рекомендациях и карточках товаров. Теперь же автобидер самостоятельно оптимизирует ставки, обеспечивая нужные позиции, при этом экономя мой рекламный бюджет. Это позволило мне сэкономить массу времени и денег, которые я могу направить на развитие бизнеса.',
    'Елена Крылова, владелица магазина товаров для дома',
  ),
  new ReviewItem(
    5,
    4,
    'Когда я только начинал продавать на Wildberries, было очень сложно понять, в каких нишах стоит работать и как правильно позиционировать свои товары. XSTAT стал для меня настоящим спасением - с помощью его аналитических инструментов я смог выявить самые перспективные категории, проанализировать конкурентов и разработать эффективную стратегию продвижения. Результат не заставил себя ждать - продажи стремительно пошли вверх, а я получил уверенность в правильности своих действий.',
    'Сергей Михайлов, основатель бренда спортивной одежды',
  ),
  new ReviewItem(
    6,
    5,
    'Благодаря возможности анализировать конверсии на различных этапах сделки, я смог идентифицировать узкие места и оптимизировать процесс так, чтобы больше пользователей успешно завершали покупку. Моя конверсия выросла значительно. Я давно искал надежный инструмент для аналитики продаж на Wildberries, который бы не только предоставлял данные, но и помогал принимать правильные решения. XSTAT стал для меня идеальным решением - он не только дает глубокую аналитику, но и помогает выявлять перспективные ниши, анализировать конкурентов и оптимизировать рекламные кампании. Благодаря этому сервису я смог значительно увеличить продажи и прибыль своего магазина.',
    'Максим Дмитров, владелец бренда детской одежды',
  ),
];
