import { useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './profile-history-page.scss';
import BigLoader from '../../components/big-loader/big-loader';
import { TableColumn } from '../../view-models/table-column';
import { Table } from '../../ui/table/table';
import { LocalStorageVariableName } from '../../services/localStorage-service';
import { HistoryItem } from '../../view-models/history-item';
import { historyService } from '../../services/history-service';
import { defaultProfileHistoryFilter } from '../../consts/default-filter-values';
import { profileHistoryColumns } from '../../consts/profile-history-colimns';
import { Pagination } from '../../components/pagination/pagination';
import { useTable } from '../../hooks/useTable';
import { ProfileHistoryFilter } from '../../view-models/profile-history-filter';
import { SimpleTable } from '../../ui/simple-table/simple-table';

const profileHistoryCN = bemCN('profile-history-page');
export const ProfileHistoryPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [tableColumns, setTableColumns] = useState<TableColumn[]>(profileHistoryColumns);
  const [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);
  const [historyFilter, setHistoryFilter] = useState<ProfileHistoryFilter>(
    defaultProfileHistoryFilter,
  );
  const [itemsCount, setItemsCount] = useState(-1);

  useEffect(() => {
    getCountItems();
    getHistoryItems(historyFilter);
  }, []);

  const getHistoryItems = (filter: ProfileHistoryFilter) => {
    setIsLoading(true);
    historyService.getHistory(filter).then((res: HistoryItem[]) => {
      setHistoryItems(res);
      setIsLoading(false);
    });
  };

  const getCountItems = () => {
    historyService.getItemsCount().then((res: number) => setItemsCount(res));
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, pageNumber, setPageNumber } = useTable(
    itemsCount,

    historyFilter,
    defaultProfileHistoryFilter,
    setHistoryFilter,

    getHistoryItems,
  );
  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={profileHistoryCN()}>
      <Pagination
        currentPageNumber={pageNumber}
        totalPagesCount={itemsCount ? Math.ceil(itemsCount / 50) : 1}
        onClick={onPagination}
      />
      <SimpleTable columns={tableColumns} data={historyItems} />
      <Pagination
        currentPageNumber={pageNumber}
        totalPagesCount={itemsCount ? Math.ceil(itemsCount / 50) : 1}
        onClick={onPagination}
      />
    </div>
  );
};
