import { FC, ReactNode, useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './select.scss';
import Popup from '../../ui/popup/popup';
import React from 'react';

export enum TypeSelectChild {
  check = 1,
  radio,
}

export interface SelectProps {
  children: ReactNode | ReactNode[];
  activeNames: string[];
  typeSelectChild: TypeSelectChild;
}
const selectCn = bemCN('select');

export const Select: FC<SelectProps> = ({ children, activeNames, typeSelectChild }) => {
  const [isShow, setIsShow] = useState(false);
  let child: ReactNode;

  if ((children as ReactNode[])[0]) {
    child = (
      <React.Fragment>
        {(children as ReactNode[]).map((c: any, index: number) =>
          index === 1 ? React.cloneElement(c, { setIsShow: setIsShow }) : React.cloneElement(c),
        )}
      </React.Fragment>
    );
  } else {
    child = React.cloneElement(children as any, { setIsShow: setIsShow });
  }

  return (
    <div className={selectCn()}>
      <div className={selectCn('button')} onClick={() => setIsShow(!isShow)}>
        {activeNames.length == 0 ? 'Не выбрано' : activeNames.join(' / ')}
      </div>
      <Popup isShow={isShow} setIsShow={setIsShow}>
        {typeSelectChild === TypeSelectChild.check ? children : child}
      </Popup>
    </div>
  );
};
