import './favorite-radio-group.scss';
import { FC, useState } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { bemCN } from '../../configs/bem-classname';
import { LabelDto } from '../../api/Api';
import { FavoriteColors } from '../favorite-check-group/favorite-check-group';

type FavoriteRadioGroupProps = {
  items: LabelDto[];
  activeId: number;
  onChange(id: number): void;
};

export const FavoriteRadioGroup: FC<any> = ({ activeId, items, onChange, setIsShow }) => {
  const favoriteRadioGroupCN = bemCN('favorite-radio-group');
  const [checkedId, setChekedId] = useState(activeId);

  const onClickItem = (id: number) => {
    if (id === checkedId) {
      setChekedId(-1);
      onChange(-1);
    } else {
      setChekedId(id);
      onChange(id);
      setIsShow && setIsShow(false);
    }
  };

  return (
    <div className={favoriteRadioGroupCN()}>
      {items &&
        items.map((item: any) => (
          <div onClick={() => onClickItem(item.id)} key={item.id}>
            <div
              className={favoriteRadioGroupCN(
                FavoriteColors[(item.colorId || 1) as FavoriteColors],
              )}
            >
              {checkedId == item.id ? <CheckMarkIcon /> : null}
            </div>
            <div>{item.name}</div>
          </div>
        ))}
    </div>
  );
};
