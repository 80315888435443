import { ReactNode } from 'react';
import { PromotionSteps } from '../consts/promotion-steps';

export class PromotionStep {
  id: PromotionSteps;
  title: string;
  toActionLink: string;
  imageName: ReactNode;
  toActionBtnText: string;
  toNextStepBtnText: string;

  constructor(
    id: PromotionSteps,
    title: string,
    toActionLink: string,
    imageName: ReactNode,
    toActionBtnText: string,
    toNextStepBtnText: string,
  ) {
    this.id = id;
    this.title = title;
    this.toActionLink = toActionLink;
    this.imageName = imageName;
    this.toActionBtnText = toActionBtnText;
    this.toNextStepBtnText = toNextStepBtnText;
  }
}
