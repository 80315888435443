import { getDateTime } from '../utils/date';

export class ApiKeyItem {
  id: number;
  nameKey: string;
  time: string;
  accessRules: string;

  constructor(id: number, name: string, time: string, accessRules: string[]) {
    this.id = id;
    this.time = getDateTime(time);
    this.nameKey = name;
    this.accessRules = accessRules.join(' , ');
  }
}
