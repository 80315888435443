import { FC, useEffect, useState } from 'react';
import './category-selected-item.scss';
import { bemCN } from '../../configs/bem-classname';
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { CategoryTree } from '../../view-models/category';

interface SearchFilterCategorySelect {
  items: CategoryTree[];
  setCheckedItems?: (ids: number[]) => void;
  checkedItems?: number[];

}

const searchFilterCategorySelect = bemCN('search-filter-category-selected');

const getAllIds = (items: CategoryTree[]) => {
  let ids: string[] = [];
  items.forEach((item: CategoryTree) => {
    ids = [...ids, item.value, ...getAllIds(item.children || [])];
  });
  return ids;
};

export const SearchFilterCategorySelect: FC<SearchFilterCategorySelect> = ({
  items,
  setCheckedItems,
  checkedItems,

}) => {
  const [checked, setChecked] = useState<string[]>(
    checkedItems ? checkedItems.map((id: number) => id.toString()) : [],
  );
  const [expanded, setExpanded] = useState<string[]>(getAllIds(items));

  useEffect(() => {
    setCheckedItems && setCheckedItems(checked.map((id: string) => Number(id)));
  }, [checked]);


  return (
    <div className={searchFilterCategorySelect()}>
      <CheckboxTree
        nodes={items}
        checked={checked}
        expanded={expanded}
        onCheck={(ids) => setChecked(ids)}
        onExpand={(expanded) => setExpanded(expanded)}
      />
    </div>
  );
};
