import { FC, ReactNode, useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './filter-bar.scss';
import { SearchInput } from '../search-input/search-input';
import { RangeInputs } from '../range-inputs/range-inputs';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';

export interface FilterBarProps {
  children: ReactNode;
  onResetFilter: () => void;
  onApplyFilter: () => void;
}
const filterBarCn = bemCN('filter-bar');

export const FilterBar: FC<FilterBarProps> = ({ children, onResetFilter, onApplyFilter }) => {
  return (
    <div className={filterBarCn()}>
      <div>{children}</div>

      <div className={filterBarCn('buttons')}>
        <Button
          onClick={onResetFilter}
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Сбросить"
        />
        <Button
          onClick={onApplyFilter}
          color={ColorsButton.violet}
          size={SizesButton.big}
          text="Применить"
        />
      </div>
    </div>
  );
};
