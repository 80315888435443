export const colors = [
  '#FF6699',
  '#6699FF',
  '#FFCC66',
  '#99FF00',
  '#FF99CC',
  '#CC66FF',
  '#99FF99',
  '#993399',
  '#00CCCC',
  '#339966',
  '#66CC33',
  '#CCFFFF',
  '#3333FF',
  '#FFCCFF',
];
