import { FC } from 'react';
import './stat-card.scss';
import { bemCN } from '../../configs/bem-classname';
import { ReactComponent as BlueChartIcon } from '../../shared/image/svg/blue-chart.svg';
import { ReactComponent as GreenChartIcon } from '../../shared/image/svg/green-chart.svg';
import { ReactComponent as OrangeChartIcon } from '../../shared/image/svg/orange-chart.svg';
import { ReactComponent as RedChartIcon } from '../../shared/image/svg/red-chart.svg';
import { ReactComponent as VioletChartIcon } from '../../shared/image/svg/violet-chart.svg';
import { SmallLoader } from '../small-loader/small-loader';

interface StatCardProps {
  id?: string;
  title: string;
  value: number;
  isLoad?: boolean;
  color: ColorStatCard;
  typeStatCard: TypeStatCard;
}

export enum TypeStatCard {
  grey,
  transporate,
}

export enum ColorStatCard {
  Blue = 1,
  Green,
  Orange,
  Red,
  Violet,
}

const Images = [
  {
    color: ColorStatCard.Blue,
    image: <BlueChartIcon />,
  },
  {
    color: ColorStatCard.Red,
    image: <RedChartIcon />,
  },
  {
    color: ColorStatCard.Green,
    image: <GreenChartIcon />,
  },
  {
    color: ColorStatCard.Orange,
    image: <OrangeChartIcon />,
  },
  {
    color: ColorStatCard.Violet,
    image: <VioletChartIcon />,
  },
];
const statCardCn = bemCN('stat-card');

export const StatCard: FC<StatCardProps> = ({ id, title, value, isLoad, color, typeStatCard }) => {
  let typeClass = TypeStatCard[typeStatCard];

  return (
    <div id={id} className={statCardCn({ type: typeClass })}>
      {Images.find((i: any) => i.color == color)?.image}
      {!isLoad ? (
        <div>
          <p>{title}</p>
          <p>{value !== -1 ? value.toLocaleString() : '-'}</p>
        </div>
      ) : (
        <SmallLoader />
      )}
    </div>
  );
};
