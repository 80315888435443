import { TableColumn, TypeColumn } from '../view-models/table-column';

export const sellersInfoTableColumns = [
  new TableColumn(1, 'color', 'Цвет', TypeColumn.image, true, 100),
  new TableColumn(2, 'name', 'Продавец', TypeColumn.string, true, 100),
  new TableColumn(3, 'revenueSum', 'Выручка, руб.', TypeColumn.string, true, 100),
  new TableColumn(4, 'loseRevenueSum', 'Упущенная выручка, руб.', TypeColumn.string, true, 100),
  new TableColumn(5, 'salesCount', 'Продажи, шт.', TypeColumn.string, true, 100),
];

export const brandsInfoTableColumns = [
  new TableColumn(1, 'color', 'Цвет', TypeColumn.image, true, 100),
  new TableColumn(2, 'name', 'Бренд', TypeColumn.string, true, 100),
  new TableColumn(3, 'revenue', 'Выручка, руб.', TypeColumn.string, true, 100),
  new TableColumn(4, 'loseRevenue', 'Упущенная выручка, руб.', TypeColumn.string, true, 100),
  new TableColumn(5, 'countSales', 'Продажи, шт.', TypeColumn.string, true, 100),
];
