import { Link, Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import { ReactComponent as LogoIcon } from '../../shared/image/svg/logo.svg';
import { ReactComponent as ArrowBottomIcon } from '../../shared/image/svg/arrow-bottom.svg';
import { ReactComponent as TelegramIcon } from '../../shared/image/svg/telegram.svg';
import { ReactComponent as VKIcon } from '../../shared/image/svg/vk.svg';
import './start-page.scss';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { linksUrl } from '../../consts/linksUrl';
import { phone, phoneWhatsApp } from '../../consts/phones';
import { authService } from '../../services/auth-service';
import { ReactComponent as Section1BackGroundIcon } from '../../shared/image/svg/main-1-section.svg';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Input, TypeDataInput, TypeViewInput } from '../../ui/input/input';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { ReactComponent as ArrowRightIcon } from '../../shared/image/svg/arrow-right.svg';
import { ReactComponent as BarChart1Icon } from '../../shared/image/svg/main-2-bar-chart.svg';
import { ReactComponent as ArrowLeftBigIcon } from '../../shared/image/svg/arrow-left-big.svg';
import { ReactComponent as ArrowRightBigIcon } from '../../shared/image/svg/arrow-right-big.svg';
import { ReactComponent as CheckMarkGreenIcon } from '../../shared/image/svg/check-mark-green.svg';
import { FeedbackFormDto, SellableItem, SiteJobTitle } from '../../api/Api';
import { paymentService } from '../../services/payment-service';
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { ReviewSlide } from '../../components/review-slide/review-slide';
import { reviews } from '../../consts/reviews';
import { ReviewItem } from '../../view-models/review-item';

import { ReactComponent as PhoneIcon } from '../../shared/image/svg/phone-feedback.svg';
import { ReactComponent as TimeIcon } from '../../shared/image/svg/timer.svg';
import { ReactComponent as AddressIcon } from '../../shared/image/svg/geoposition.svg';
import { Select, TypeSelectChild } from '../../components/select/select';
import { RadioGroup } from '../../components/radio-group/radio-group';
import { SelectItem } from '../../view-models/select-item';
import { feedbackService } from '../../services/feedback-service';
import { ColorsModal, Modal } from '../../ui/modal/modal';
import { emailHasError, nameHasError, phoneHasError } from '../../utils/validation-input';

interface FastRegProps {
  title: string;
  greenWord: string;
}
const FastReg: FC<FastRegProps> = ({ title, greenWord }) => {
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState<{ email: string; phone: string }>({
    email: '',
    phone: '',
  });
  return (
    <div className={fasrRegSectionCn()}>
      <h2>
        {title.split(greenWord)[0]}
        <span className={startPageCn('green')}> {greenWord}</span> {title.split(greenWord)[1]}
      </h2>
      <div>
        <Input
          onChange={(v: string) => setNewUser({ ...newUser, email: v })}
          placeholder="Почта"
          typeView={TypeViewInput.dark}
          typeData={TypeDataInput.email}
          isValidate
        />
        <Input
          onChange={(v: string) => setNewUser({ ...newUser, phone: v })}
          placeholder="Номер телефона"
          typeView={TypeViewInput.dark}
          typeData={TypeDataInput.phone}
          isValidate
        />
      </div>
      <Button
        onClick={() => {
          navigate(linksUrl.register, { state: { user: newUser } });
        }}
        text="Начать"
        color={ColorsButton.green}
        size={SizesButton.big}
      />
    </div>
  );
};

const solutionsText = [
  {
    id: 1,
    title: 'Рост продаж и прибыли',
    summary: [
      'Находите прибыльные ниши и оптимальный ассортимент для вашего магазина',
      'Анализируйте данные о продажах и конкурентах, чтобы принимать обоснованные решения',
      'Автоматизируйте управление рекламными ставками и экономьте бюджет',
    ],
  },
  {
    id: 2,

    title: 'Экономия времени и усилий',
    summary: [
      'Получайте всю необходимую аналитику в одном месте, без трудоемкого сбора данных вручную',
      'Автобидер самостоятельно управляет ставками, обеспечивая топовые позиции',
      'Персональная поддержка экспертов избавит вас от рутинных задач и решит любые вопросы',
    ],
  },
  {
    id: 3,

    title: 'Спокойствие и уверенность',
    summary: [
      'Вы всегда будете в курсе ситуации на маркетплейсе и сможете предвидеть тренды',
      'Надежная защита данных и конфиденциальность гарантируют безопасность вашего бизнеса',
      'Многолетний опыт работы XSTAT и положительные отзывы клиентов вселяют доверие',
    ],
  },
  {
    id: 4,
    title: 'Больше свободного времени',
    summary: [
      'Автоматизация рутинных процессов высвобождает время для стратегического управления',
      'Вы можете сфокусироваться на развитии бизнеса, а не на сборе и анализе данных',
      'Меньше стресса и больше возможностей наслаждаться жизнью за пределами работы',
    ],
  },
];

const startPageCn = bemCN('start-page');
const mainSectionCn = bemCN('main-section');
const descriptionSectionCn = bemCN('description-section');
const fasrRegSectionCn = bemCN('fastReg-section');
const solutionsSectionCn = bemCN('solutions-section');
const opportunitiesSectionCn = bemCN('opportunities-section');
const advantagesSectionCn = bemCN('advantages-section');
const tariffsSectionCn = bemCN('tariffs-section');
const reviewsSectionCn = bemCN('reviews-section');
const feedbackSectionCn = bemCN('feedback-section');

export const StartPage = () => {
  const navigate = useNavigate();
  const isAuth = authService.getIsAuth();

  const [activeId, setActiveId] = useState(1);

  const [tarrifs, setTariffs] = useState<SellableItem[]>([]);

  useEffect(() => {
    paymentService.getTariffs().then((res: SellableItem[]) => setTariffs(res));
  }, []);

  const toRegistation = () => {
    navigate(linksUrl.register);
  };

  const toLogin = () => {
    navigate(linksUrl.login);
  };

  const toService = () => {
    navigate(linksUrl.analyitcs + linksUrl.analyticsProduct);
  };
  const swiperRef = useRef<SwiperClass>();

  const [feetback, setFeetbact] = useState<FeedbackFormDto>({
    name: '',
    phone: '',
    email: '',
    message: '',
    specialty: 1,
  });
  const [roles, setRoles] = useState<SiteJobTitle[]>([]);
  const [isValidateSendButton, setIsValidteSendButton] = useState(false);

  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    authService.getRoles().then((res: SiteJobTitle[]) => setRoles(res));
  }, [1]);

  const sendMessage = () => {
    feedbackService.sendMessage(feetback).then((res: any) => {
      setFeetbact({
        name: '',
        phone: '',
        email: '',
        message: '',
        specialty: -1,
      });
      setIsShowModal(true);
    });
  };

  useEffect(() => {
    if (
      !phoneHasError(feetback.phone || '') &&
      !emailHasError(feetback.email || '') &&
      feetback.message &&
      feetback.message?.length > 0 &&
      feetback.name &&
      feetback.name?.length > 0
    ) {
      setIsValidteSendButton(true);
    } else {
      setIsValidteSendButton(false);
    }
  }, [feetback]);

  const onClickSendButton = () => {
    sendMessage();
  };

  const [isOpenMenu, setIsOpenMune] = useState(false);

  const onClickMenuButton = () => {
    let navIcon: HTMLElement = document.getElementById('nav-icon') as HTMLElement;
    if (navIcon) {
      if (!isOpenMenu) {
        navIcon.classList.add('open');
      } else {
        navIcon.classList.remove('open');
      }
      setIsOpenMune(!isOpenMenu);
    }
  };

  const onClickTariffLink = () => {
    onClickMenuButton();
    document &&
      document.querySelector('#tariffs-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  };

  const onClickOpportunitiesLink = () => {
    onClickMenuButton();

    document &&
      document.querySelector('#opportunities-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  };

  return (
    <div className={startPageCn()}>
      <div className={startPageCn('header')}>
        <div className={startPageCn('logo')}>
          <LogoIcon />
          <span>STAT</span>
        </div>
        <div className={startPageCn('mune-container', { active: isOpenMenu })}>
          <div className={startPageCn('top-menu')}>
            <div>
              <a onClick={onClickTariffLink}>Тарифы</a>
              <ArrowBottomIcon />
            </div>
            <div>
              <a onClick={onClickOpportunitiesLink}>Возможности</a>
              <ArrowBottomIcon />
            </div>
          </div>
          <div className={startPageCn('buttons')}>
            {isAuth ? (
              <Button
                onClick={toService}
                text="К сервису"
                color={ColorsButton.green}
                size={SizesButton.big}
              />
            ) : (
              <>
                <Button
                  onClick={toLogin}
                  text="Войти"
                  color={ColorsButton.transition}
                  size={SizesButton.big}
                />
                <Button
                  onClick={toRegistation}
                  text="Зарегистрироваться"
                  color={ColorsButton.green}
                  size={SizesButton.big}
                />
              </>
            )}
          </div>
        </div>
        <div onClick={onClickMenuButton} id="top-menu-btn">
          <div id="nav-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div className={mainSectionCn()}>
        <Section1BackGroundIcon />
        <div>
          <h1>
            <span className={startPageCn('green')}>Аналитика маркетплейсов</span> Wildberries и Ozon
            для <span className={startPageCn('green')}>роста вашего бизнеса</span>
          </h1>
          <div>
            <p>Комплексное решение для анализа продаж, конкурентов и управления рекламой.</p>
            <p className={startPageCn('green')}>Бесплатный период 5 дней</p>
          </div>
          <Button
            onClick={toRegistation}
            text="Попробовать бесплатно"
            size={SizesButton.big}
            color={ColorsButton.green}
          />
        </div>
      </div>

      <FastReg greenWord="увеличить" title="Хотите увеличить продажи и сэкономить?" />

      <div className={descriptionSectionCn()}>
        <div>
          <div>
            <CheckMarkIcon /> <p>Глубокая аналитика продаж и конкурентов</p>
          </div>
          <div>
            <CheckMarkIcon /> <p>Комплексный подход к управлению рекламой</p>
          </div>
          <div>
            <CheckMarkIcon /> <p>Передовые инструменты для глубокого анализа данных</p>
          </div>
          <div>
            <CheckMarkIcon /> <p>Прогнозирование спроса и оптимизация стратегии</p>
          </div>
          <div>
            <CheckMarkIcon /> <p>Автоматизация управления рекламными ставками</p>
          </div>
          <div>
            <CheckMarkIcon /> <p>Персональная поддержка экспертов</p>
          </div>
        </div>
        <div>
          <p>
            <span>XSTAT</span> — это программа для аналитики маркетплейсов, которая поможет вам
            находить прибыльные ниши, анализировать стратегию конкурентов и эффективно
            оптимизировать рекламные кампании.
          </p>
          <BarChart1Icon />
        </div>
      </div>

      <div className={solutionsSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Готовые </span>
          решения
        </h1>
        <div>
          <div>
            {solutionsText.map((sol: any, index: number) => (
              <div
                onClick={() => setActiveId(sol.id)}
                key={sol.id}
                className={sol.id === activeId ? 'active' : ''}
              >
                {index + 1}. {sol.title} <ArrowRightIcon />
              </div>
            ))}
          </div>
          <div>
            <span>{activeId}</span>
            <p>{solutionsText.find((s: any) => s.id === activeId)?.title}</p>
            {solutionsText
              .find((s: any) => s.id === activeId)
              ?.summary.map((sum: string, index: number) => (
                <div key={index.toString()}>
                  — <p>{sum}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <FastReg greenWord="продажи" title="Увеличьте продажи без труда!" />

      <div id="opportunities-section" className={opportunitiesSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Возможности </span>
          сервиса
        </h1>
        <div>
          <div>
            <div className={opportunitiesSectionCn('image')}></div>
            <div className={opportunitiesSectionCn('info')}>
              <p>Управление рекламой</p>
              <p className={opportunitiesSectionCn('p_title')}>
                Автоматизация управления рекламными ставками
              </p>
              <p>
                Используйте удобные и понятные инструменты автоматического управления ставками
                рекламных объявлений. Алгоритмы самостоятельно настраивают ставки, основываясь на
                заданных параметрах, что позволяет сократить расходы на рекламу и занимать ТОПовые
                позиции.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>
                Таргетированная реклама на маркетплейсах
              </p>
              <p>
                Настраивайте точный таргетинг, чтобы объявления демонстрировались только наиболее
                заинтересованным покупателям. Определяйте оптимальный диапазон мест показа для
                каждой рекламной кампании, повышая эффективность продвижения на маркетплейсах.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>Анализ эффективности рекламы</p>
              <p>
                Регулярно анализируйте ключевые показатели эффективности рекламных кампаний на
                маркетплейсах. Отслеживайте метрики конверсий, стоимости привлечения клиентов и
                рентабельности инвестиций, чтобы выявлять и отказываться от неэффективных источников
                трафика.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>Управление рекламным бюджетом</p>
              <p>
                Устанавливайте бюджет для каждой рекламной кампании на маркетплейсах Wildberries и
                Ozon и тщательно контролируйте расходы. Оптимизируйте ставки и повышайте
                эффективность рекламы, чтобы избежать перерасхода средств и максимизировать прибыль.
              </p>
            </div>
          </div>
          <div>
            <div className={opportunitiesSectionCn('info')}>
              <p>Аналитика</p>
              <p className={opportunitiesSectionCn('p_title')}>
                Определение оптимального ассортимента для вашего магазина на маркетплейсах
              </p>
              <p>
                Выявляйте прибыльные товарные категории и ассортимент для магазина на маркетплейсах,
                таких как Wildberries и Ozon. Анализ продаж, спроса и конкуренции позволит
                сформировать ассортимент, который максимизирует продажи и прибыль.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>
                Всесторонний анализ ключевых показателей на маркетплейсах
              </p>
              <p>
                Используйте передовые аналитические инструменты XSTAT, чтобы детально изучить
                продажи, категории, бренды и конкурентов на маркетплейсах. Получайте ценные данные и
                инсайты, для принятия обоснованных решений и развития бизнеса.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>
                Выявление и анализ ключевых игроков в выбранных нишах
              </p>
              <p>
                Определите основных продавцов и конкурентов в наиболее перспективных нишах на
                маркетплейсах. Глубокий анализ их стратегий, ассортимента и ценообразования позволит
                вам разработать эффективную конкурентную стратегию для вашего бизнеса.
              </p>
              <p className={opportunitiesSectionCn('p_title')}>
                Выбор наиболее прибыльных ниш для выхода на маркетплейсы
              </p>
              <p>
                На основе всесторонней аналитики XSTAT выявляйте наиболее перспективные ниши с
                высоким спросом и низким уровнем конкуренции. Это позволит сфокусироваться на
                наиболее прибыльных направлениях для развития вашего бизнеса на маркетплейсах.
              </p>
            </div>
            <div className={opportunitiesSectionCn('image')}></div>
          </div>
        </div>
      </div>

      <FastReg greenWord="преимущество" title="Получи преимущество над конкурентами!" />

      <div className={advantagesSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Наши </span>
          преимущества
        </h1>
        <div>
          <div>
            <p>Клиентская поддержка</p>
            <p>Мы стремимся предоставлять качественный сервис и поддержку нашим клиентам</p>
            <span>1</span>
          </div>
          <div>
            <p>Безопасность и конфиденциальность</p>
            <p>Мы понимаем важность защиты данных вашей компании и ваших клиентов.</p>
            <span>2</span>
          </div>
          <div></div>
          <div></div>
          <div>
            <p>Прогнозирование спроса</p>
            <p>
              Вы можете предсказывать будущий спрос на услуги на основе исторических данных и
              трендов.
            </p>
            <span>3</span>
          </div>
          <div>
            <p>Анализ динамики цен и оптимизация стратегии</p>
            <p>
              Вы сможете анализировать динамику цен на маркетплейсе и оценить их влияние на спрос.
            </p>
            <span>4</span>
          </div>
        </div>
      </div>

      <div id="tariffs-section" className={tariffsSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Выберите </span>
          тариф
        </h1>
        <div>
          {tarrifs.map((t: SellableItem) => (
            <div key={t.id?.toString()}>
              <p>{t.name}</p>
              <p>
                {t.basePrice !== 0 && t.basePrice
                  ? (t.basePrice / 100).toLocaleString('ru-Ru') + ' ₽'
                  : 'Бесплатно'}
              </p>
              <Button
                size={SizesButton.big}
                color={ColorsButton.green}
                onClick={toRegistation}
                text={
                  t.basePrice && t.basePrice !== 0
                    ? ((t.basePrice || 1) / (t.durationMonths || 1) / 100).toLocaleString() +
                      ' ₽ в месяц'
                    : 'Начать сейчас'
                }
              />
              <div>
                {t.description &&
                  t.description.map((t: string) => (
                    <p key={t}>
                      <CheckMarkIcon />
                      {t}
                    </p>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <FastReg greenWord="Зарегистрируйтесь" title="Зарегистрируйтесь и попробуйте бесплатно!" />

      <div className={reviewsSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Отзывы </span>
          клиентов
        </h1>
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          autoHeight={false}
          navigation={true}
          modules={[Navigation]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            841: {
              slidesPerView: 2,
            },
          }}
        >
          {reviews.map((r: ReviewItem) => (
            <SwiperSlide key={r.id}>
              <ReviewSlide review={r} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div>
          <Button
            onClick={() => swiperRef?.current?.slidePrev()}
            size={SizesButton.small}
            color={ColorsButton.green}
            icon={<ArrowLeftBigIcon />}
          />
          <Button
            onClick={() => swiperRef?.current?.slideNext()}
            size={SizesButton.small}
            color={ColorsButton.green}
            icon={<ArrowRightBigIcon />}
          />
        </div>
      </div>

      <div className={feedbackSectionCn()}>
        <h1>
          <span className={startPageCn('green')}>Оставьте </span>
          заявку
        </h1>
        <div>
          <div className={feedbackSectionCn('info')}>
            <p>Связаться с нами. Это просто.</p>
            <p>
              Хотите увеличить продажи на Wildberries и Ozon? Оставьте заявку, и мы расскажем, как
              XSTAT поможет вам найти прибыльные ниши и эффективно управлять рекламой.
            </p>
            <div className={feedbackSectionCn('contacts')}>
              <div className={feedbackSectionCn('contact-item')}>
                <PhoneIcon />
                <div>
                  <p>Звони сейчас</p>
                  <a href={'tel:' + phoneWhatsApp}>{phone}</a>
                </div>
              </div>
              <div className={feedbackSectionCn('contact-item')}>
                <TimeIcon />
                <div>
                  <p>График работы</p>
                  <p>9:00 - 18:00</p>
                </div>
              </div>
              <div className={feedbackSectionCn('contact-item')}>
                <AddressIcon />
                <div>
                  <p>Адрес</p>
                  <a href="https://yandex.ru/maps/-/CDdDvFLx">Ярославль, Ленина 25, офис 1</a>
                </div>
              </div>
            </div>
          </div>
          <div className={feedbackSectionCn('form')}>
            <div>
              <div className={feedbackSectionCn('input')}>
                <p>Ваше имя</p>
                <Input
                  placeholder="Имя"
                  typeView={TypeViewInput.dark}
                  typeData={TypeDataInput.text}
                  onChange={(v: string) => setFeetbact({ ...feetback, name: v })}
                />
              </div>
              <div className={feedbackSectionCn('input')}>
                <p>Адрес электронной почты</p>
                <Input
                  placeholder="Email"
                  typeView={TypeViewInput.dark}
                  typeData={TypeDataInput.email}
                  onChange={(v: string) => setFeetbact({ ...feetback, email: v })}
                  isValidate
                />
              </div>

              <div className={feedbackSectionCn('input')}>
                <p>Номер телефона</p>
                <Input
                  placeholder="Номер телефона"
                  typeView={TypeViewInput.dark}
                  typeData={TypeDataInput.phone}
                  onChange={(v: string) => setFeetbact({ ...feetback, phone: v })}
                  isValidate
                />
              </div>
              <div className={feedbackSectionCn('input')}>
                <p>Специальность</p>
                <Select
                  activeNames={[
                    roles.length !== 0
                      ? roles.find((r: SiteJobTitle) => r.id == feetback.specialty)?.title || ''
                      : '',
                  ]}
                  typeSelectChild={TypeSelectChild.radio}
                >
                  <RadioGroup
                    items={roles.map(
                      (r: SiteJobTitle) => new SelectItem(r.id || -1, r.title || ''),
                    )}
                    activeId={feetback.specialty}
                    onChange={(id: number) => {
                      setFeetbact({ ...feetback, specialty: id });
                    }}
                  />
                </Select>
              </div>
            </div>
            <div className={feedbackSectionCn('input')}>
              <p>Сообщение</p>
              <textarea
                onChange={(v: ChangeEvent<HTMLTextAreaElement>) =>
                  setFeetbact({ ...feetback, message: v.target.value })
                }
                placeholder="Оставьте сообщение, и наши специалисты готовы предоставить вам необходимую консультацию."
              />
            </div>
            <Button
              onClick={onClickSendButton}
              color={ColorsButton.green}
              size={SizesButton.big}
              text="Отправить"
              noDisabled={!isValidateSendButton}
            />
          </div>
          <Modal
            isShowDialog={isShowModal}
            setIsShowDialog={setIsShowModal}
            title={''}
            color={ColorsModal.dark}
          >
            <div className={feedbackSectionCn('modal')}>
              <CheckMarkGreenIcon />
              <p>Спасибо за проявленный интерес к XSTAT!</p>
              <p>Мы получили ваше сообщение и скоро свяжемся с вами.</p>
              <Button
                onClick={() => setIsShowModal(false)}
                color={ColorsButton.green}
                size={SizesButton.big}
                text="Ок"
              />
            </div>
          </Modal>
        </div>
      </div>

      <div className={startPageCn('footer')}>
        <div>
          <div className={startPageCn('logo')}>
            <LogoIcon />
            <span>STAT</span>
          </div>
          <div>
            <a href={'#tariffs-section'}>Тарифы</a>
            <a href={'#opportunities-section'}>Возможности</a>
          </div>
          <div>
            <a target="_blank" href="https://t.me/xstatru">
              <TelegramIcon />
            </a>
            <a target="_blank" href="https://vk.com/xstatru">
              <VKIcon />
            </a>
          </div>
        </div>
        <div>
          <div>
            <a href={linksUrl.offer} target="_blank ">
              Договор-оферта
            </a>
            <a href={linksUrl.politics} target="_blank ">
              Политика обработки персональных данных
            </a>
          </div>
          <div>
            <a href={`mailto:sample@example.com`}>xstat@yandex.ru</a>{' '}
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div>
          <span>Xstat © {new Date().getFullYear()}. Все права защищены</span>
          <div>
            {isAuth ? (
              <Button
                onClick={toService}
                text="К сервису"
                color={ColorsButton.green}
                size={SizesButton.big}
              />
            ) : (
              <>
                <Button
                  onClick={toLogin}
                  text="Войти"
                  color={ColorsButton.transition}
                  size={SizesButton.big}
                />
                <Button
                  onClick={toRegistation}
                  text="Зарегистрироваться"
                  color={ColorsButton.green}
                  size={SizesButton.big}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
