import { useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import { promotionSteps, PromotionSteps as PromotionStepsEnum } from '../../consts/promotion-steps';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import './promotion-steps.scss';
import { PromotionStep } from '../../view-models/promotion-step';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { Link, useLocation } from 'react-router-dom';

const promotionStepsCn = bemCN('promotion-steps');

export const PromotionSteps = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<PromotionStepsEnum>(
    localStorageService.getValue<PromotionStepsEnum>(LocalStorageVariableName.promotionSteps) ||
      PromotionStepsEnum.addCabinet,
  );

  const toNextStep = (id: PromotionStepsEnum) => {
    let nextStep: PromotionStepsEnum = (id + 1) as PromotionStepsEnum;
    setCurrentStep(nextStep);
    localStorageService.setValue(LocalStorageVariableName.promotionSteps, nextStep);
    if (id == PromotionStepsEnum.addAgree) {
      document.location.reload();
    }
  };

  return (
    <div className={promotionStepsCn()}>
      {promotionSteps.map((s: PromotionStep) => {
        return (
          <div
            className={promotionStepsCn('step', {
              isDisable: s.id !== currentStep,
              isСompleted: s.id < currentStep,
            })}
            key={s.id.toString()}
          >
            <div>
              <div className={promotionStepsCn('img')}>{s.imageName}</div>
              <div className={promotionStepsCn('info')}>
                <span>Шаг {s.id} из 3</span>
                <p>{s.title}</p>
              </div>
            </div>
            <div>
              {s.id == PromotionStepsEnum.addAgree ? (
                <Button
                  text={s.toActionBtnText}
                  size={SizesButton.big}
                  color={ColorsButton.violet}
                  onClick={() => toNextStep(s.id)}
                />
              ) : (
                <>
                  <Button
                    text={s.toNextStepBtnText}
                    size={SizesButton.big}
                    color={ColorsButton.violet}
                    onClick={() => toNextStep(s.id)}
                  />
                  <Link target="_blank" to={s.toActionLink}>
                    {s.toActionBtnText}
                  </Link>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
