import { FC, MouseEventHandler } from 'react';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { bemCN } from '../../configs/bem-classname';
import {
  PieChart as RechartPieChart,
  Cell,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Tooltip as CustomTooltip } from '../tooltip/tooltip';
import './pie-chart.scss';

type PieChartProps = {
  data: ChartDataItem[];
  unit?: string;
  errorText: string;
  isLegend?: boolean;
  height?: number;
};
const pieChartCN = bemCN('pie-chart');

export const PieChart: FC<PieChartProps> = ({ data, unit, errorText, isLegend, height }) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel: FC<{
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
  }> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x - 10}
        y={y}
        fill="var(--white_2)"
        textAnchor="central"
        dominantBaseline="middle"
        fontSize={12}
        alignmentBaseline="central"
      >
        {percent * 100 > 5 ? `${(percent * 100).toFixed(0)}%` : ''}
      </text>
    );
  };

  return (
    <div className={pieChartCN()}>
      {data.length == 0 ? (
        <div className={pieChartCN('error')}>{errorText}</div>
      ) : (
        <ResponsiveContainer width="100%" height={height ? height : 270}>
          <RechartPieChart
            width={270}
            height={270}
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip content={<CustomTooltip unit={unit} />} cursor={{ fill: 'transparent' }} />
            {isLegend && (
              <Legend
                wrapperStyle={{ overflowY: 'auto', scrollbarWidth: 'thin' }}
                verticalAlign='middle'
                align='right'
                layout='vertical'
                iconType="circle"
              />
            )}
            <Pie
              isAnimationActive={true}
              label={renderCustomizedLabel}
              labelLine={false}
              data={data}
              dataKey="value"
              nameKey={'label'}
              innerRadius={45}
              className={pieChartCN('pie')}
            >
              {data.map((item, index) => {
                return (
                  <Cell stroke="var(--icon-color-white)" fill={item.color as string} key={`cell-${index}`} />
                );
              })}
            </Pie>
          </RechartPieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
