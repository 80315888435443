import { Outlet, redirect } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import SideBar from '../../components/sidebar/sidebar';
import './root-page.scss';
import { Header } from '../../components/header/header';
import { useEffect } from 'react';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';


export const RootPage = () => {
  const rootPageCn = bemCN('root-page');

  useEffect(() => {
    let theme = localStorageService.getValue(LocalStorageVariableName.theme);
    if (theme) {
      let body = document.getElementsByTagName('body')[0];
      theme === 'light' ? body.classList.remove('_dark') : body.classList.add('_dark');
    }
  }, []);

  return (
    <div className={rootPageCn()}>
      <SideBar />
      <div>
        <Header />
        <Outlet />
      </div>
    </div>
  );
};
