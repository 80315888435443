import { EffectCallback } from 'react';
import {
  CampaignCardDto,
  CampaignContainerDto,
  CampaignDto,
  CampaignFilterDto,
  EfficiencyCampaignDto,
  GetCampaignCardRequestDto,
  StatCardCampaign,
  StatisticsCampaignDto,
} from '../api/Api';
import { API } from '../configs/api';
import { formatFullDate, getDateTime } from '../utils/date';
import { PromotionCabinet } from '../view-models/promotion-cabinet';
import { PromotionCardFilter, PromotionFilter } from '../view-models/promotion-filter';

class PromotionService {
  async getCabinets() {
    try {
      let res = API.apiKey.getCabinetsList();
      let result = (await res).data;
      return result.map((item: any) => new PromotionCabinet(item.id, item.name)) || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getStatistics(filter: PromotionFilter) {
    try {
      let res = API.campaign.getStatisticsCreate(
        { dateFrom: filter.dateFrom, dateTo: filter.dateTo },
        { cabinetId: filter.cabinetId },
      );
      let result = (await res).data;
      return result
        ? ({
            ...result,
            cr: result.cr || result.cr == 0 ? Number(Number(result.cr).toFixed(2)) : null,
            ctr: result.ctr || result.ctr == 0 ? Number(Number(result.ctr).toFixed(2)) : null,
            roi: result.roi || result.roi == 0 ? Number(Number(result.roi).toFixed(2)) : null,
            roas: result.roas || result.roas == 0 ? Number(Number(result.roas).toFixed(2)) : null,
            cpa: result.cpa || result.cpa == 0 ? Number(Number(result.cpa).toFixed(2)) : null,
            revenue:
              result.revenue || result.revenue == 0
                ? Number(Number(result.revenue).toFixed(2))
                : null,
            cost: result.cost || result.cost == 0 ? Number(Number(result.cost).toFixed(2)) : null,
            atbs: result.atbs || result.atbs == 0 ? Number(Number(result.atbs).toFixed(2)) : null,
            profit:
              result.profit || result.profit == 0 ? Number(Number(result.profit).toFixed(2)) : null,
            view: result.view || result.view == 0 ? Number(Number(result.view).toFixed(2)) : null,

            efficiency: result.efficiency
              ? result.efficiency
                  .filter((e: EfficiencyCampaignDto) => e.efficiency !== null)
                  .map((ef: EfficiencyCampaignDto) => {
                    return {
                      idCAmpaign: ef.idCAmpaign,
                      efficiency: Number(Number(ef.efficiency).toFixed(2)),
                    } as EfficiencyCampaignDto;
                  })
                  .sort(
                    (a: EfficiencyCampaignDto, b: EfficiencyCampaignDto) =>
                      (a.efficiency || 0) - (b.efficiency || 0),
                  )
              : {},
          } as StatisticsCampaignDto)
        : {};
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getCampaigns(filter: CampaignFilterDto) {
    try {
      if (filter.statusIds?.length == 0) {
        delete filter.statusIds;
      }
      if (filter.typeIds?.length == 0) {
        delete filter.typeIds;
      }
      let res = API.campaign.postCampaign(filter);
      let result = (await res).data;
      return {
        ...result,
        campaigns: result.campaigns?.map((x: CampaignDto) => {
          return {
            ...x,
            changeTime: getDateTime(x.changeTime || ''),
            createTime: getDateTime(x.createTime || ''),
            startTime: getDateTime(x.startTime || ''),
          } as CampaignDto;
        }),
      } as CampaignContainerDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getnCard(filter: PromotionCardFilter) {
    try {
      let res = API.campaign.cardCreate(filter);
      let result = (await res).data;
      return {
        ...result,
        createdDate: formatFullDate(result.createdDate || ''),
        endTime: formatFullDate(result.endTime || ''),
        cr: result.cr ? Number(Number(result.cr).toFixed(2)) : null,
        ctr: result.ctr ? Number(Number(result.ctr).toFixed(2)) : null,
        romi: result.romi ? Number(Number(result.romi).toFixed(2)) : null,
        roas: result.roas ? Number(Number(result.roas).toFixed(2)) : null,
        cpc: result.cpc ? Number(Number(result.cpc).toFixed(2)) : null,
        cpo: result.cpo ? Number(Number(result.cpo).toFixed(2)) : null,
        stats: result.stats?.map((s: StatCardCampaign) => {
          return { ...s, date: formatFullDate(s.date || '') };
        }),
      } as CampaignCardDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }
}

export const promotionService: PromotionService = new PromotionService();
