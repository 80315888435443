import { bemCN } from '../../configs/bem-classname';
import './instructions-page.scss';
const instructionsPageCn = bemCN('instructions-page');

export const InstructionsPage = () => {
  return (
    <div className={instructionsPageCn()}>
      <embed
        width="100%"
        height="100%"
        src={'/files/instructions.pdf#page=1&zoom=100'}
        type="application/pdf"
      />
    </div>
  );
};
