import { PromotionStep } from '../view-models/promotion-step';
import { linksUrl } from './linksUrl';
import { ReactComponent as Step1 } from '../shared/image/svg/promotion-step-1.svg';
import { ReactComponent as Step2 } from '../shared/image/svg/promotion-step-2.svg';
import { ReactComponent as Step3 } from '../shared/image/svg/promotion-step-3.svg';

export enum PromotionSteps {
  addCabinet = 1,
  addKey,
  addAgree,
  all
}

export const promotionSteps: PromotionStep[] = [
  new PromotionStep(
    PromotionSteps.addCabinet,
    'Создайте API-ключ в личном кабинете WB Seller.',
    linksUrl.root + linksUrl.instructions,
    <Step1 />,
    'Создать',
    'Я уже создал',
  ),
  new PromotionStep(
    PromotionSteps.addKey,
    'Подключите API-ключ к Вашему личному кабинету XSTAT.',
    linksUrl.profile + linksUrl.profileKeys,
    <Step2 />,

    'Подключить',
    'Я уже подключил',
  ),
  new PromotionStep(
    PromotionSteps.addAgree,
    'Вы почти у цели, нажмите на кнопку ниже и настроится связь.',
    linksUrl.promotion,
    <Step3 />,

    'Подключить рекламный кабинет',
    '',
  ),
];
