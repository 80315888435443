import { AddApiKeyRequestDto, GetApiKeysByOwnerResponseDto } from '../api/Api';
import { API } from '../configs/api';
import { ApiKeyItem } from '../view-models/api-key-item';
import { Country } from '../view-models/country';

class ApiKeysService {
  async getKeys() {
    try {
      let res = API.apiKey.getApiKeysByOwnerList();
      let result = (await res).data as GetApiKeysByOwnerResponseDto[];
      return (
        result.map(
          (item: GetApiKeysByOwnerResponseDto) =>
            new ApiKeyItem(
              item.id || -1,
              item.name || '',
              item.expiryDateTime || '',
              item.scopeFlags || [],
            ),
        ) || []
      );
    } catch (err: any) {
      console.error(err);
      return [];
    }
  }

  async deleteKey(idKey: number) {
    try {
      let res = API.apiKey.deleteApiKeyDelete({ id: idKey });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.error(err);
    }
  }

  async addKey(newKey: AddApiKeyRequestDto) {
    try {
      let res = API.apiKey.addApiKeyCreate(newKey);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.error(err);
      return "Некорректное значение ключа!";
    }
  }
}

export const apiKeysService: ApiKeysService = new ApiKeysService();
