import './shared/styles/index.scss';
import { Provider } from 'react-redux';
// import AuthProvider from './view/components/auth-provider/auth-provider';
import AppRouter from './configs/routes';
import { store } from './store';

function App() {
  return (
    <Provider store={store}>
      {/* <AuthProvider>  */}
      <AppRouter />
      {/* </AuthProvider> */}
    </Provider>
  );
}

export default App;
