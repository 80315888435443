
export const countries = [
  {
    id: 113,
    name: 'Россия',
  },
  {
    id: 5,
    name: 'Украина',
  },
  {
    id: 40,
    name: 'Казахстан',
  },
  {
    id: 9,
    name: 'Азербайджан',
  },
  {
    id: 16,
    name: 'Беларусь',
  },
  {
    id: 28,
    name: 'Грузия',
  },
  {
    id: 2112,
    name: 'Абхазия',
  },
  {
    id: 6,
    name: 'Австралия',
  },
  {
    id: 7,
    name: 'Австрия',
  },
  {
    id: 2357,
    name: 'Албания',
  },
  {
    id: 2368,
    name: 'Алжир',
  },
  {
    id: 2376,
    name: 'Ангола',
  },
  {
    id: 2354,
    name: 'Андорра',
  },
  {
    id: 235,
    name: 'Аргентина',
  },
  {
    id: 13,
    name: 'Армения',
  },
  {
    id: 2110,
    name: 'Афганистан',
  },
  {
    id: 6010,
    name: 'Багамские Острова',
  },
  {
    id: 303,
    name: 'Бангладеш',
  },
  {
    id: 6359,
    name: 'Барбадос',
  },
  {
    id: 2361,
    name: 'Бахрейн',
  },
  {
    id: 5047,
    name: 'Белиз',
  },
  {
    id: 18,
    name: 'Бельгия',
  },
  {
    id: 200,
    name: 'Болгария',
  },
  {
    id: 2228,
    name: 'Боливия',
  },
  {
    id: 2358,
    name: 'Босния и Герцеговина',
  },
  {
    id: 243,
    name: 'Бразилия',
  },
  {
    id: 6329,
    name: 'Бруней Даруссалам',
  },
  {
    id: 306,
    name: 'Буркина Фасо',
  },
  {
    id: 21,
    name: 'Великобритания',
  },
  {
    id: 114,
    name: 'Венгрия',
  },
  {
    id: 2371,
    name: 'Венесуэла',
  },
  {
    id: 203,
    name: 'Вьетнам',
  },
  {
    id: 2586,
    name: 'Габон',
  },
  {
    id: 202,
    name: 'Гвинея',
  },
  {
    id: 27,
    name: 'Германия',
  },
  {
    id: 2435,
    name: 'Государство Палестина',
  },
  {
    id: 213,
    name: 'Греция',
  },
  {
    id: 30,
    name: 'Дания',
  },
  {
    id: 311,
    name: 'Демократическая Республика Конго',
  },
  {
    id: 6517,
    name: 'Джибути',
  },
  {
    id: 2730,
    name: 'Доминиканская Республика',
  },
  {
    id: 6868,
    name: 'Другое',
  },
  {
    id: 305,
    name: 'Египет',
  },
  {
    id: 6367,
    name: 'Замбия',
  },
  {
    id: 33,
    name: 'Израиль',
  },
  {
    id: 209,
    name: 'Индия',
  },
  {
    id: 2108,
    name: 'Индонезия',
  },
  {
    id: 2365,
    name: 'Иордания',
  },
  {
    id: 2232,
    name: 'Ирак',
  },
  {
    id: 2227,
    name: 'Иран',
  },
  {
    id: 36,
    name: 'Ирландия',
  },
  {
    id: 302,
    name: 'Исландия',
  },
  {
    id: 37,
    name: 'Испания',
  },
  {
    id: 38,
    name: 'Италия',
  },
  {
    id: 2109,
    name: 'Йемен',
  },
  {
    id: 245,
    name: 'Камбоджа',
  },
  {
    id: 45,
    name: 'Канада',
  },
  {
    id: 210,
    name: 'Катар',
  },
  {
    id: 236,
    name: 'Кипр',
  },
  {
    id: 50,
    name: 'Китай',
  },
  {
    id: 2113,
    name: 'Колумбия',
  },
  {
    id: 2380,
    name: 'Кооперативная Республика Гайана',
  },
  {
    id: 2362,
    name: 'Королевство Саудовская Аравия',
  },
  {
    id: 2363,
    name: 'Кувейт',
  },
  {
    id: 6323,
    name: 'Лаос',
  },
  {
    id: 11317,
    name: 'Республика Гамбия',
  },
  {
    id: 57,
    name: 'Латвия',
  },
  {
    id: 2111,
    name: 'Ливан',
  },
  {
    id: 2106,
    name: 'Ливия',
  },
  {
    id: 59,
    name: 'Литва',
  },
  {
    id: 2355,
    name: 'Лихтенштейн',
  },
  {
    id: 206,
    name: 'Люксембург',
  },
  {
    id: 2359,
    name: 'Македония',
  },
  {
    id: 238,
    name: 'Малайзия',
  },
  {
    id: 6518,
    name: 'Мали',
  },
  {
    id: 2798,
    name: 'Мальдивская Республика',
  },
  {
    id: 2353,
    name: 'Мальта',
  },
  {
    id: 2372,
    name: 'Марокко',
  },
  {
    id: 2229,
    name: 'Мексика',
  },
  {
    id: 62,
    name: 'Молдова',
  },
  {
    id: 2356,
    name: 'Монако',
  },
  {
    id: 2231,
    name: 'Монголия',
  },
  {
    id: 2487,
    name: 'Мьянма',
  },
  {
    id: 2375,
    name: 'Намибия',
  },
  {
    id: 6326,
    name: 'Непал',
  },
  {
    id: 240,
    name: 'Нигерия',
  },
  {
    id: 65,
    name: 'Нидерланды',
  },
  {
    id: 204,
    name: 'Новая Зеландия',
  },
  {
    id: 207,
    name: 'Норвегия',
  },
  {
    id: 208,
    name: 'ОАЭ',
  },
  {
    id: 6058,
    name: 'Объединенная Республика Танзания',
  },
  {
    id: 2364,
    name: 'Оман',
  },
  {
    id: 6521,
    name: 'Острова Кайман',
  },
  {
    id: 316,
    name: 'Пакистан',
  },
  {
    id: 2587,
    name: 'Панама',
  },
  {
    id: 6327,
    name: 'Парагвай',
  },
  {
    id: 2395,
    name: 'Перу',
  },
  {
    id: 74,
    name: 'Польша',
  },
  {
    id: 241,
    name: 'Португалия',
  },
  {
    id: 2752,
    name: 'Республика Бенин',
  },
  {
    id: 6377,
    name: 'Республика Гаити',
  },
  {
    id: 2491,
    name: 'Республика Гана',
  },
  {
    id: 5131,
    name: 'Республика Гватемала',
  },
  {
    id: 2504,
    name: 'Республика Зимбабве',
  },
  {
    id: 2669,
    name: 'Республика Камерун',
  },
  {
    id: 2453,
    name: 'Республика Кения',
  },
  {
    id: 312,
    name: 'Республика Конго',
  },
  {
    id: 2636,
    name: 'Республика Коста-Рика',
  },
  {
    id: 2454,
    name: 'Республика Кот-д’Ивуар',
  },
  {
    id: 2635,
    name: 'Республика Куба',
  },
  {
    id: 2398,
    name: 'Республика Либерия',
  },
  {
    id: 2370,
    name: 'Республика Маврикий',
  },
  {
    id: 2488,
    name: 'Республика Мадагаскар',
  },
  {
    id: 6337,
    name: 'Республика Малави',
  },
  {
    id: 2378,
    name: 'Республика Нигер',
  },
  {
    id: 3666,
    name: 'Республика Никарагуа',
  },
  {
    id: 2446,
    name: 'Республика Северный Судан',
  },
  {
    id: 2367,
    name: 'Республика Сейшельские острова',
  },
  {
    id: 2374,
    name: 'Республика Сенегал',
  },
  {
    id: 6504,
    name: 'Республика Сьерра-Леоне',
  },
  {
    id: 6350,
    name: 'Республика Чад',
  },
  {
    id: 6356,
    name: 'Республика Эль-Сальвадор',
  },
  {
    id: 2369,
    name: 'Реюньон',
  },
  {
    id: 234,
    name: 'Румыния',
  },
  {
    id: 11177,
    name: 'Сан-Томе и Принсипи',
  },
  {
    id: 5048,
    name: 'Сент-Винсент и Гренадины',
  },
  {
    id: 146,
    name: 'Сербия',
  },
  {
    id: 233,
    name: 'Сингапур',
  },
  {
    id: 317,
    name: 'Сирия',
  },
  {
    id: 318,
    name: 'Словакия',
  },
  {
    id: 2105,
    name: 'Словения',
  },
  {
    id: 85,
    name: 'США',
  },
  {
    id: 86,
    name: 'Таджикистан',
  },
  {
    id: 300,
    name: 'Таиланд',
  },
  {
    id: 148,
    name: 'Тайвань',
  },
  {
    id: 6330,
    name: 'Тоголезская Республика',
  },
  {
    id: 244,
    name: 'Тунис',
  },
  {
    id: 93,
    name: 'Туркменистан',
  },
  {
    id: 94,
    name: 'Турция',
  },
  {
    id: 4635,
    name: 'Уганда',
  },
  {
    id: 2810,
    name: 'Уругвай',
  },
  {
    id: 2360,
    name: 'Филиппины',
  },
  {
    id: 100,
    name: 'Финляндия',
  },
  {
    id: 101,
    name: 'Франция',
  },
  {
    id: 2103,
    name: 'Хорватия',
  },
  {
    id: 6448,
    name: 'Центральноафриканская Республика',
  },
  {
    id: 5046,
    name: 'Черногория',
  },
  {
    id: 199,
    name: 'Чехия',
  },
  {
    id: 2396,
    name: 'Чили',
  },
  {
    id: 108,
    name: 'Швейцария',
  },
  {
    id: 149,
    name: 'Швеция',
  },
  {
    id: 239,
    name: 'Шотландия',
  },
  {
    id: 2511,
    name: 'Шри-Ланка',
  },
  {
    id: 242,
    name: 'Эквадор',
  },
  {
    id: 6328,
    name: 'Эритрея',
  },
  {
    id: 109,
    name: 'Эстония',
  },
  {
    id: 2520,
    name: 'Эфиопия',
  },
  {
    id: 211,
    name: 'ЮАР',
  },
  {
    id: 110,
    name: 'Южная Корея',
  },
  {
    id: 2524,
    name: 'Южная Осетия',
  },
  {
    id: 2440,
    name: 'Ямайка',
  },
  {
    id: 111,
    name: 'Япония',
  },
  {
    id: 48,
    name: 'Кыргызстан',
  },
  {
    id: 97,
    name: 'Узбекистан',
  },
];
