import { formatFullDate } from '../utils/date';

export class PaymentItem {
  id: number;
  date: string;
  tariffName: string;
  status: string;
  method: string;
  sum: number;

  constructor(
    id: number,
    date: string,
    tariffName: string,
    status: string,
    method: string,
    sum: number,
  ) {
    this.id = id;
    this.date = formatFullDate(date);
    this.status = status;
    this.tariffName = tariffName;
    this.method = method;
    this.sum = sum / 100;
  }
}
