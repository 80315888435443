import { HelpItem, HelpLink } from '../view-models/help-item';
import { linksUrl } from './linksUrl';

export const helpItems: HelpItem[] = [
  new HelpItem(
    1,
    'Как подключить рекламный кабинет Wildberries к XSTAT?',
    'Для этого необходимо создать ключ API в личном кабинете WB и добавить его на сайте XSTAT в разделе  ',
    new HelpLink('Профиль — Ключи.', linksUrl.profile + linksUrl.profileKeys),
  ),
  new HelpItem(
    2,
    'Как создать API ключ?',
    'Воспользуйтесь ',
    new HelpLink('инструкцией.', '/' + linksUrl.instructions),
  ),
  new HelpItem(
    3,
    'Подходит ли сервис XSTAT для новичков?',
    'Да, XSTAT отлично подходит для новичков! Мы постарались сделать интерфейс простым и понятным, чтобы вам было удобно работать, даже если вы впервые используете аналитические сервисы. Никаких лишних функций — только самые важные инструменты для анализа данных. К тому же, мы подготовили обучающие видео, которые помогут быстро разобраться в работе сервиса. Если же останутся вопросы, наши менеджеры всегда готовы прийти на помощь.',
  ),
  new HelpItem(
    4,
    'Как XSTAT собирает данные?',
    'Каждый день мы получаем данные напрямую с маркетплейсов, обрабатываем их и сохраняем. Такой подход позволяет предоставлять актуальную и точную информацию о товарах и их динамике.',
  ),
  new HelpItem(
    5,
    'Безопасно ли предоставлять свои API-ключи?',
    'Абсолютно безопасно. Мы используем современные технологии защиты данных, чтобы ваши API-ключи оставались в безопасности. Доступ к ним строго ограничен и используется только для выполнения запросов, связанных с вашей аналитикой. Ваши данные находятся под надежной защитой.',
  ),
  new HelpItem(
    6,
    'Сколько я могу пользоваться предоставленными данными?',
    'Вы можете использовать данные на протяжении всего действия вашего тарифа. Доступ к аналитике будет сохраняться на протяжении всего оплаченного периода.',
  ),
  new HelpItem(
    7,
    'Какие данные собирает XSTAT?',
    'XSTAT собирает широкий спектр данных о товарах на маркетплейсах, включая как видимую пользователям информацию(цены, рейтинги, продажи), так и скрытую, например остатки на складах. Такой подход обеспечивает полный доступ к данным, необходимым для анализа и принятия решений.',
  ),
];
