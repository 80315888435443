import { FC, useEffect, useMemo, useState } from 'react';
import './table.scss';
import { bemCN } from '../../configs/bem-classname';
import { TableColumn } from '../../view-models/table-column';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';

import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, AgColumn } from 'ag-grid-community';

import { tableService } from '../../services/table-service';

interface TableProps {
  id?: string;
  columns: TableColumn[];
  data: any[];
  localStorageColumns: LocalStorageVariableName;
  onSort: (idColumn: number) => void;
  idActiveSort: number;
  decs: boolean;
  isSimpleTable?: boolean;
}

export enum DirectionPaginationClick {
  next,
  back,
}

const tableCN = bemCN('table');
export const Table: FC<TableProps> = ({
  columns,
  localStorageColumns,
  data,
  onSort,
  idActiveSort,
  decs,
  isSimpleTable,
}) => {
  const [colDefs1, setColDefs1] = useState<ColDef[]>([]);
  const [data1, setData1] = useState<any[]>([]);

  useEffect(() => {
    let actualColumn = localStorageService.getValue<TableColumn[]>(localStorageColumns);
    tableService
      .getColDefs(actualColumn || columns, localStorageColumns, idActiveSort, decs, isSimpleTable)
      .then((res: ColDef[]) => setColDefs1(res));
    tableService.getRowDefs(data, columns).then((res: any[]) => setData1(res));
  }, [columns, data]);

  const saveWidthToLocalStorage = (colId: number, newWidth: number) => {
    let actualCols = localStorageService.getValue<TableColumn[]>(localStorageColumns);
    let newColumns: TableColumn[] = [
      ...(actualCols || columns).map((c: TableColumn) =>
        c.id == colId ? ({ ...c, width: newWidth } as TableColumn) : c,
      ),
    ];

    localStorageService.setValue(localStorageColumns, newColumns);
  };

  const saveOrderToLocalStorage = (colId: number, newIndex: number) => {
    let actualCols = localStorageService.getValue<TableColumn[]>(localStorageColumns);

    let newColumns = [...(actualCols || columns).filter((c: TableColumn) => c.id !== colId)];
    let currentCol = (actualCols || columns).find((c: TableColumn) => c.id === colId);
    if (currentCol) {
      newColumns.splice(newIndex, 0, currentCol);
      localStorageService.setValue(localStorageColumns, newColumns);
    }
  };

  

  return (
    <div
      id="myGrid"
      className={tableCN('', { isSimple: isSimpleTable }, ['ag-theme-quartz'])}
      // style={{ height: countRows ? 650 : 2830 }}
      style={{ height: '100%' }}
    >
      <AgGridReact
        domLayout={'autoHeight'}
        onSortChanged={(e: any) => onSort(e.columns[e.columns.length - 1].colId)}
        suppressRowTransform={true}
        tooltipShowDelay={0}
        //  tooltipHideDelay = {100}
        unSortIcon={true}
        rowData={data1}
        columnDefs={colDefs1}
        onColumnResized={(e: any) => {
          saveWidthToLocalStorage(Number(e.column?.colId), e.column?.actualWidth);
        }}
        onColumnMoved={(e: any) => saveOrderToLocalStorage(Number(e.column?.colId), e.toIndex)}
        noRowsOverlayComponent={() => <p>Нет данных по Вашему запросу.</p>}
        tooltipShowMode="whenTruncated"
        enableBrowserTooltips={false}
       
        // tooltipMouseTrack = {true}
        
      />
    </div>
  );
};


