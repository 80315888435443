import { memo, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import { LineChartPropName } from '../../view-models/product-analitics-line-chart-item';
import {
  ResponsiveContainer,
  LineChart as RechartLineChart,
  CartesianGrid,
  XAxis,
  Line,
  YAxis,
  Label,
  Tooltip,
} from 'recharts';
import { Tooltip as CustomTooltip } from '../tooltip/tooltip';
import { CheckGroup, CheckMarkPosition } from '../../components/check-group/check-group';
import { SelectItem } from '../../view-models/select-item';
import './dinamic-line-chart.scss';

type DinamicLineChartProps = {
  id?: string;
  title?: string;
  data: any;
  propNames: LineChartPropName[];
  basePropName: string;
  basePropLabel: string;
  labelY?: string;
  labelX?: string;
  errorText?: string;
};

const colors: string[] = ['#DC48AC', '#25BA7B', '#5195BF', '#974ABB', '#EBB254'];

const dinamicLineChartCN = bemCN('dinamic-line-chart');

export const DinamicLineChart = memo((props: DinamicLineChartProps) => {
  let { id, data, propNames, basePropName, basePropLabel, labelY, labelX, errorText, title } =
    props;
  const [filterLineIds, setFilterLineIds] = useState<number[]>(
    propNames.map((pN: LineChartPropName) => pN.id),
  );

  const [isNullData, setIsNullData] = useState(false);

  const onChangeCheck = (ids: number[]) => {
    setFilterLineIds(ids);
  };

  const CustomTooltip = ({ active, payload, label, unit }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={dinamicLineChartCN('custom-tooltip')}>
          <div>
            <div>{basePropLabel}</div>
            <div style={{color: "var(--font-color)"}}>{payload[0].payload[basePropName]}</div>
          </div>
          {propNames.map((prop: LineChartPropName, index: number) => (
            <div style={{ color: colors[index] }}>
              <div style={{ color: colors[index] }}>{prop.label}:</div>
              <div>
                {payload[0].payload[prop.propName]
                  ? payload[0].payload[prop.propName].toLocaleString('ru-RU')
                  : 0}{' '}
                {prop.unit ? prop.unit + '.' : 'руб.'}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={dinamicLineChartCN()}>
      {isNullData ? (
        <p>{errorText}</p>
      ) : (
        <>
          <div className={dinamicLineChartCN('checkbox-panel')}>
            <p>{title}</p>
            <CheckGroup
              items={propNames.map(
                (propName: LineChartPropName) => new SelectItem(propName.id, propName.label),
              )}
              activeIds={filterLineIds}
              checkMarkPosition={CheckMarkPosition.left}
              onChange={onChangeCheck}
            />
          </div>
          <ResponsiveContainer width="100%" height={278}>
            <RechartLineChart
              width={854}
              height={578}
              data={data}
              margin={{ top: 10, right: 20, left: 50, bottom: 10 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                // tickCount={7}
                //  tickCount={20}
                // scale={'point'}
                padding={{ left: 20, right: 20 }}
                dy={15}
                style={{
                  fontSize: '16px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fill: 'var(--font-color)',
                }}
                // interval={14}
                axisLine={true}
                dataKey={basePropName}
                minTickGap={15}
                // interval={20}
                // type='ca'
              />
              {propNames.map((propName: LineChartPropName, index: number) => (
                <Line
                  hide={!filterLineIds.includes(propName.id)}
                  key={propName.id}
                  name={propName.label}
                  dot={{ fill: 'var(--dot-color)', strokeWidth: 1, r: 4,strokeDasharray:''}}
                  
                  type="monotone"
                  dataKey={propName.propName}
                  strokeWidth={2}
                  stroke={colors[index]}
                  unit={propName.unit}
                />
              ))}
              <YAxis
                // height={578}
                // width={80}
                dx={-5}
                style={{
                  fontSize: '16px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fill: 'var(--font-color)',
                 
                }}
                type="number"
                axisLine={false}
              >
                <Label
                  offset={35}
                  dy={-75}
                  value={labelY}
                  angle={-90}
                  position={'left'}
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Manrope',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '33px',
                    fill: '#222',
                  }}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
            </RechartLineChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
});

export default DinamicLineChart;
