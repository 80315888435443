import { NavLink } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import './top-menu.scss';
import { FC } from 'react';

const topMenuCn = bemCN("top-menu");

interface TopMuneProps {
  links: any[];
}

export const TopMenu:FC<TopMuneProps> = ({links}) => {
  return (
    <div className={topMenuCn()}>
        {
            links.map((link:any) => <NavLink key={link.name} to={link.path}>{link.name}</NavLink>)
        }
    </div>
  );
};
