import { FC, useEffect, useState } from 'react';
import './category-selected-item.scss';
import { bemCN } from '../../configs/bem-classname';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { CategoryTree } from '../../view-models/category';

interface SeacrhCategorySelectedItemProps {
  id?: string;
  text: string;
  items: any[];
  isDisable: boolean;
  onChange: (ids:string[]) => void;
}

const searchCategorySelectedItemCn = bemCN('seacrh-category-selected-item');

const getAllIds = (items: CategoryTree[]) => {
  let ids: string[] = [];
  items.forEach((item: CategoryTree) => {
    ids = [...ids, item.value, ...getAllIds(item.children || [])];
  });
  return ids;
};

export const SearchCategorySelectedItem: FC<SeacrhCategorySelectedItemProps> = ({
  id,
  text,
  items,
  isDisable,
  onChange
}) => {
  const [cheked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>(getAllIds(items));

  const onChangeItem = (changeIds:string[]) =>{
    setChecked(changeIds);
    onChange(changeIds)
  }

  return (
    <div id={id} className={searchCategorySelectedItemCn({isDisable:isDisable})}>
      <div className={searchCategorySelectedItemCn('button')}>
        <div className={searchCategorySelectedItemCn('color-block')}></div>
        <span>{text}</span>
      </div>
      <CheckboxTree
        nodes={items}
        checked={cheked}
        expanded={expanded}
        onCheck={onChangeItem}
        onExpand={(expanded) => setExpanded(expanded)}
      />
    </div>
  );
};
